// reducer.js

import { SET_GOOGLE_API_KEY } from './actions';

const initialState = {
  googleApiKey: '', // Initial state for the API key
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GOOGLE_API_KEY:
      return {
        ...state,
        googleApiKey: action.payload, // Update the API key in the state
      };
    default:
      return state;
  }
};

export default reducer;
