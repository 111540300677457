import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
// import NetworkSpeed from 'react-network-speed';

//Import Images
import error from "../../assets/images/error-img.png"

const Pages303 = () => {
   //meta title
   document.title = "No Internet | FrontPin";

   const [isOnline, setIsOnline] = useState(window.navigator.onLine);
    
  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.removeEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online',handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
//  if(window.navigator.onLine == true){
//     return window.location.href='/dashboard';
//  }else{
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
            {/* <NetworkSpeed
                onChange={({ kbps }) => console.log(kbps)}
                interval={1000}
            /> */}
              <div className="text-center mb-5">
                <h1 className="display-2 fw-medium">
                  <i className="bx bx-wifi-off text-primary display-1" />
                  
                </h1>
                
                <h4 className="text-uppercase">No Internet .</h4>
                <p>Please check your internet connection....</p>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to="/dashboard"
                  >
                    Try Again
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
 }
// }

export default Pages303
