import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Map, InfoWindow, Marker, GoogleApiWrapper, Circle } from "google-maps-react"
import { connect } from "react-redux"
// import LightData from "./LightData"
import Select from 'react-select'


import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Label,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import Switch from "react-switch"
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from "react-places-autocomplete"
  import toastr from "toastr"
  import "toastr/build/toastr.min.css"
const LoadingContainer = () => <div>Loading...</div>
const SkeletonRow = () => (
    <Row>
      <Col md={12}>
        <Card>
          <CardBody>
            <Row>
              <Col md={6}>
                <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
                <Skeleton height={20} width={100} />
              </Col>
              <Col md={6} style={{ textAlign: 'end' }}>
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={80} />
                <Skeleton height={20} width={100} />
                <Skeleton height={20} width={80} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col lg={12}>
        <Card>
          <CardBody>
            <div style={{ position: 'relative' }}>
              <Skeleton height={300} />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
const AddLocation = props => {
  //meta title
  document.title = `Add Location | ${process.env.REACT_APP_NAME}`
  const [alllocation, setAlllocation] = useState(false)
  const [timezoneFor, setTimezoneFor] = useState({label : '',value:''})
  const [showPlaceHolder, setShowPlaceHolder] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [selectedPlace, setselectedPlace] = useState('')
  const [apiLoaded, setApiLoaded] = useState(false)
  const [switch1, setswitch1] = useState(false)
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [GeoFenceRadius, setGeoFenceRadius] = useState(1);
  const [timeZone, setTimeZone] = useState([])  

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const fetchAllTimeZone = ()=>{
    const formdata = new FormData();
formdata.append("method", "all-timezone");

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
  .then((response) => response.json())
  .then((result) => setTimeZone(result))
  .catch((error) => console.error(error));
  }
  useEffect(() => {
    fetchAllTimeZone();
    if (window.google && window.google.maps) {
        setApiLoaded(true)
      } else {
        // If not, wait for the 'google-maps-api-loaded' event
        window.addEventListener("google-maps-api-loaded", () => {
          setApiLoaded(true)
        })
      }
  }, [])
  const handleChange = address => {
    // setFormData({ ...FormData, s_address: address })
    setLatitude(null)
    setLongitude(null)
    setselectedPlace(address)
  }
  const handleSelect = async address => {
    // setFormData({ ...FormData, s_address: address })
    try {
      const results = await geocodeByAddress(address)
      const latLng = await getLatLng(results[0])
      const { lng, lat } = latLng // Sender coordinates
      console.log("Sender Latitude and Longitude:", latLng)
      setselectedPlace(results[0].formatted_address);
      setLongitude(lng)
      setLatitude(lat)
      // Set sender coordinates in FormData
    //   setFormData(prevData => ({
    //     ...prevData,
    //     s_lng: lng,
    //     s_lat: lat,
    //   }))

      // Check if both sender and receiver locations are set
    //   if (FormData.r_lat !== 0 && FormData.r_lng !== 0) {
    //     calculateDistance(lat, lng, FormData.r_lat, FormData.r_lng)
    //   }
    } catch (error) {
      console.error("Error", error)
    }
  }
  const onPressSubmit = () =>{
                        let local = JSON.parse(localStorage.getItem('authUser'));
                        console.log(local);
                        let org_id = local.map((e)=>e.org_id);
                        console.log(org_id[0])
                        
                        if(companyName == ""){
                            toastr.error('Company name is required');
                            return false;
                        }else if(selectedPlace == ""){
                            toastr.error('Company location is required');
                            return false;
                        }else if(timezoneFor.label==''){
                          toastr.error('Select the company timezone');
                            return false;
                        }else{
                            let form = new FormData();
                            
                            form.append('company_location', selectedPlace);
                            form.append('company_name', companyName);
                            form.append('timezone_for', timezoneFor.label);
                            form.append('org_id', org_id[0]);
                            form.append('location_map', latitude + ',' + longitude);
                            if(switch1 == true){
                              form.append('geofence',GeoFenceRadius)
                            }else{
                              form.append('geofence',null)
                            }
                            form.append('method', 'company-data-post');
                            var requestOptions = {
                              method: 'POST',
                              body: form,
                              redirect: 'follow',
                            };
    
                            fetch(
                              process.env.REACT_APP_LEAVE_API_URL+'/company.php',
                              requestOptions,
                            )
                              .then(response => response.text())
                              .then((res)=>{
                                if(res == 'success'){
                                    toastr.success('Successfully added the company Location');
                                    location.href = '/locations'
                                }else{
                                    toastr.error('Error comes while adding try again');
                                }
                              }).catch((e)=>{
                                console.log(e)
                              })
    
                        }
                        }
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="Add Location" />
       
         <Row >
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <label >
                            Company Name
                          </label>
                          <input type="text" className="form-control" onChange={(e)=>setCompanyName(e.target.value)} value={companyName}  name="company_name"/>
                          <label  style={{paddingTop:10}}>
                            Location
                          </label>
                       
                          <PlacesAutocomplete
                            value={selectedPlace}
                            onChange={handleChange}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Enter address...",
                                    className: "form-control",
                                    id: "address_",
                                  })}
                                />
                                <div>
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item"
                                    const style = suggestion.active
                                      ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        }
                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                         <p style={{borderBottomWidth:1 , borderBottomColor: '#f3f3f3'}}>{suggestion.description}</p>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                         
                        </Col>
                          <Col md={12}>
                          <Col md={8}>
                           <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-12 col-form-label"
                              >
                                Company Timezone
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value={timezoneFor}
                                  options={timeZone}
                                  onChange={e => setTimezoneFor(e)}
                                  className="select2-selection"
                                  placeholder="Select Timezone"
                                />
                              </Col>
                            </Row>
                             </Col>
                          </Col>
                        <Col md={12} style={{paddingTop:10}}>
                         <Row>
                              <Col md={3}>
                              <Label>Enable Geofence </Label>
                              </Col>
                              <Col md={3} style={{textAlign:'end'}}>
                              <Switch
                                
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                className="me-1 mb-sm-8 mb-2"
                                onColor="#626ed4"
                                onChange={() => {
                                setswitch1(!switch1)
                                }}
                                checked={switch1}
                            />
                            </Col>
                            
                         </Row>
                         { 
                                switch1 == true ? 
                                <Col md={6}>
                                    <Label>Geofence Radius in meters</Label>
                                    <input
                                    type="number"
                                    min={1}
                                    className="form-control"
                                    name="geofence"
                                    value={GeoFenceRadius}
                                    onChange={(e)=>setGeoFenceRadius(e.target.value)}
                                    />
                                </Col>
                                : null 
                            }
                        </Col>
                        <Col md={4} style={{marginTop:10}}>
                            <button className="btn btn-primary" onClick={()=>onPressSubmit()}>Add Location</button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                {
                latitude !== null && longitude !== null   ? 
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div
                        id="gmaps-markers"
                        className="gmaps"
                        style={{ position: "relative" }}
                      >
                      
<Map
                          initialCenter={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                          google={props.google}
                          style={{ width: "100%", height: "100%" }}
                          zoom={13}
                        >
                             {
                                switch1 === true ? 

                                <Circle
                                         center={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }} // Set center of the circle
                                         radius={parseFloat(GeoFenceRadius)} // Set radius of the circle in meters
                                         strokeColor="red" // Set stroke color
                                         strokeOpacity={0.8} // Set stroke opacity
                                         strokeWeight={2} // Set stroke weight
                                         fillColor="red" // Set fill color
                                         fillOpacity={0.35} // Set fill opacity
                                     /> : null
                             }
                                         <Marker
                                          title={`Location`}
                                        //   name={`${e.location}`}
                                          position={{ lat: parseFloat(latitude), lng: parseFloat(longitude) }}
                                        />
                                      
                                        {/* <Marker name={"Dolores park"} /> */}
                          {/* <InfoWindow>
                            <div>
                              <h1>{e.location}</h1>
                            </div>
                          </InfoWindow> */}
                        </Map>
                        
                        
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                :null
 }
              </Row>
         
        </div>
      </div>
    </React.Fragment>
  )
}

AddLocation.propTypes = {
  google: PropTypes.object,
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAIiEgVMMUA5gil8H1mRK3y7WrMcghm8jY",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(AddLocation)
)
