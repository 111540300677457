import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";
import Swal from "sweetalert2";

const NotificationDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notificationList,setnotificationList] = useState([])
  useEffect(()=>{
    fetchNotification();
  },[])
  const fetchNotification = async ()=>{
    let localVal = JSON.parse(localStorage.getItem('authUser'));
    let emp = localVal.map((e)=>e.user_id);
    let org = localVal.map((e)=>e.org_id);
    var formdata = new FormData();
    formdata.append("method", "fetch-notifications");
    formdata.append("emp_id", emp);
    formdata.append("org_id", org);

    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
    };

    fetch("https://api.frontforcecrm.com/notifications.php", requestOptions)
    .then(response => response.json())
    .then(result => setnotificationList(result))
    .catch(error => console.log('error', error));
}
const changeStatusFrom = (id) =>{

  var formdata = new FormData();
    formdata.append("method", "change-status-notifications");
    formdata.append("post_id", id);

    var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
    };

    fetch("https://api.frontforcecrm.com/notifications.php", requestOptions)
    .then(response => response.text())
    .then(result => {
        if(result == 'success'){
          fetchNotification();
        }
    })
    .catch(error => console.log('error', error));
}
const PressClear = async ()=>{
  let localVal =  localStorage.getItem('authUser');
  let mapingVl = JSON.parse(localVal);
  let org = mapingVl.map((e)=>e.org_id[0]);
  let user = mapingVl.map((e)=>e.user_id[0]);
  var formdata = new FormData();
  formdata.append("method", "change-status-notifications-all");
  formdata.append("org_id", org);
  formdata.append("emp_id", user);
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };

  fetch("https://api.frontforcecrm.com/notifications.php", requestOptions)
  .then(response => response.text())
  .then(result => {
      if(result == 'success'){
        Swal.fire('Success','Notification list is cleared');
         fetchNotification();
      }else{
          Swal.fire('Server Error','Error while clearing the list');
      }
  })
  .catch(error => console.log('error', error));
}
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{notificationList.length  == 0 ? 0 : notificationList.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
              {/* <div className="col-auto">
                <a href="#" className="small">
                  {" "}
                  View All
                </a>
              </div> */}
            </Row>
          </div>
     
          <SimpleBar style={{ height: "230px" }}>

            
           {
            
           notificationList.length == 0 ? 
           <p style={{textAlign:'center'}}>{'No notifications '}</p> : 
           ( notificationList.map((e,index)=>(
            <Link onClick={()=>changeStatusFrom(e.id)} key={index} className="text-reset notification-item">
            <div className="d-flex">
              <div style={{paddingBottom: 5, paddingTop:5,paddingLeft: 1,paddingRight: 1}} className="avatar-xs me-3">
                <span className="avatar-title bg-primary rounded-circle font-size-16">
                  <i className="bx bx-user" />
                </span>
              </div>
              <div className="flex-grow-1">
                <h6 className="mt-0 mb-1">
                  {props.t( `${e.title}`)}
                </h6>
                <div className="font-size-12 text-muted">
                  <p className="mb-1">
                    {props.t(
                      `${e.description}`
                    )}
                  </p>
                  <p className="mb-0">
                    <i className="mdi mdi-clock-outline" />{" "}
                    {props.t("3 min ago")}{" "}
                  </p>
                </div>
              </div>
            </div>
          </Link>
          )))
           }
          </SimpleBar>
          {
            notificationList.length == 0 ? null : 

          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" onClick={()=>PressClear()}>
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("Clear All")}</span>
            </Link>
          </div>
          }
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};