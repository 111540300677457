import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Form,
  CardTitle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"

import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

const TimeOffHolidays = props => {
  //meta title
  document.title = "Timeoff | Skote - React Admin & Dashboard Template"

  const [activeTab, setActiveTab] = useState("1")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const optionGroup = [
    {
      options: [
        { label: "Pakistan", value: "Pakistan" },
        { label: "India", value: "India" },
        { label: "China", value: "China" },
      ],
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Time Off Policies
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Holidays
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>

            <Col xl="6">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="float-end">
                    <div className="btn btn-primary">Add Time Off Policies</div>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId="1">
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Name</th>
                          <th>Compensation</th>
                          <th>Units</th>
                          <th>Assignees</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">Medical Leave</th>
                          <td>Paid</td>
                          <td>Days</td>
                          <td>Not Assign</td>
                          <td>
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            />
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Vacation Leave</th>
                          <td>Paid</td>
                          <td>Days</td>
                          <td>Not Assign</td>
                          <td>
                            <i
                              className="mdi mdi-pencil font-size-18"
                              id="edittooltip"
                            />
                            <i
                              className="mdi mdi-delete font-size-18"
                              id="deletetooltip"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </TabPane>
              </TabContent>

              <TabContent activeTab={activeTab} className="p-3">
                <TabPane tabId="2">
                  <Row>
                    <Col xl="3">
                      <Card>
                        <CardBody>No calendars added</CardBody>
                      </Card>
                    </Col>
                    <Col xl="9">
                      <Card>
                        <CardBody>
                          <CardTitle className="mb-4">
                            HOLIDAY CALENDAR
                          </CardTitle>
                          <Form>
                            <Row className="mb-2">
                              <Label
                                htmlFor="horizontal-firstname-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Calendar name
                              </Label>
                              <Col sm={9}>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="horizontal-firstname-Input"
                                  placeholder="Calendar name"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={3} sm={3}></Col>
                              <Col xl={9} sm={9}>
                                <div className="form-check from-check-success mb-4">
                                  <Input
                                    type="checkbox"
                                    className="form-check-Input"
                                    id="horizontal-customCheck"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="horizontal-customCheck"
                                  >
                                    Make default
                                  </Label>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-3 col-form-label"
                              >
                                Holidays
                              </Label>
                              <Col sm={9}>
                                <Select
                                  value="Select"
                                  options={optionGroup}
                                  className="select2-selection"
                                  placeholder="Select country or region"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col xl={3} sm={3}></Col>
                              <Col xl={9} sm={9}>
                                <div className="form-check from-check-success mb-4">
                                  <Input
                                    type="checkbox"
                                    className="form-check-Input"
                                    id="horizontal-customCheck2"
                                  />
                                  <Label
                                    className="form-check-label"
                                    htmlFor="horizontal-customCheck2"
                                  >
                                    Add manually
                                  </Label>
                                </div>
                              </Col>
                            </Row>

                            <Row className="float-end">
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    className="w-md"
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TimeOffHolidays
