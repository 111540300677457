import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Container,Row,Col, Modal,ModalBody,Card, CardBody,Form,Label,Spinner, UncontrolledTooltip, Input } from 'reactstrap'
import Select from 'react-select';
import Swal from 'sweetalert2';
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { ArrowRightAltSharp, DeleteSharp, EditSharp, PermIdentitySharp, SyncAltSharp, SyncSharp } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Backdrop, CircularProgress, Skeleton ,useTheme} from '@mui/material';

function removeBodyCss() {
    document.body.classList.add("no_padding");
}
function getValueAfterEquals(inputString) {
    // Split the string by '=' and return the second part
    const parts = inputString.split('=');
    if (parts.length > 1) {
        return parts[1];
    } else {
        return null; // Return null if the '=' sign is not found
    }
}
function getFormattedDateTime(timezone) {
  const date = new Date();

  const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: timezone
  };

  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const formattedParts = formatter.formatToParts(date);

  const formatMap = {};
  formattedParts.forEach(({ type, value }) => {
      formatMap[type] = value;
  });

  const year = formatMap.year;
  const month = formatMap.month.padStart(2, '0');
  const day = formatMap.day.padStart(2, '0');
  const hour = formatMap.hour.padStart(2, '0');
  const minute = formatMap.minute.padStart(2, '0');
  const second = formatMap.second.padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}



export default function ZKHome() {
    document.title = `ZKTeco Device | ${process.env.REACT_APP_NAME}`
    const [zKData,setZKData] = useState([])
    const [timezoneFor, setTimezoneFor] = useState({label : '',value:''})
    const [loaderForConfig, setLoaderForConfig] = useState(false)
    const [placeholderShown, setPlaceholderShown] = useState(true)
    const [mockDataOther, setMockDataOther] = useState([])
    const [timeZone, setTimeZone] = useState([])  
    const [updateTime,setUpdateTime] = useState('')
    const [zkDeviceDataSingle,setZkdeviceDataSingle] = useState({
        id:'',
        ip_address:'',
        for_company_address:'',
        password:'',
        username:'',
      })
      const [singleModal,setSingleModal] = useState(false)
      function tog_centerother() {
        setSingleModal(!singleModal);
        removeBodyCss();
      }
      function tog_centerTime() {
        setTimeModal(!timeModal);
        removeBodyCss();
      }
  useEffect(()=>{
    // fetchAddLinkedDevices();
    fetchCompaineName()
    fetchZKDevicesData();
    fetchAllTimeZone();
  },[])
  const fetchAllTimeZone = ()=>{
    const formdata = new FormData();
formdata.append("method", "all-timezone");

const requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
  .then((response) => response.json())
  .then((result) => setTimeZone(result))
  .catch((error) => console.error(error));
  }
  const fetchZKDevicesData = ()=>{
    let org_id = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
    let form =  new FormData();
    form.append('method','fetch_devices');
    form.append('org_id',org_id[0])
    axios.post(process.env.REACT_APP_LEAVE_API_URL +'/machine-config.php', form).then((res)=>{
        setPlaceholderShown(false)
        if(res.data.lenght !== 0){
        setZKData(res.data.reverse())
      }
  
    console.log(res.data)
    })
  }
  const fetchCompaineName = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData();
    formdata.append('method', 'fetch_company');
    formdata.append('role', role);
    formdata.append('emp_id', emp);
    formdata.append('org_id', org);
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
  
    await fetch(process.env.REACT_APP_LEAVE_API_URL+'/company.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.warn(result);
        if (result.length !== 0) {
          result.map(e => {
            let val = {
              value: e.location,
              label: e.location,
            };
             mockDataOther.push(val);
          });
        }
      })
      .catch(error => console.log('error', error));
  };
  const deleteZKdevice = (passed) =>{
    Swal.fire({
      title:'Confirmed',
      icon:'warning',
      text:'Are you sure that you want to delete this device configuration',
      showCancelButton:true,
      cancelButtonText:"No, I don't",
      cancelButtonColor:'red',
      confirmButtonColor:'green',
      confirmButtonText:'Yes, Please',
      backdrop:'static'

    }).then((is)=>{
      if(is.isConfirmed){
        let form = new FormData();
        form.append('method','delete-machine-configuration');
        form.append('id', passed);
        axios.post(process.env.REACT_APP_LEAVE_API_URL + '/machine-config.php',form).then((res)=>{
          if(res.data=='success'){
            toastr.success('You successfully delete the configuration device');
            fetchZKDevicesData();
          }else{
            toastr.error('Error comes. Please try again');
          }
        }).catch((res)=>{
          console.log(res);
        })
      }else{
        Swal.close();
      }
    })
  }
  const passingMe = (id)=>{
    const formdata = new FormData();
    formdata.append("method", "status-checker");
    formdata.append("status_id", id);
    axios.post(process.env.REACT_APP_LEAVE_API_URL +'/machine-config.php', formdata)
   
      .then((result) => {
        if(result.data == 'success'){
          toastr.success('Success','Your device connection is done. Now you can start communication with your device');
        }else{
          Swal.fire({
            icon:'error',
            title:`${process.env.REACT_APP_NAME} doesn't communicated with your provided IP address. Please try again with another IP address. Or Before that try to use Ping Method to ping your IP Address`,
            showCancelButton:true,
            cancelButtonColor:'red',
            cancelButtonText:'Close',
            confirmButtonText:'Try again',
            confirmButtonColor:'green',

            
          }).then((is)=>{
            if(is.isConfirmed){
              passingMe(id)
            }else{
              Swal.close();
            }
          })

        }
      })
      .catch((error) => console.error(error));
  }
  const [singleZkData,setSingleZKData]= useState({})
  const [timeModal,setTimeModal] = useState(false)
  const handleDeviceTime = (item)=>{
    setSingleZKData(item);
    setTimeModal(true)
  }
  const updateTimeForMachine = ()=>{
    Swal.fire({
      title:'Confirmation',
      text:'Are you sure that you want to update the time',
      icon:'warning',
      showCancelButton:true,
      cancelButtonText:"No, I don't",
      cancelButtonColor:'red',
      confirmButtonText:'Yes, Please',
      confirmButtonColor:'green',
      
    }).then((is)=>{
      if(is.isConfirmed){
        const formdata = new FormData();
        formdata.append('method', 'update_time_of_machine');
        formdata.append("timefor", singleZkData.id);
        formdata.append("time", updateTime);
        axios.post(`${process.env.REACT_APP_LEAVE_API_URL}/machine-config.php`,formdata).then((res)=>{
          if(res.data == 'success'){
            toastr.success('Success','You successfully update the machine time');
            setTimeModal(false)
          }else{
            toastr.error('Error','Error comes while updating the machine time. Please try again');
          }
        }).catch((er)=>{
          toastr.error('Server Error','Server error. Please check your internet connection and try again');
          console.log(er);
        })
      }
      else{
        Swal.close();
        setTimeModal(!timeModal);
      }

    })
  }
  const SyncAttendanceFromDB = (pass) =>{
    setopenLoader(true)
    let value = JSON.parse(localStorage.getItem('authUser'));
    let org = value.map((e)=>e.org_id);
    const formdata = new FormData();
    formdata.append("method", "fetch_attendance_from_device");
    formdata.append("device_id", pass);
    formdata.append("org_id", org);
    axios.post(process.env.REACT_APP_LEAVE_API_URL +'/schedule-file.php', formdata)
      .then((result) => {
        setopenLoader(false)
        if(result.data == 'done'){
          toastr.success('Succes','You successfully sync the data from ZKTeco');
      }
    })
      .catch((error) => console.error(error));
  }
  const PlacholderItem= () =>{
    return(
        <Card>
            <CardBody>
                <Row>
                <Col  md={6}>
               
                            <Row>
                                <Col md={12} style={{paddingBottom:20}}>
                                    <img src={require('../../assets/images/icons/zklogo.png')} style={{width:140,height:60}}/>
                                </Col>
                                
                                <Col md={6}>
                                    <p>Device Location</p>
                                </Col>
                                <Col md={6}>
                                   <Skeleton width={300} height={40}/>
                                </Col>
                                <Col md={6}>
                                    <p>SerialNumber</p>
                                </Col>
                                <Col md={6}>
                                <Skeleton width={300} height={40}/>
                                </Col>
                                <Col md={6}>
                                    <p>IP Address</p>
                                </Col>
                                <Col md={6}>
                                <Skeleton width={300} height={40}/>
                                </Col>
                                <Col md={6}>
                                    <p>Port</p>
                                </Col>
                                <Col md={6}>
                                <Skeleton width={300} height={40}/>
                                </Col>
                                <Col md={6}>
                                    <p>Device Name</p>
                                </Col>
                                <Col md={6}>
                                <Skeleton width={300} height={40}/>
                                </Col>
                                <Col md={6}>
                                    <p>Device Time</p>
                                </Col>
                                <Col md={6}>
                                <Skeleton width={300} height={40}/>
                                </Col>
                                <Col md={6}>
                                    <p>Status</p>
                                </Col>
                                <Col md={6}>
                                <Skeleton width={300} height={40}/>
                                </Col>
                             
                            </Row>
                    
                </Col>
                </Row>
            </CardBody>
        </Card>
    );
  }
  const theme = useTheme()
  const [openLoader,setopenLoader] = useState(false);
  return (
     <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Backdrop
              sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
              open={openLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
               <Row>
                <Col md={6}>

               {
                placeholderShown == true ?<PlacholderItem/> : null
                }
                </Col>
               </Row>
            <Row>
                {
                    zKData.length== 0 && placeholderShown == false ?<Col md={12}>
                        <h6>No Device Config</h6>
                        <p>Go to Organization <ArrowRightAltSharp/> Settings and configure your device  <Link to={'../organization/profile'} >Go to Setting</Link></p>
                    </Col>:null
                }
                {
                    zKData.map((e,index)=>(
                        
                <Col key={index} md={6}>
                   <Card>
                
                    <CardBody>
                            <Row>
                                <Col md={6} style={{paddingBottom:20}}>
                                    <img src={require('../../assets/images/icons/zklogo.png')} style={{width:140,height:60}}/>
                                </Col>
                                <Col md={6} style={{paddingBottom:20 , paddingTop:20,textAlign:'end'}}>
                                   <SyncSharp onClick={()=>SyncAttendanceFromDB(e.id)} id="sync_icon"  style={{cursor:'pointer'}}/>
                                   <UncontrolledTooltip target={'sync_icon'}>
                                       <p>Sync Attendance from machine</p>
                                   </UncontrolledTooltip>
                                </Col>
                                <Col md={6}>
                                    <p>Device Location</p>
                                </Col>
                                <Col md={6}>
                                    <p>{e.for_company_address}</p>
                                </Col>
                                <Col md={6}>
                                    <p>SerialNumber</p>
                                </Col>
                                <Col md={6}>
                                    <p>{getValueAfterEquals(e.serial)}</p>
                                </Col>
                                <Col md={6}>
                                    <p>IP Address</p>
                                </Col>
                                <Col md={6}>
                                    <p>{e.ip_address}</p>
                                </Col>
                                <Col md={6}>
                                    <p>Port</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        {e.password}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p>Device Name</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        {getValueAfterEquals(e.deviceName)}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p>Device Time</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        {e.deviceTime}
                                         {/* <EditSharp onClick={()=>handleDeviceTime(e)} style={{fontSize:18,cursor:'pointer'}} id="edit_for"/>
                                        <UncontrolledTooltip target={'edit_for'}>
                                          <p>Edit Machine Time</p>
                                        </UncontrolledTooltip> */}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <p>Status</p>
                                </Col>
                                <Col md={6}>
                                    <p>
                                        Active
                                    </p>
                                </Col>
                                <Col md={12}>
                                   

                                    <EditSharp onClick={()=>{
                                        setSingleModal(true)
                                        setZkdeviceDataSingle(e)
                                    }} style={{color:'blue',cursor:'pointer'}} id="editToolTip"/>
                                    <UncontrolledTooltip target={'editToolTip'}>
                                        <span>Edit</span>
                                    </UncontrolledTooltip>
                                    <DeleteSharp style={{color:'red',marginLeft:10,cursor:'pointer'}} id="deleteToolTip"/>
                                    <UncontrolledTooltip target={'deleteToolTip'}>
                                        <span>Delete</span>
                                    </UncontrolledTooltip>
                                    <Link
                                    to={`./${e.id}`}
                                    state={{
                                        location: e.for_company_address
                                    }}
                                    >
                                    
                                    <PermIdentitySharp
                                    id="viewToolTip" style={{cursor:'pointer',color:'green',marginLeft:10}}/>
                                    <UncontrolledTooltip target={'viewToolTip'}>
                                        <span>View User List</span>
                                    </UncontrolledTooltip>
                                    </Link>
                                    
                                    
                                </Col>
                            </Row>
                    </CardBody>
                   </Card>
                </Col>
                    ))
                }
            </Row>
            </Container>
            </div>
            <Modal
                      isOpen={singleModal}
                      toggle={() => {
                        tog_centerother();
                      }}
                      centered
                      style={{width:'100%'}}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit ZK Configuration</h5>
                       
                       
                        <button
                          type="button"
                          onClick={() => {
                            setSingleModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                  
                             <Form 
                             onSubmit={(e)=>{
                              e.preventDefault();
                              if(e.target.elements.ip_address.value ==  ''){
                                toastr.error('IP address is required');
                                return false;
                              }else if(e.target.elements.device_password.value ==  ''){
                                toastr.error('Device Password is required');
                                return false;
                              }else if(e.target.elements.location_for.value ==  ''){
                                toastr.error('Device Location is required');
                                return false;
                              }else{
                                // setLoaderForConfig(true)
                                let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id)
                                let form = new FormData();
                                form.append('method', 'machine-configuration-post-update');
                                form.append('org_id', org[0]);
                                // form.append('username', zkDeviceDataSingle.username);
                                form.append('port', zkDeviceDataSingle.password);
                                form.append('company_name', zkDeviceDataSingle.for_company_address);
                                form.append('ip_address', zkDeviceDataSingle.ip_address);
                                form.append('edit_id', zkDeviceDataSingle.id);
                                axios.post(process.env.REACT_APP_LEAVE_API_URL + '/machine-config.php' , form).then((res)=>{
                                //   setLoaderForConfig(false)
                                  if(res.data == 'success'){
                                    toastr.success('You successfully added the device configuration details');
                                    fetchZKDevicesData()
                                    setSingleModal(false)
                                  }else{
                                    toastr.error('Error comes. Please try again');
                                  }
                                }).catch((er)=>{
                                  console.log(er)
                                })
                              }
                             }}
                             >
                               <Row>
                                <Col md={12}>
                                <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-email-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                  IP Address
                                 </Label>
                                
                                 <Col sm={9}>
                                <input
                                type="text"
                                name="ip_address"
                                className="form-control"
                                placeholder="192.0.0.0.1"
                                value={zkDeviceDataSingle.ip_address}
                                onChange={(e)=>setZkdeviceDataSingle((prev)=>({
                                  ...prev,
                                  ip_address : e.target.value
                                }))}
                                
                                />
                                  
                                 </Col>
                               </Row>
                               
                                <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-email-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                  PORT
                                 </Label>
                                
                                 <Col sm={9}>
                                <input
                                type="text"
                                name="device_password"
                                className="form-control"
                                placeholder="*******"
                                value={zkDeviceDataSingle.password}
                                onChange={(e)=>setZkdeviceDataSingle((prev)=>({
                                  ...prev,
                                  password : e.target.value
                                }))}
                                />
                                  
                                 </Col>
                               </Row>
                                <Row className="mb-4">
                                 <Label
                                   htmlFor="horizontal-email-Input"
                                   className="col-sm-3 col-form-label"
                                 >
                                  Location For
                                 </Label>
                                
                                 <Col sm={9}>
                                 <Select
                                 name="location_for"
                                 className="select2-selection"
                                 options={mockDataOther}
                                 value={{label:zkDeviceDataSingle.for_company_address, value:zkDeviceDataSingle.for_company_address}}
                                 onChange={(e)=>setZkdeviceDataSingle((prev)=>({
                                   ...prev,
                                   for_company_address : e.value
                                   }))}
                                 />
                                  
                                 </Col>
                               </Row>
                                </Col>
                              
                                <Col md={12}>
                               {
                                loaderForConfig == true ? 
                                <Spinner color="primary"/>:
                                <button className="btn btn-primary">Save Changes</button>  
                               }
                                <span style={{marginLeft: 10}} onClick={()=>{
                                 fetchZKDevicesData();
                                 setSingleModal(false)
                                }} className="btn btn-outline-danger">Cancel</span>  
                               </Col>
                               </Row>

                             </Form>
                   
                      </div>
                      </Modal>
                      <Modal
                      isOpen={timeModal}
                      toggle={() => {
                        tog_centerTime();
                      }}
                      centered
                      style={{width:'100%'}}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Edit ZK Machine Time</h5>
                       
                       
                        <button
                          type="button"
                          onClick={() => {
                            setTimeModal(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {/* <Form> */}
                          <Row>
                            <Col md={12}>
                              <Label>Current Device Time</Label>
                               <p>{singleZkData?.deviceTime}</p>
                            </Col>
                            <Col md={12}>
                              <Label>Update Time</Label>
                              <Row className="mb-4">
                              <Label
                                htmlFor="horizontal-email-Input"
                                className="col-sm-12 col-form-label"
                              >
                                Select Timezone
                              </Label>
                                <Col sm={12}>
                                  <Select
                                    value={timezoneFor}
                                    options={timeZone}
                                    onChange={e => {
                                      setTimezoneFor(e)
                                     const value =  getFormattedDateTime(e.value);
                                     setUpdateTime(value);

                                    }}
                                    className="select2-selection"
                                    placeholder="Select Timezone"
                                  />
                                </Col>

                            </Row>
                            </Col>
                            <Col md={12}>
                            <Label>TimeZone</Label>
                            <p>{updateTime}</p>
                            </Col>
                            <Col md={12}>
                              <button className='btn btn-primary' onClick={()=>updateTimeForMachine()}>Update</button>
                              <button style={{marginLeft:10}} className='btn btn-outline-danger'>Cancel</button>
                            </Col>
                          </Row>
                        {/* </Form> */}
                      </div>
                      </Modal>
            </React.Fragment>
  )
}
