import React, { useEffect, useState } from "react"
import ReactEcharts from "echarts-for-react"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor"

const Tasks = ({ dataColors   }) => {
  const doughnutEChartColors = getChartColorsArray(dataColors)
  const [dataFor, setOptionsForTasks ]= useState([])
useEffect(()=>{
  getTasksCounter(1)
},[1])
 function getTasksCounter (pram){
  let localArray =JSON.parse( localStorage.getItem('authUser'));
  console.log(localArray)
  let org = localArray.map((e)=>e.org_id);
  let userId = localArray.map((e)=>e.user_id);
  let role = localArray.map((e)=>e.role);
  var formdata = new FormData();
  formdata.append('method', 'get-tasks-counter');
  formdata.append('org_id', org);
  formdata.append('emp_id' , userId);
  formdata.append('role' , role);


  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };

  console.warn(formdata)
  fetch('https://api.frontforcecrm.com/tasks.php', requestOptions)
    .then(response => response.json())
    .then(result => {
      let options =  {
        toolbox: {
          show: false,
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          orient: "vertical",
          x: "left",
          data: ["Active", "Completed"],
          textStyle: {
            color: ["#8791af"],
          },
        },
        color: doughnutEChartColors,
        series: [
          {
            name: "Tasks ",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "30",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              { value:  parseFloat(result.active_tasks) || 0, name: "Active" },
              { value: parseFloat(result.completed_tasks) || 0 , name: "Completed" },
            ],
          },
        ],
      }
     setOptionsForTasks(options)
    })
    .catch(error => console.log('error', error));
};

  return (
    <React.Fragment>
      
      {
        dataFor.length !== 0 ? 
        <ReactEcharts style={{ height: "350px" }} option={dataFor} />:null
         
      }
    </React.Fragment>
  )
}
export default Tasks
