import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Spinner,
} from "reactstrap"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

//redux
import { useSelector, useDispatch } from "react-redux"
import withRouter from "components/Common/withRouter"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, resetProfileFlag } from "../../store/actions"
import axios from "axios"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
const UserProfile = () => {
  //meta title
  document.title = `Profile | ${process.env.REACT_APP_NAME}`

  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [currentId, setcurrentID] = useState('')
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [username, setuserName] = useState("")
  const [phoneCode, setPhoneCode] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [profileImage, setProfileImage] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [EmployeeID, setEmployeeID] = useState("")
  const [SendingImage, setSendingImage] = useState("")
  const [spinner, setSpinner] = useState(false)

  const getData = () => {
    let local = localStorage.getItem("authUser")
    let json = JSON.parse(local)

    json.map(e => {
      console.log(e);
      setcurrentID(e.user_id)
      
      var formdata = new FormData()
      formdata.append("method", "get-organization-by-id")
      formdata.append("emp_id", e.user_id)
      formdata.append("org_id", e.org_id)

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(process.env.REACT_APP_LEAVE_API_URL+"/company.php", requestOptions)
        .then(response => response.json())
        .then(result => {
          setProfileImage(result.user.profile_image)
          setFirstName(result.user.first_name)
          setEmployeeID(result.user.id)
          setuserName(result.user.username)
          setEmailAddress(result.user.email_address)

          if (result.user.phone_number !== null) {
            let value = result.user.phone_number.split("|")
            setPhoneCode(value[0])
            setPhoneNumber(value[1])
          }
        })
        .catch(error => console.log("error", error))
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const toggleEditView = () => {
    setIsEditing(!isEditing)
  }
  const handleImageChange = event => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        const selectedImageURI = e.target.result
        setSendingImage(selectedImageURI)
      }

      reader.readAsDataURL(file)
    }
  }
  const handleSaveChanges = async (e) => {
    setSpinner(true)
    e.preventDefault()
    let valueForImage = document.getElementById("fileInput")
    const val = valueForImage.files[0]
   
    let form = new FormData()
    form.append("method", "update-profile-information")
    form.append("user_id", currentId)
    form.append("fullname", firstName)
    form.append("email", emailAddress)
    form.append("username", username)
    form.append("phone_number", phoneCode + "|" + phoneNumber)

    form.append("profileimage", val)
  
    axios.post('https://api.frontforcecrm.com/login.php', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(result => {
        if (result.data.message === "success") {
          Swal.fire("Profile data is successfully ")
          console.log(result.data.user_data)
          localStorage.setItem('authUser', JSON.stringify(result.data.user_data))
          setSpinner(false)
        } else if (result.data.message === "Username is already exits") {
          Swal.fire("Username is already exits")
          setSpinner(false)
        } else {
          Swal.fire("Error! Profile data is not updated ")

          setSpinner(false)
        }
      })
      .catch(error => console.log("error", error))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="" breadcrumbItem="Profile" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      {
                        profileImage == ""?

                        <Skeleton width={80} height={80}  borderRadius={100}/>
                       :
                       <div>
                       <label htmlFor="fileInput" style={{borderRadius:100}}>
                           <img
                             src={SendingImage || profileImage}  // Use the selectedImage or the default logoImg
                             alt=""
                             style={{borderRadius:100 ,cursor: 'pointer' }}
                             className="avatar-md rounded-circle img-thumbnail"
                             height="80"
                             width={80}
                           />
                         </label>
                       
                         <Input
                           type="file"
                           id="fileInput"
                           accept=".jpg, .jpeg, .png"
                           style={{display:'none'}}
                           onChange={handleImageChange}  // Handle file selection
                       />
                       </div>
                      }
                        
                     
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted" style={{paddingLeft:10}}>
                        {
                          firstName == null ? 
                          <Skeleton width={300}/>:
                          <p className="mb-1"><strong>Employee  name:</strong> {firstName + ' ' + lastName}</p>
                        }
                        {
                          currentId == '' ? 
                          <Skeleton width={300}/>
                          :
                          <p className="mb-1"><strong>Employee  no:</strong> #{currentId}</p>
                        }
                        {
                          emailAddress  == ""  ?
                          <Skeleton width={300}/>:
                          <p className="mb-1"><strong>Email : </strong> {emailAddress}</p>

                        }
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Personal Information </h4>

          <Card>
            <CardBody>
              <Row>
                <Col lg="8">
                  {isEditing == false ? (
                    <div>
                        <Row>
                        <Col lg="12">
                          <Button
                            type="button"
                            color="primary"
                            className="float-end"
                            onClick={toggleEditView}
                          >
                            Edit Mode
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Label>Username:</Label>
                          <Input
                            type="text"
                            name="username"
                            value={username}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Label>First Name:</Label>
                          <Input
                            type="text"
                            name="firstName"
                            value={firstName}
                            disabled
                          />
                        </Col>
                        <Col lg="6">
                          <Label>Last Name:</Label>
                          <Input
                            type="text"
                            name="lastName"
                            value={lastName}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Label>Email:</Label>
                          <Input
                            type="text"
                            name="email"
                            value={emailAddress}
                            disabled
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Label>Phone Code:</Label>
                          <Input
                            type="text"
                            name="phoneCode"
                            value={phoneCode}
                            disabled
                          />
                        </Col>
                        <Col lg="6">
                          <Label>Phone Number:</Label>
                          <Input
                            type="text"
                            name="phoneNumber"
                            value={phoneNumber}
                            disabled
                          />
                        </Col>
                      </Row>
                      
                    
                    </div>
                  ) : (
                    <Form onSubmit={handleSaveChanges}>
                      <Row style={{paddingTop:0}}>
                        <Col lg="12">
                        
                          {
                            spinner == true ? 
                            <Spinner className="float-end"/> : 
                          <Button
                            type="submit"
                            color="primary"
                            className="float-end"
                          >
                            Save Changes
                          </Button>
                          
                          }
                          
                          <Button
                            onClick={()=>setIsEditing(false)}
                            color="success"
                            className="float-end"
                            style={{marginRight:5}}
                          >
                            View Mode
                          </Button> 
                        
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Label>Username:</Label>
                          <Input
                            type="text"
                            name="username"
                            value={username}
                            onChange={e => setuserName(e.target.value)}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Label>First Name:</Label>
                          <Input
                            type="text"
                            name="firstName"
                            value={firstName}
                            onChange={e => setFirstName(e.target.value)}
                            required
                          />
                        </Col>
                        <Col lg="6">
                          <Label>Last Name:</Label>
                          <Input
                            type="text"
                            name="lastName"
                            value={lastName}
                            onChange={e => setLastName(e.target.value)}
                            
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <Label>Email:</Label>
                          <Input
                            type="email"
                            name="email"
                            value={emailAddress}
                            onChange={e => setEmailAddress(e.target.value)}
                            required
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <Label>Phone Code:</Label>
                          <Input
                            type="text"
                            name="phoneCode"
                            value={phoneCode}
                            onChange={e => setPhoneCode(e.target.value)}
                            required
                          />
                        </Col>
                        <Col lg="6">
                          <Label>Phone Number:</Label>
                          <Input
                            type="text"
                            name="phoneNumber"
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                            required
                          />
                        </Col>
                      </Row>
                      
                      
                    </Form>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(UserProfile)
