import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../assets/style/style.css"

import {
  Button,
  Card,
  CardBody,
  Col,
  Alert,
  Container,
  Table,
  Form,
  CardTitle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import img1 from "../../assets/images/mobile-full.f01843f1.svg"
import img2 from "../../assets/images/desktop.15c3369c.svg"
import img3 from "../../assets/images/tablet-full.9ad10738.svg"
import img4 from "../../assets/images/web.52834f02.svg"
import img7 from "../../assets/images/approve-hour.f7e7a87f.svg"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import TableContainer from "../../components/Common/TableContainer"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { act } from "react-dom/test-utils"

const TimeTrackingPolicies = props => {
  //meta title
  document.title = "Timeoff | Skote - React Admin & Dashboard Template"

  const [activeTab, setActiveTab] = useState("1")
  const [editcheck, setEditcheck] = useState(false)
  const [clockrest, setCloclkrest] = useState(false)
  const [editsc, setEditsc] = useState(false)
  const [devicelock, setDevicelock] = useState(false)

  const [isChecked, setIsChecked] = useState(false)

  const handleCheckboxClick = () => {
    setIsChecked(!isChecked)
  }
  const handleclockrestriction = () => {
    setCloclkrest(!clockrest)
  }

  const handleeditcheckbox = () => {
    setEditcheck(!editcheck)
  }

  const handleeditsc = () => {
    setEditsc(!editsc)
  }

  const handleeditdevicelock = () => {
    setDevicelock(!devicelock)
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}

          <Row className="mb-4">
            <Col xl="6">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Policies
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Approvals
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div className="text-muted mb-4 font-size-14">
                    These time tracking rules are applied as defaults to
                    everyone in the organization, unless stated otherwise.
                    <br /> You can override these settings for each group in
                    Group Settings.
                  </div>
                  <Row>
                    <Col xl="9">
                      <Card>
                        <CardBody>
                          <Row className="mb-4">
                            <Col xl={12}>
                              <Row>
                                <Col xl={6}>
                                  <CardTitle>DEVICE RESTRICTIONS</CardTitle>
                                </Col>
                                {!editcheck ? (
                                  <Col xl={6}>
                                    <div className="float-end">
                                      <i
                                        className="bx bx-edit-alt"
                                        style={{
                                          fontSize: "2em",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleeditcheckbox}
                                      />
                                    </div>
                                  </Col>
                                ) : (
                                  <Col xl={6}></Col>
                                )}
                              </Row>

                              <div className="text-muted mb-3">
                                Select the platforms your team members and
                                managers are allowed to clock in and out from.
                              </div>
                              <Row>
                                <Col xl={3}>
                                  <input
                                    type="checkbox"
                                    id="myCheckbox1"
                                    disabled={!editcheck}
                                  />
                                  <label
                                    htmlFor="myCheckbox1"
                                    className="custom-checkbox text-center card-title font-size-14"
                                  >
                                    <img src={img1} alt="Image 2" />
                                    Mobile
                                  </label>
                                </Col>
                                <Col xl={3}>
                                  <input
                                    type="checkbox"
                                    id="myCheckbox2"
                                    disabled={!editcheck}
                                  />
                                  <label
                                    htmlFor="myCheckbox2"
                                    className="custom-checkbox text-center card-title font-size-14"
                                  >
                                    <img src={img2} alt="Image 3" />
                                    Desktop
                                  </label>
                                </Col>
                                <Col xl={3}>
                                  <input
                                    type="checkbox"
                                    id="myCheckbox3"
                                    checked
                                    disabled={!editcheck}
                                  />
                                  <label
                                    htmlFor="myCheckbox3"
                                    className="custom-checkbox text-center card-title font-size-14"
                                  >
                                    <img src={img4} alt="Image 3" />
                                    Browser
                                  </label>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="form-check font-size-14 mb-4 ">
                                <input
                                  className=" form-check-input"
                                  type="checkbox"
                                  id="upcomingtaskCheck01"
                                  disabled={!editcheck}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="upcomingtaskCheck01"
                                >
                                  Allow members to clock in and out on mobile
                                  while offine
                                </label>
                              </div>
                            </Col>
                          </Row>
                          {editcheck ? (
                            <Row className="float-end">
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    className="w-md"
                                    onClick={handleeditcheckbox}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Row></Row>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <Row className="mb-4">
                            <Col xl={12}>
                              <Row>
                                <Col xl={6}>
                                  <h4 className="card-title mb-3">
                                    TIME CLOCK RESTRICTIONS
                                  </h4>
                                </Col>
                                {!clockrest ? (
                                  <Col xl={6}>
                                    <div className="float-end">
                                      <i
                                        className="bx bx-edit-alt"
                                        style={{
                                          fontSize: "2em",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleclockrestriction}
                                      />
                                    </div>
                                  </Col>
                                ) : (
                                  <Col xl={6}></Col>
                                )}
                              </Row>
                              <div className="text-muted mb-3">
                                Require members to track time with additional
                                verification methods in Personal Mode.
                                <br />
                                Restrictions for Kiosk Mode can be configured in
                                Kiosk Settings on shared devices.
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col xl={12}>
                              <Card
                                className={`custom-checkbox-card ${
                                  isChecked ? "checked" : ""
                                } ${!clockrest ? "disabled" : ""}`}
                                onClick={handleCheckboxClick}
                              >
                                <CardBody>
                                  <CardTitle>Flexible</CardTitle>
                                  <div className="text-muted mb-3">
                                    Good for teams with higher onsite autonomy
                                  </div>
                                  <div className="custom-horizontal-line"></div>
                                  <Row>
                                    <Col xl={12}>
                                      <Row>
                                        <Col xl={4}>
                                          <div className="card-title mb-4 font-size-14">
                                            <i className="bx bx-user" /> Face
                                            recognization disable
                                            <div className="text-muted font-size-10">
                                              Self capturing not required
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl={4}>
                                          <div className="card-title mb-4 font-size-14">
                                            <i className="bx bx-map" /> GPS
                                            location not required
                                            <div className="text-muted font-size-10">
                                              Geofences disabled
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xl={4}>
                                          <div className="card-title mb-4 font-size-14">
                                            <i className="bx bx-timer" />{" "}
                                            Editable time entries
                                            <div className="text-muted font-size-10"></div>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>

                          {clockrest ? (
                            <Row className="float-end">
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    className="w-md"
                                    onClick={handleclockrestriction}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Row></Row>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <Row className="mb-4">
                            <Col xl={12}>
                              <Row>
                                <Col xl={6}>
                                  <h4 className="card-title mb-3">
                                    SCREENSHOTS
                                    <span
                                      className="badge rounded-pill badge-soft-warning font-size-11"
                                      style={{ marginLeft: "4px" }}
                                    >
                                      New Feature
                                    </span>
                                  </h4>
                                </Col>
                                {!editsc ? (
                                  <Col xl={6}>
                                    <div className="float-end">
                                      <i
                                        className="bx bx-edit-alt"
                                        style={{
                                          fontSize: "2em",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleeditsc}
                                      />
                                    </div>
                                  </Col>
                                ) : (
                                  <Col xl={6}></Col>
                                )}
                              </Row>

                              <div className="text-muted mb-3">
                                Capture screen monitors of team members when
                                they are clocked in via desktop app.
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="form-check font-size-14 mb-4 ">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="upcomingtaskCheck02"
                                  disabled={!editsc}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="upcomingtaskCheck02"
                                >
                                  Require screenshot capturing
                                </label>
                              </div>
                            </Col>
                          </Row>
                          {editsc ? (
                            <Row className="float-end">
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    className="w-md"
                                    onClick={handleeditsc}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Row></Row>
                          )}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <Row className="mb-4">
                            <Col xl={12}>
                              <Row>
                                <Col xl={6}>
                                  <h4 className="card-title mb-3">
                                    DEVICE LOCK
                                  </h4>
                                </Col>
                                {!devicelock ? (
                                  <Col xl={6}>
                                    <div className="float-end">
                                      <i
                                        className="bx bx-edit-alt"
                                        style={{
                                          fontSize: "2em",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleeditdevicelock}
                                      />
                                    </div>
                                  </Col>
                                ) : (
                                  <Col xl={6}></Col>
                                )}
                              </Row>

                              <div className="text-muted mb-3">
                                To prevent a user from clocking in from a
                                different device, enable this setting to
                                eliminate fraudulent clocking in and out.
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="form-check font-size-14 mb-4 ">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="upcomingtaskCheck03"
                                  disabled={!devicelock}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="upcomingtaskCheck03"
                                >
                                  Enable devic lock for members
                                </label>
                              </div>
                            </Col>
                          </Row>
                          <Alert color="secondary" role="alert">
                            Device lock can be used together with Face Spoofing
                            Prevention to eliminate fraudulent clocking in and
                            out.
                          </Alert>
                          {devicelock ? (
                            <Row className="float-end">
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="secondary"
                                    className="w-md"
                                    onClick={handleeditdevicelock}
                                  >
                                    Cancel
                                  </Button>
                                </div>
                              </Col>
                              <Col>
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md"
                                  >
                                    Submit
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <Row></Row>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>

              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <div className="product-img position-relative">
                    <img
                      src={img7}
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                  <div className="mt-4 text-center">
                    <h5 className="mb-3 text-truncate">
                      No pay periods set up yet
                    </h5>
                  </div>
                  <div className="text-muted mb-3 text-center">
                    Process timesheets for payroll with fixed
                    <br /> pay periods and approval workflows.
                  </div>
                  <div className="text-center">
                    <Button type="submit" className="btn btn-warning">
                      Set up pay periods
                    </Button>
                  </div>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default TimeTrackingPolicies
