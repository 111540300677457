import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Input,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"
import { Link } from "react-router-dom"
import Knob from "../AllCharts/knob/knob"

import img1 from "../../assets/images/activities.256a49b0.svg"

//Date Picker
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { act } from "react-dom/test-utils"

const Activitiesreports = props => {
  //meta title
  document.title = "ReportOverview | Skote - React Admin & Dashboard Template"

  const [activeTab, setactiveTab] = useState("1")
  const [activities, setactivities] = useState(22)
  const [projects, setprojects] = useState(33)
  const [clients, setclients] = useState(11)

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <div className="checkout-tabs">
            <Row className="my-4">
              <Col sm="6">
                <Link
                  to="/report/overview"
                  className="btn text-muted d-none d-sm-inline-block btn-link"
                >
                  <i className="mdi mdi-arrow-left me-1" /> Tracked Time Report
                </Link>
              </Col>
            </Row>
            <Row>
              <Col lg="2" sm="3">
                <Nav className="flex-column" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "1" })}
                      onClick={() => {
                        setactiveTab("1")
                      }}
                    >
                      <i className="bx bx-task d-block check-nav-icon mt-1 mb-1" />
                      <p className="fw-bold mb-1">ACTIVITIES</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "2" })}
                      onClick={() => {
                        setactiveTab("2")
                      }}
                    >
                      <i className="bx bx-folder-open d-block check-nav-icon mt-1 mb-1" />
                      <p className="fw-bold mb-1">PROJECTS</p>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: activeTab === "3" })}
                      onClick={() => {
                        setactiveTab("3")
                      }}
                    >
                      <i className="bx bx-group d-block check-nav-icon mt-1 mb-1" />
                      <p className="fw-bold mb-1">CLIENTS</p>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col lg="8" sm="9">
                <Card className="pt-2 pb-2">
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col
                            lg="12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="text-center" dir="ltr">
                              <h5 className="font-size-14 mb-3">Activities</h5>
                              <Knob
                                value={activities}
                                fgColor="#556ee6"
                                thickness={0.22}
                                activities={true}
                                height={200}
                                width={150}
                                onChange={e => {
                                  setactivities(e)
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col
                            lg="12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="text-center" dir="ltr">
                              <h5 className="font-size-14 mb-3">Projects</h5>
                              <Knob
                                value={projects}
                                fgColor="#4AC18E"
                                thickness={0.22}
                                projects={true}
                                height={200}
                                width={150}
                                onChange={e => {
                                  setprojects(e)
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="3">
                        <Row>
                          <Col
                            lg="12"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <div className="text-center" dir="ltr">
                              <h5 className="font-size-14 mb-3">Clients</h5>
                              <Knob
                                value={clients}
                                fgColor="#1D1E3A"
                                thickness={0.22}
                                clients={true}
                                height={200}
                                width={150}
                                onChange={e => {
                                  setclients(e)
                                }}
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Activitiesreports
