import React, { useEffect, useMemo, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"
import Switch from 'react-switch'
//import images
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "./TaskCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Spinner
} from "reactstrap"

const TaskStatus = () => {
  //meta title
  const { id } = useParams()
  const [counter, setCounter] = useState(1)
  const [assignTo, setassignTo] = useState([])
  const [mockData2, setmockData2] = useState([])
  const [isAttachmentSwitch, setisAttachmentSwitch] = useState(false)
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  document.title = `${id}| ${process.env.REACT_APP_NAME}`
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [DataToBeSetTasks, setDataToBeSetTasks] = useState([])
  const [job, setJob] = useState(null)
  const [createTaskShow, setCreateTaskShown] = useState(false)
  const [clientType, setClientType] = useState('')
  // validation

   const fetchAllProjects = async () => {
    
    let valueFrom =  localStorage.getItem('authUser');
    let convertedValue = JSON.parse(valueFrom);
    let orgId = convertedValue.map(e => e.org_id);
    let user_id = convertedValue.map(e => e.user_id);
    let role = convertedValue.map(e => e.role);
   
      var formdata = new FormData();
      formdata.append('method', 'get-tasks-assigned-all-by-status');
      formdata.append('id', user_id);
      formdata.append('role', role);
      formdata.append('status', 'null');
      var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow',
      };
      await fetch(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php', requestOptions)
        .then(response => response.json())
        .then(result => {
          if(role == 'admin'){
            const total = result.filter(item => {
              return item ;
            })
            console.log(total)
            const active = result.filter(item => {
              let currentDate = new Date();
              let deadlineDate = new Date(item.task.deadline_date);
              if(item.task.status == 'active' &&  deadlineDate > currentDate){
                return item;
              }
  
            })
            const overdue = result.filter(item => {
              let currentDate = new Date();
              let deadlineDate = new Date(item.task.deadline_date);
              
              if(deadlineDate < currentDate && item.task.status == 'active'){
                return item;
              }
            })
            const completed = result.filter(item => {
              if(item.task.status == 'completed' ){
                return item;
              }
            })
            if(id == 'total'){
              setJobsList(total)
            }
            else if(id == 'active'){
              setJobsList(active)
            }
            else if(id == 'overdue'){
              setJobsList(overdue)
            }
            else if(id == 'completed'){
              setJobsList(completed)
            }
          }else{
          const total = result.filter(item => {
            let assignedTo = item.assigned_to.find(
              user => user.id == user_id,
            );
            if(assignedTo.lenght !== 0 ){
              return item;
            } 
          })
          
          const active = result.filter(item => {
            let currentDate = new Date();
            let deadlineDate = new Date(item.task.deadline_date);
            let assignedTo = item.assigned_to.find(
              user => user.id == user_id,
            );
           
            if(assignedTo.lenght !== 0 && item.task.status == 'active' &&  deadlineDate > currentDate){
              return item;
            }

          })
          const overdue = result.filter(item => {
            let currentDate = new Date();
            let deadlineDate = new Date(item.task.deadline_date);
            let assignedTo = item.assigned_to.find(
              user => user.id == user_id,
            );
            if(assignedTo.lenght !=0 && deadlineDate < currentDate && item.task.status == 'active'){
              return item;
            }
          })
          const completed = result.filter(item => {
            let assignedTo = item.assigned_to.find(
              user => user.id == user_id,
            );
            if(assignedTo.lenght !=0 && item.task.status == 'completed' ){
              return item;
            }
          })
          if(id == 'total'){
            setJobsList(total)
          }
          else if(id == 'active'){
            setJobsList(active)
          }
          else if(id == 'overdue'){
            setJobsList(overdue)
          }
          else if(id == 'completed'){
            setJobsList(completed)
          }
        }
        })
        .catch(error => console.log('error', error));
    
  };
   
  
  
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const formatDateTime = (inputDate) => {
    // Parse the input date string into a Date object
    const date = new Date(inputDate);
    
    // Extract the year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    
    // Construct the formatted date string in MM/DD/YYYY format
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  
 
  useEffect(() => {
    fetchAllProjects();
  }, [])


  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }


  
   
  const columns = useMemo(
    () => [
      {
        Header: "Task title",
        accessor: "task.task_title",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Project Name",
        accessor: "project",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Assigned Members",
        accessor: "assigned_to",
        filterable: true,
        Cell: cellProps => {
            return (
                <>
                  {cellProps.value.map((e, i) => (
                    <img key={i} src={e.profile_image} className="avatar-sm rounded-circle" />
                  ))}
                </>
              );
            
        },
      },
      {
        Header: "Status",
        accessor: "task.status",
        filterable: true,
        Cell: cellProps => {
            
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
            const obj = {
                task: cellProps.cell.row.original.task,
                project:cellProps.cell.row.original.project,
                assigned_to: cellProps.cell.row.original.assigned_to
            }
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link
               to={'/task-remarks'}
               state={{id: cellProps.value , task_data : obj}}
                                                  
                                                
                   className="btn btn-sm btn-soft-primary">
                    
                  <i className="mdi mdi-file-edit-outline" id="viewtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                Edit
              </UncontrolledTooltip>
              <li>
                <Link
                  to="#"
                  
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    // const jobData = cellProps.row.original;
                    // onClickDelete(jobData);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          
          )
        },
      },
     
    ],
    []
  )

  return (
    <React.Fragment>
      
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tasks" breadcrumbItem={`${id} Tasks`} />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1"> </h5>
                    
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={jobsList}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
      
        </div>
      </div>
      <Offcanvas
          isOpen={createTaskShow}
          direction="bottom"
        style={{height:800}}
          toggle={()=>setCreateTaskShown(false)}
        >
          <OffcanvasHeader
          toggle={()=>setCreateTaskShown(false)}
          >
            Create Project
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                if(clientType == ""){
                    toastr.error('Select the client type');
                    return false;
                }else if(e.target.elements.project_name.value == ""){
                    toastr.error('Project name is required');
                    return false
                }else if(e.target.elements.project_description.value == ""){
                    toastr.error('Project name is required');
                    return false
                }else{
                    let local = JSON.parse(localStorage.getItem('authUser'));
                    let org = local.map((e)=>e.org_id)
                    setLoadingSpinner(true)
                    let form = new FormData();
                    const fileInput = document.querySelector('input[type="file"]')
                    const file = isAttachmentSwitch === true ? fileInput.files[0] : null
                    form.append('method', 'create-project');
                    form.append('org_id', org);
                    form.append('client_type' , clientType);
                    if(clientType == 'new client'){
                        form.append('client_name',e.target.elements.client_name.value)
                        form.append('client_email',e.target.elements.client_email.value)
                        form.append('client_phone',e.target.elements.client_phone.value)
                        form.append('client_address',e.target.elements.client_address.value)
                    }else{
                        form.append('client_id',clientType == 'existing client' ? e.target.elements.client_id.value : null)
                    }
                    form.append('project_name',e.target.elements.project_name.value)
                    form.append('project_description',e.target.elements.project_description.value)
                    if (isAttachmentSwitch == true) {
                      form.append('file_upload', file);
                    }
                   
                    axios.post(
                      process.env.REACT_APP_LEAVE_API_URL+'/projects.php',form,{headers:{
                          "Content-Type": "multipart/form-data",
                        }}
                       ).then(result => {
                          setLoadingSpinner(false)
                          if(result.data === 'success'){
                              toastr.success('You successfully added the project');
                              getAllTasks();
                              setCreateTaskShown(false);
                          }else{
                              toastr.error('Error while adding the project');
                          }
                      })
                      .catch(e => console.log(e));
                }  
            }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                

                  <label>{'Select Client'}</label>
                    <div className="row">
                    <div className="col-md-2">

                        <div className="form-check form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiocolor3"
                              name="customRadiocolor1"
                              className="form-check-input"
                              onChange={(e)=>setClientType(e.target.value)}
                              value={'existing client'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor3"
                            >
                              Existing Client
                            </label>
                          </div>
                    </div>
                    <div className="col-md-2">
                           <div className="form-check form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiocolor2"
                              name="customRadiocolor1"
                              className="form-check-input"
                              onChange={(e)=>setClientType(e.target.value)}
                              value={'new client'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor2"
                            >
                              New Client
                            </label>
                          </div>
                    </div>
                    </div>
                  
                  
                </Col>
                
                {

                  clientType == "" ?  null:
                  ( clientType == 'existing client' ? 
                    <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Select Client</label>
                    <Select
                      
                      onChange={item => 
                        setassignTo(item.value)
                      }
                      options={mockData2}
                      name="client_id"
                      className="select2-selection"
                    />
                  </div>
                </Col>: <Col md={12}>
                    <Label>Client Information</Label>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Input
                            type="text"
                            className="form-control"
                            name="client_name"
                            placeholder="Client Name"
                            
                            />
                        </Col>
                        <Col md={4} className="mb-3">
                            <Input
                            type="email"
                            className="form-control"
                            name="client_email"
                            placeholder="Client Email"

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Input
                            type="tel"
                            className="form-control"
                            name="client_phone"
                            placeholder="Client Phone"
                            
                            />
                        </Col>
                        <Col md={12} className="mb-3">
                            <Input
                            type="textarea"
                            className="form-control"
                            name="client_address"
                            placeholder="Client Address"

                            />
                        </Col>
                    </Row>
                </Col>)
                }
                <Col md={12} className="mb-3">
                  <Label>Project Name</Label>
                 <Input
                    name="project_name"
                    type="text"
                    className="form-control"
                    placeholder="..."
                 />
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Project Description</Label>
                  <textarea
                    name="project_description"
                    type="text"
                    className="form-control"
                    placeholder="Type description here...."
                  ></textarea>
                </Col>
               
                <Col md={12}>
                <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            setisAttachmentSwitch(!isAttachmentSwitch);
                          }}
                    />
                    </div>
                </Col>
                {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'end'}}>
                    <button type="button"  onClick={()=>setCreateTaskShown(false)} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        loadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Add Project</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
    </React.Fragment>
  )
}

export default TaskStatus
