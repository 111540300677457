import React from 'react';
import { useState } from 'react';
import { Button, Col, Container, Input, Row } from 'reactstrap';
// import Ionicons from 'react-icons/io5';


const Dialpad = ({ pin, onDigitPress, onDeletePress, onPinChange }) => {
  return (
    <Container className="text-center mt-5">
      <h5 className="mb-4 font-weight-bold text-dark">
        Enter your 4-digit Pin
      </h5>
      <Row className="mb-4">
        {Array.from({ length: 4 }, (_, index) => (
          <Col md={3} key={index}>
            <div style={{height:50 ,display:'flex', justifyContent:'center',alignItems:'center'}} className="border p-2 rounded">
              <span  className="font-weight-bold">
                {pin[index] ? '*' : null}
              </span>
            </div>
          </Col>
        ))}
      </Row>
      <Input
        type="text"
        className="d-none"
        maxLength={4}
        value={pin}
        onChange={(e) => onPinChange(e.target.value)}
      />
      <Row className="justify-content-center">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map((digit) => (
          <Col key={digit} md={2} className="mb-3 text-center">
            <Button
              color="light"
              style={{height:50 ,width:50}}
              className=" d-flex align-items-center justify-content-center"
              onClick={() => onDigitPress(digit)}
            >
              {digit}
            </Button>
          </Col>
        ))}
        <Col  md={3}  className="mb-3">
          <Button
            block
            color="light"
            className="h-100 d-flex align-items-center justify-content-center"
            onClick={onDeletePress}
          >
            X
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Dialpad;
