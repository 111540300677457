import React, { useState } from "react"
import PropTypes from "prop-types"
import { Label, Progress, Row, Table } from "reactstrap"
import Swal from "sweetalert2"
import axios from "axios"

const formatAmount = (amount, currency) => {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
  })
}

  
function numberToWords(num) {
  const ones = ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
  const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
  const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

  if (num < 10) {
      return ones[num];
  }

  if (num < 20) {
      return teens[num - 10];
  }

  if (num < 100) {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ' ' + ones[num % 10] : '');
  }

  if (num < 1000) {
      return ones[Math.floor(num / 100)] + ' hundred' + (num % 100 !== 0 ? ' ' + numberToWords(num % 100) : '');
  }

  if (num < 1000000) {
      return numberToWords(Math.floor(num / 1000)) + ' thousand' + (num % 1000 !== 0 ? ' ' + numberToWords(num % 1000) : '');
  }

  if (num < 1000000000) {
      return numberToWords(Math.floor(num / 1000000)) + ' million' + (num % 1000000 !== 0 ? ' ' + numberToWords(num % 1000000) : '');
  }

  return 'Number too large to convert to words';
}
function formatJoingDate(inputDate) {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    const parts = inputDate.split('/');
    const day = parseInt(parts[1], 10);
    const month = months[parseInt(parts[0], 10) - 1];
    const year = parseInt(parts[2], 10);

    return day + ' ' + month + ' ' + year;
}
const SalarySlip =   ({ employee,editView,monthFor,yearFor }) => {
  
  const {amount_per_once,amount_per_month,deduct_salary,schedule_off_status,month_tax_precentage,allowance_per_month,allowance_once,month_commission,month_bonus,salary_for_this_month,month_incentive,month_overtime,trady,month_tax,loan_amount,loan_currency,advance_salary,advance_salary_currency,absent_precentage,absent,leave_precentage,present_precentage, present,leave,unpaid,total_working_days,emp_id,last_name,first_name,basic_salary,job_location,job_cat, pay_mode,salary_currency,joing_date,job_title,allowance_per_month_deduct_data,allowance_once_deduct_data,amount_per_once_deduct,amount_per_month_deduct} = employee[0]
  const totalPayable = basic_salary
  let loan_amountChange = loan_amount !== null ? loan_amount : 0 
  let loan_currencyChange = loan_currency  !== null ? loan_currency : salary_currency
  let adv_salaryChange = advance_salary  !== null ? advance_salary : 0
  let adv_currencyChange = advance_salary_currency  !== null ? advance_salary_currency : salary_currency
  let scheduleSalary = schedule_off_status == 'No' ? parseFloat(salary_for_this_month)  : 0
 
  const numberToWord = Math.round(parseFloat(Math.round(basic_salary)) +parseFloat(amount_per_once)+parseFloat(amount_per_month) - parseFloat(loan_amountChange) - parseFloat(adv_salaryChange) - parseFloat(trady) - parseFloat(month_tax) +parseFloat(month_bonus) + parseFloat(month_commission) + parseFloat(month_incentive) + parseFloat(month_overtime) - parseFloat(unpaid)-scheduleSalary - parseFloat(amount_per_month_deduct) - parseFloat(amount_per_once_deduct) - parseFloat(deduct_salary));




  const  sendMailToUser =()=> {
    let val = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_title);
    const content = document.querySelector('.print_content').innerHTML;
    let form = new FormData();
    form.append('method', 'send_salary_email');
    form.append('emp_id', emp_id);
    form.append('content_for_pdf', content);
    form.append('org_name', val);
    form.append('month', monthFor);
    form.append('year', yearFor);
    form.append('fullname', first_name+ ' ' + last_name)
    form.append('designation', job_title)
    form.append('location', job_location)
    form.append('dept', job_cat)
    form.append('hiring_date' , formatJoingDate(joing_date))
    form.append('unpaid' , absent)
    form.append('unpaid_amount' , formatAmount(parseFloat(unpaid) , salary_currency))
    form.append('leave' , leave)
    form.append('working_days' , total_working_days)
    form.append('present' , present)
    form.append('basic_salary' , formatAmount(parseFloat(basic_salary) , salary_currency))
    form.append('commission' , formatAmount(parseFloat(month_commission) , salary_currency))
    form.append('bonus' , formatAmount(parseFloat(month_commission) , salary_currency))
    form.append('incentive' , formatAmount(parseFloat(month_incentive) , salary_currency))
    form.append('overtime' , formatAmount(parseFloat(month_overtime) , salary_currency))
    form.append('salary_this' , formatAmount(Math.round(parseFloat(salary_for_this_month)) , salary_currency))
    form.append('trady' , formatAmount(parseFloat(trady) , salary_currency))
    form.append('loan' , formatAmount(parseFloat(loan_amountChange) , salary_currency))
    form.append('tax' , formatAmount(parseFloat(month_tax) , salary_currency))
    form.append('advance' , formatAmount(parseFloat(advance_salary) , salary_currency))
    form.append('gross_earnings' , formatAmount(  parseFloat(Math.round(salary_for_this_month)) +parseFloat(month_bonus)+ parseFloat(month_commission) + parseFloat(month_incentive) + parseFloat(month_overtime), salary_currency))
    form.append('gross_deduction' , formatAmount(parseFloat(loan_amountChange) + parseFloat(adv_salaryChange) + parseFloat(trady) + parseFloat(month_tax) + parseFloat(unpaid), salary_currency))
    form.append('words_amount', numberToWord > 0 ? numberToWords(numberToWord) : null )
    form.append('net_amount', formatAmount(parseFloat(numberToWord), salary_currency))
    let optionFor = {
      method: "POST",
      body: form
    }
    fetch('https://api.frontforcecrm.com/salary.php',optionFor).then((res)=>res.json()).then((result)=>{
      if(result.message =='success'){
        Swal.fire({
          title:'Email is sent',
          html: `<p>Email is successfully sent to this email ${result.email}.</p>`,
          icon:'success'

        })
        return false;
      }
      if(result.message != 'success'){
        Swal.fire({
          title:'Email is not sent',
          text: `${result.message}`,
          icon:'error'

        })
        return false;
      }
    }).catch((er)=>{
      Swal.fire({
        title:'Server Error',
        text: `${er}`,
        icon:'error'
      });
      
    })
    
}

 
 if(editView == true){
    return null;
 }else{
      
     return (
       <div className="container mt-5 mb-5">
         <div className="row">
            <div className="row" style={{paddingBottom:20}}>
            <div className="col-md-4">
                <Label>Present</Label>
                <Progress value={parseFloat(Math.round(present_precentage))} color="success"/>
            </div>
            <div className="col-md-4">
                <Label>Absent</Label>
                <Progress value={parseFloat(Math.round(absent_precentage))} color="danger" />
            </div>
            <div className="col-md-4">
                <Label>Leave</Label>
                <Progress value={parseFloat(Math.round(leave_precentage))} color="warning"/>
            </div>
            </div>
           
         
            <div className="row print_content" >
            <table border="1" >
             <tr
               height="70px"
               style={{
                 backgroundColor: '#0a87db',
                 color: "white",
                 textAlign: "center",
                 fontSize: 24,
                 fontWeight: "600",
               }}
             >
               
               <td colSpan="5">{JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_title) } <br></br>
                 <p>{monthFor}-{yearFor}</p>
               </td>
             </tr>
               
             <tr style={{paddingLeft:10}}>
               
               <th>&nbsp;&nbsp;Emp Id:</th>
               <td>{emp_id}</td>
               <th>Hiring Date</th>
               <td>{formatJoingDate(joing_date)}</td>
               
             </tr>
   
            
             <tr>
              <th>&nbsp;&nbsp;Name</th>
               <td>{first_name + ' ' + last_name}</td>
               <th>Unpaid</th>
               <td>{absent}</td>
             </tr>
   
             <tr>
             <th>&nbsp;&nbsp;Designation</th>
               <td>{job_title}</td>
               
               <th>CL Leave</th>
               <td>{leave}</td>
             </tr>
             {/* <!------5 row----> */}
             <tr>
               <th>&nbsp;&nbsp;Location</th>
               <td>{job_location}</td>
               <th>Working Days</th>
               <td>{total_working_days}</td>
             </tr>
             {/* <!------6 row----{'>'} */}
             <tr>
               <th>&nbsp;&nbsp;Department</th>
               <td>{job_cat}</td>
               <th>Presents</th>
               <td>{present}</td>
             </tr>
           </table>
           <tr></tr>
           <br />
           <table border="1">
             <tr style={{backgroundColor:'#0a87db',color:'white',height:30}}>
               <th>&nbsp;&nbsp;Earnings</th>
               <th>Amount</th>
               <th>Deductions</th>
               <th>Amount</th>
             </tr>
             <tr>
               <td>&nbsp;&nbsp;Basic</td>
               <td>{formatAmount(parseFloat(basic_salary), salary_currency)}</td>
               <td>Tardy Fine</td>
               <td>{formatAmount(parseFloat(trady), salary_currency)}</td>
             </tr>
             <tr>
               <td>&nbsp;&nbsp;Sale Commission</td>
               <td>{formatAmount(parseFloat(month_commission), salary_currency)}</td>
               <td>Loan </td>
               <td>{formatAmount(parseFloat(loan_amountChange), loan_currencyChange)} </td>
             </tr>
             <tr>
             <td>&nbsp;&nbsp;Bonus</td>
               <td>{formatAmount(parseFloat(month_bonus), salary_currency)}</td>
               <td>Income tax</td>
               <td>{formatAmount(parseFloat(month_tax), salary_currency)}</td>
             </tr>
             <tr>
             <td>&nbsp;&nbsp;Incentive</td>
               <td>{formatAmount(parseFloat(month_incentive), salary_currency)}</td>
               
               <td>Upaid Fine</td>
               <td>{formatAmount(Math.round(parseFloat(unpaid)), salary_currency)}</td>
             </tr> 
             <tr>
             <td>&nbsp;&nbsp;Overtime</td>
               <td>{formatAmount(parseFloat(month_overtime), salary_currency)}</td>
               <td>Advance</td>
               <td>{formatAmount(parseFloat(adv_salaryChange), adv_currencyChange)}</td>
             </tr>
            {
              schedule_off_status == 'No' ? 

              <tr>
                <td></td>
                <td></td>
                <td>Schedule OFF</td>
                <td>{formatAmount(Math.round(scheduleSalary), salary_currency)}</td>
              </tr>: null
             
            }
             {
              allowance_per_month.lenght !=  0 ? (
                allowance_per_month.map((e,index)=>(
              <tr key={index}>
               <td>&nbsp;&nbsp;{e.type}</td>
               <td>{formatAmount(parseFloat(e.amount), e.currency)}</td>
              </tr>

              ))
              ) : null
             }
             {
              allowance_per_month_deduct_data.lenght !=  0 ? (
                allowance_per_month_deduct_data.map((e,index)=>(
              <tr key={index}>
               <td></td>
               <td></td>
               <td>{e.type}</td>
               <td>{formatAmount(parseFloat(e.amount), e.currency)}</td>
              </tr>

              ))
              ) : null
             }
             {
              allowance_once.lenght !=  0 ? (
              allowance_once.map((e,index)=>(
              <tr key={index}>
               <td>&nbsp;&nbsp;{e.type}</td>
               <td>{formatAmount(parseFloat(e.amount), e.currency)}</td>
              </tr>

              ))
              ) : null
             }
             {
              allowance_once_deduct_data.lenght !=  0 ? (
              allowance_once_deduct_data.map((e,index)=>(
              <tr key={index}>
               <td></td>
               <td></td>
               <td>&nbsp;&nbsp;{e.type}</td>
               <td>{formatAmount(parseFloat(e.amount), e.currency)}</td>
              </tr>

              ))
              ) : null
             }
             {/* <tr>
               <td>&nbsp;&nbsp;bonus</td>
               <td>{formatAmount(500, salary_currency)}</td>
             </tr>*/}
            
             <tr>
               <th>&nbsp;&nbsp;Gross Earnings</th>
               <td>{formatAmount(parseFloat(amount_per_once) +parseFloat(amount_per_month) + parseFloat(Math.round(basic_salary)) +parseFloat(month_bonus)+ parseFloat(month_commission) + parseFloat(month_incentive) + parseFloat(month_overtime), salary_currency)}</td>
               <th>Gross Deductions</th>
               <td>{formatAmount(Math.round( parseFloat(amount_per_month_deduct) + parseFloat(amount_per_once_deduct) +parseFloat(loan_amountChange) +scheduleSalary + parseFloat(adv_salaryChange) + parseFloat(trady) + parseFloat(month_tax) + parseFloat(unpaid)), loan_currencyChange)}</td>
             </tr>
            
           </table>
          

            <div className="row text-end mt-3">
            
              <div className=" col-md-6 " >
             {
              month_tax_precentage == "0" || month_tax_precentage == 0?
                null :  
              <p style={{margin:0}}>
              
              <Row style={{display:'flex',justifyContent:'space-between',flexWrap:'nowrap'}}>
                 <strong>Tax {  month_tax_precentage + '%' }</strong>
               <span>{formatAmount(Math.round(month_tax), salary_currency)}</span>
              </Row>
              </p>
             }
              <p>
              <Row style={{display:'flex',justifyContent:'space-between',flexWrap:'nowrap'}}>
                 <strong>NET PAY</strong>
               <span>{ formatAmount(Math.round(parseFloat(Math.round(basic_salary))-scheduleSalary - parseFloat(loan_amountChange) - parseFloat(adv_salaryChange) - parseFloat(trady) - parseFloat(month_tax) +parseFloat(month_bonus) + parseFloat(month_commission) + parseFloat(month_incentive) + parseFloat(month_overtime) - parseFloat(unpaid) + parseFloat(amount_per_once)+parseFloat(amount_per_month) ) - parseFloat(amount_per_month_deduct) - parseFloat(amount_per_once_deduct) - parseFloat(deduct_salary), salary_currency)}</span>
              </Row>
              
              </p>
               
            
             
              </div>
              
            </div>
           
           <div style={{paddingTop:10,textAlign:"center"}}>
            <center>

            <div className="amount_word_div">
                <p >Amount in words :  <span style={{textTransform:'uppercase'}}>{numberToWord > 0 ? numberToWords(numberToWord) : null}</span></p>
            </div>
            </center>
           </div>
           </div>
           <div style={{textAlign:"end",paddingTop:30}}>
            <button  className="btn btn-success" onClick={()=>printContent()}>Print Slip</button>
            <button className="btn btn-info" style={{marginLeft:10}} onClick={()=>sendMailToUser()}>Send Email</button>
           </div>
         </div>
       </div>
     )
     function printContent() {
      const content = document.querySelector('.print_content').innerHTML;
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write(`
          <html>
          <head>
              <title>Print</title>
              <style>
              @page {
                
                margin: 0;
            }
            th{
              font-family: 'Nunito'
            }
              </style>
          </head>
          <body>
              ${content}
              <script>
                  // Automatically trigger print dialog
                  window.onload = function() {
                      window.print();
                      window.close();
                  };
              </script>
          </body>
          </html>
      `);
      printWindow.document.close();
  }
   
    
 }
}

SalarySlip.propTypes = {
  employee: PropTypes.arrayOf(
    PropTypes.shape({
      basic_salary: PropTypes.number.isRequired,
      salary_currency: PropTypes.string.isRequired,
    })
  ).isRequired,
}
export default SalarySlip
