import React from "react"
import { Link } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"

// import images
import img1 from "../../assets/images/small/img-2.jpg"
import img2 from "../../assets/images/small/img-6.jpg"
import img3 from "../../assets/images/small/img-1.jpg"

const LatestProjects = ({parsedData}) => {
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-UK', options).format(date);
  };
  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <CardBody>
            <div className="d-flex">
              <div className="me-2">
                <h5 className="card-title mb-4">Latest Projects</h5>
              </div>
              {/* <UncontrolledDropdown className="ms-auto">
                <DropdownToggle
                  className="text-muted font-size-14"
                  tag="a"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <Link className="dropdown-item" to="#">
                    Action
                  </Link>
                  <Link className="dropdown-item" to="#">
                    Another action
                  </Link>
                  <Link className="dropdown-item" to="#">
                    Something else
                  </Link>
                  <div className="dropdown-divider"></div>
                  <Link className="dropdown-item" to="#">
                    Separated link
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </div>

            <div className="table-responsive">
              <table className="table table-sm table-nowrap align-middle mb-0">
                <thead>
                  <tr>
                    <th scope="col" >
                      Project Name
                    </th>
                    <th scope="col">Client Name</th>
                    <th scope="col">Client Phone</th>
                    <th scope="col">Status</th>
                    <th scope="col">Created At</th>
                    
                  </tr>
                </thead>

                <tbody>
                  {parsedData.map((project, key) => (
                    <tr key={key}>
                 
                      <td>
                        <h5 className="font-size-13 text-truncate mb-1">
                          <Link to={`/project-overview/${project.id}`}
                             state={{project: project }} className="text-primary">
                            {project.projectName}
                          </Link>
                        </h5>
                        {/* <p className="text-muted mb-0">{popularpost.date}</p> */}
                      </td>
                      <td>
                        {/* <i className="bx bx-like align-middle me-1"></i>{" "} */}
                        {project.client_name}
                      </td>
                      <td>
                        {/* <i className="bx bx-comment-dots align-middle me-1"></i>{" "} */}
                        {project.client_email}
                      </td>
                      <td>
                        {/* <i className="bx bx-comment-dots align-middle me-1"></i>{" "} */}
                        {
                         project.status === 'new' ? 
                         <Badge color="info">{project.status}</Badge> 
                          : project.status === 'active' ? 
                              <Badge color="primary">{project.status}</Badge>
                          : project.status === 'completed' ? 
                              <Badge color="success">{project.status}</Badge>
                          : null
                        }
                      </td>
                      <td>
                        {/* <i className="bx bx-comment-dots align-middle me-1"></i>{" "} */}
                        {formatDate(project.created_at)}
                      </td>
                     
                     
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default LatestProjects
