import React, { useEffect, useState } from "react"
import {
  Badge,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Label,
  OffcanvasBody,
  OffcanvasHeader,
  Offcanvas,
  Input,
} from "reactstrap"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { after, map } from "lodash"
import Select from "react-select"
//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card invoice
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import CardInvoice from "./card-invoice"
import { getInvoices as onGetInvoices } from "store/actions"
import {
  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridColDef,
} from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"
import { Avatar, Box, Paper } from "@mui/material"
import classnames from "classnames"
import axios from "axios"
import { DeleteSharp } from "@mui/icons-material"
import Swal from "sweetalert2"
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}))

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Invoice Data.</Box>
    </StyledGridOverlay>
  )
}
const InvoicesList = props => {
  //meta title
  document.title = `Invoices | ${process.env.REACT_APP_NAME}`

  const [invoiceData, setInvoiceData] = useState([])
  const [tableLoader, setTableLoader] = useState(true)
  const [total, setTotal] = useState(0)
  const [paid, setPaid] = useState(0)
  const [overdue, setOverdue] = useState(0)
  const [pending, setPending] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)
  const [paidAmount, setPaidAmount] = useState(0)
  const [overdueAmount, setOverdueAmount] = useState(0)
  const [pendingAmount, setPendingAmount] = useState(0)
  const [paidData, setPaidData] = useState([])
  const [pendingData, setPendingData] = useState([])
  const [overdueData, setOverdueData] = useState([])
  const [currency, setCurrency] = useState("")

  const [dynamicLayouts, setdynamicLayouts] = useState([])
  const [itemName, setItemName] = useState([])
  const [itemQuantities, setItemQuantities] = useState([])
  const [itemPrices, setItemPrices] = useState([])
  const [itemDescriptions, setItemDescriptions] = useState([])
  const [isRight, setIsRight] = useState(false)
  const [BillingNameTo , setBillingNameTo] = useState('');
  const [clientList , setClientList] = useState('');
  const [subTotalInvoice , setsubTotalInvoice] = useState(0);
  const [invoiceTax , setinvoiceTax] = useState(0);
  const [invoiceTotal , setinvoiceTotal] = useState(0);
  const [Discount , setDiscount] = useState(0);
  const [invoiceTotalAfterTax , setinvoiceTotalAfterTax] = useState(0);
  const [createdDate , setCreatedDate] = useState('');
  const [dueDate , setDueDate] = useState('');
  const [currencyInvoice , setCurrencyInvoice] = useState('$');
  
  useEffect(() => {
    fetchInvoice()
    clientFetchDB()
  }, [])
  const clientFetchDB = () => {
    let jsonMaping = JSON.parse(localStorage.getItem("authUser")).map(
      e => e.org_id
    )
    let form = new FormData()

    form.append("method", "fetch-clients")

    form.append("org_id", jsonMaping)
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL+"/clients.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setClientList(result)
      })
      .catch(er => {
        console.log(er)
      })
  }

  const addDynamicLayout = () => {
    setItemName(prevState => [...prevState, ""])
    setItemQuantities(prevState => [...prevState, ""])
    setItemPrices(prevState => [...prevState, ""])
    setItemDescriptions(prevState => [...prevState, ""])
  }

  const removeDynamicLayout = index => {
    setItemName(prevState => prevState.filter((_, i) => i !== index))
    setItemQuantities(prevState => prevState.filter((_, i) => i !== index))
    setItemPrices(prevState => prevState.filter((_, i) => i !== index))
    setItemDescriptions(prevState => prevState.filter((_, i) => i !== index))
  }

  const updateItemValue = (type, index, value) => {
    switch (type) {
      case "itemName":
        setItemName(prevState =>
          prevState.map((item, i) => (i === index ? value : item))
        )
        break
      case "itemQuantities":
        setItemQuantities(prevState =>
          prevState.map((item, i) => (i === index ? value : item))
        )
        break
      case "itemPrices":
        setItemPrices(prevState =>
          prevState.map((item, i) => (i === index ? value : item))
        )
        break
      case "itemDescriptions":
        setItemDescriptions(prevState =>
          prevState.map((item, i) => (i === index ? value : item))
        )
        break
      default:
        break
    }
    
    
  }
  const HitDoneButton = ()=>{
    const result = itemQuantities.reduce((total, current, index) => {
      return total + current * itemPrices[index];
    }, 0);
    setsubTotalInvoice(result);
    setinvoiceTotal(result)
  }
  const fetchInvoice = async () => {
    let localValue = localStorage.getItem("authUser")
    let MapingValues = JSON.parse(localValue)
    let org_id = MapingValues.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-invoices")
    form.append("org_id", org_id)
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL+"/mobile-invoice.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setInvoiceData(result)
        let paidCount = 0
        let overdueCount = 0
        let pendingCount = 0
        let paidAmount = 0
        let overdueAmount = 0
        let pendingAmount = 0
        let paidData = []
        let overdueData = []
        let pendingData = []
        let cu = ""
        result.forEach(item => {
          switch (item.invoice_status) {
            case "paid":
              paidAmount = parseFloat(item.total) + paidAmount
              paidCount++
              paidData.push(item)
              cu = item.currency
              break
            case "overdue":
              overdueAmount = parseFloat(item.total) + overdueAmount
              overdueCount++
              overdueData.push(item)
              break
            case "pending":
              pendingAmount = parseFloat(item.total) + pendingAmount
              pendingCount++
              pendingData.push(item)
              break
            default:
              // Handle unexpected status
              break
          }
        })
        setCurrency(cu)
        setTotal(paidCount + pendingCount + overdueCount)
        setTotalAmount(paidAmount + pendingAmount + overdueAmount)
        setPending(pendingCount)
        setPendingAmount(pendingAmount)
        setOverdue(overdueCount)
        setOverdueAmount(overdueAmount)
        setPaid(paidCount)
        setPaidData(paidData)
        setOverdueData(overdueData)
        setPendingData(pendingData)
        setPaidAmount(paidAmount)
        if (result.length == 0) {
          setTableLoader(false)
        }
      })
      .catch(e => console.log(e))
  }
  function stringToColor(string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.charAt(0)}`,
    }
  }
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  function formatDateTime(inputDate) {
    const parts = inputDate.split('-');
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    return `${month}/${day}/${year}`;
  }

  const columnsFor: GridColDef[] = [
    {
      field: "profile_image",
      headerName: "Image",
      width: 150,
      renderCell: param => <Avatar {...stringAvatar(param.row.billing_name)} />,
    },
    {
      field: "billing_name",
      headerName: "Client Name",
      width: 150,
      renderCell: param => <p>{param.value}</p>,
    },
    { field: "billing_email", headerName: "Client Email", width: 150 },
    { field: "invoice_status", headerName: "Status", width: 150 },
    {
      field: "total",
      headerName: "Total",
      width: 150,
      renderCell: props => (
        <p>{props.row.currency + parseFloat(props.value).toFixed(2)}</p>
      ),
    },
    { field: "created_date", headerName: "Invoice Date", width: 150 },
    {
      field: "",
      width: 250,
      headerName: "Actions",
      renderCell: param => {
        return (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link
                to={`/invoices-detail/${param.row.id}`}
                state={{
                  obj: param.row,
                }}
                className="btn btn-sm btn-soft-primary"
              >
                <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
              </Link>
            </li>

            <li>
              <Link
                to={param.row.invoice_url}
                target="_blank"
                className="btn btn-sm btn-soft-success"
              >
                <i className="mdi mdi-file-outline" id="viewTooltip" />
              </Link>
            </li>
            <li>
              <button
                onClick={()=>DeleteInvoiceFromDB(param.row.id)}
                
                className="btn btn-sm btn-soft-danger"
              >
                <i className="bx bx-trash" id="deletetooltip" />
              </button>
            </li>
          </ul>
        )
      },
    },
  ]
  const [activeTab, setActiveTab] = useState("1")
  const [existingClient, setClientData] = useState({
    billingName :'',
    billingEmail:'',
    billingAddress:''
  })
  const [clientType, setClientType] = useState("")
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  const handleInput = (e) => {
    setInputValue(e.target.value);
  };

  const  saveInvoiceinToDb = async () => {

  if(existingClient.billingName == '' || existingClient.billingEmail == '' || existingClient.billingAddress == ''){
    toastr.error('Client information is requried');
    return false;
  }
  else if(itemName.length == 0 || itemDescriptions.length == 0 || itemPrices.length == 0 ){
    toastr.error('Item  information is required');
    return false;
  }else if(createdDate == '' || dueDate == ''){
    if(createdDate == ''){
      toastr.error('Created date is required');
      return false;
      
    }else{
      toastr.error('Due date is required');
      return false;
    }

  }else if(subTotalInvoice == 0){
     toastr.error('Please click the done after adding the item information');
     return false;
  }
  else{
    const { value: inputValue } = await Swal.fire({
      title: 'Add Invoice',
      input: 'textarea',
      inputLabel: 'Invoice Notes',
      
      inputPlaceholder: 'Notes......',
      showCancelButton: true,
      inputValidator: (value) => {
          let localValue = localStorage.getItem('authUser');
          let valueMap = JSON.parse(localValue);
          let org_id = valueMap.map(e => e.org_id); 
          let org_Name = valueMap.map(e => e.org_title);
          let emp_id = valueMap.map(e => e.user_id);
          let mergedArray = [];
          for (let i = 0; i < Math.max(itemName.length, itemQuantities.length, itemPrices.length, itemDescriptions.length); i++) {
              let itemSubtotal =0;
              itemSubtotal  = itemQuantities[i] * itemPrices[i];
      
              mergedArray.push([
                 itemName[i] !== undefined ? itemName[i] : null,
                itemQuantities[i] !== undefined ? itemQuantities[i] : null,
                itemPrices[i] !== undefined ? itemPrices[i] : null,
                  itemSubtotal =  itemSubtotal
              ]);
          }
              let stringValues = '';
              mergedArray.forEach((e) => {
                  stringValues += `<tr>`;
                  e.forEach((value) => {
                      stringValues += `<td>${value !== null ? value : ''}</td>`;
                  });
                  stringValues += `</tr>`;
              });
        
          let form = new FormData();
          
          form.append('method', 'create-invoice-from-desktop');
          form.append('org_id', org_id);
          form.append('org_name', org_Name);
          form.append('emp_id', emp_id);
          form.append('created_at', formatDateTime(createdDate));
          form.append('due_date', formatDateTime(dueDate));
          form.append('billing_name', existingClient.billingName);
          form.append('billing_email', existingClient.billingEmail);
          form.append('billing_address', existingClient.billingAddress);
          form.append('itemName',itemName.toString());
          form.append('itemQuantity',itemQuantities.toString());
          form.append('itemPrice', itemPrices.toString());
          form.append('itemDescription', itemDescriptions.toString());
          form.append('subtotal_amount', subTotalInvoice);
          form.append('total_amount', invoiceTotal);
          form.append('discount_value', Discount);
          form.append('tax_value',invoiceTax);
          form.append('notes', value);
          form.append('currency',currencyInvoice);
          
          // var requestOptions = {
          //   method: 'POST',
          //   body: form,
          //   headers: {
          //     'Content-Type': 'text/html', // Specify the content type as text/html
          //   },
          
          // };
          axios.post(process.env.REACT_APP_LEAVE_API_URL+'/mobile-invoice.php', form)
            
            .then(result => {
              if(result.data.message == 'success'){
                setIsRight(false)
                fetchInvoice();
                setClientData({
                  billingAddress:'',
                  billingEmail:'',
                  billingName:'',
                })
                setItemName([])
                setItemDescriptions([])
                setItemQuantities([])
                setItemPrices([])
                setClientType('')
                setCreatedDate('')
                setDueDate('')
                window.open(result.data.pdf_url, 'blank')

              }
            })
            .catch(e => {
              
           alert(`Error ${e}`);
            });
        
      }

    });
  }
    
    

    
   
  };


  const DeleteInvoiceFromDB = (id)=>{
       Swal.fire({
        title:'Confirm',
        backdrop:'static',
        icon:'warning',
        text:'Are you sure to delete this invoice. This process cannot undo',
        showCancelButton:true,
        confirmButtonText:'Yes, Please',
        cancelButtonText:"No, I don't",
        confirmButtonColor:'green',
        cancelButtonColor:'red'
       }).then((is)=>{
        if(is.isConfirmed){
          let localValues = localStorage.getItem('authUser');
          let MapingVaue = JSON.parse(localValues);
                      
          let org_id =  MapingVaue.map((e)=>e.org_id)
              
              let form = new FormData();
              form.append('method','delete-invoice');

              form.append('org_id',org_id)
              form.append('delete_for',id)
              var requestOptions = {
                  method: 'POST',
                  body: form
                };
              
                fetch(process.env.REACT_APP_LEAVE_API_URL+'/mobile-invoice.php', requestOptions)
                  .then(res => res.text())
                  .then(result => {
                      if(result === 'success'){
                          
                          toastr.success('You successfully delete the invoice');
                          fetchInvoice();
                      }else{
                         
                          toastr.error('Error while deletion ');

                      }
                  }).catch((er)=>{
                      this.setState({deletionDisabled: false,deletionLoading:false})
                      console.log(er)})
        }else{
          Swal.close();
        }
       })
  
      
   
}

  const changeClickSelect = (val) =>{
    let form = new FormData();
    form.append('method', 'get-single-client-by-id');
    form.append('client_id', val)

    axios.post
    (process.env.REACT_APP_LEAVE_API_URL+'/mobile-invoice.php',form).then((res)=>{
        setClientData({
          billingName:res.data.client_name,
          billingEmail:res.data.client_phone,
          billingAddress:res.data.client_address
        })
    }).catch((err)=>console.log(err));
  }
  const onchangeDiscount = value => {
    console.log(value)
    setDiscount(parseFloat(value))
    const afterDiscounts = calculateTotalAmount(
      subTotalInvoice,
      parseFloat(value),
      invoiceTax,
    );
    console.log(afterDiscounts)
    if (invoiceTax != 0) {
      setinvoiceTotalAfterTax(Math.round(afterDiscounts)) 
    }
    setinvoiceTotal(Math.round(afterDiscounts))
    
  };
  const onchangeInvoiceTax = value => {
    setinvoiceTax(parseFloat(value))
    const afterDiscounts = calculateTotalAmount(
     subTotalInvoice,
      Discount,
      parseFloat(value),
    );
    setinvoiceTotalAfterTax(Math.round(afterDiscounts))
    
  };
  const calculateDiscountedAmount = (totalAmount, discountPercentage) =>{
    const discountedAmount =
      totalAmount - (discountPercentage / 100) * totalAmount;
    return discountedAmount;
  }
  
 const calculateTotalAmount = (originalAmount, discountPercentage, taxRate) =>{
    const discountedAmount =
      originalAmount - (discountPercentage / 100) * originalAmount;
    const taxAmount = (taxRate / 100) * discountedAmount;
    const totalAmount = discountedAmount + taxAmount;
    return totalAmount;
  }


  const currencyArray = [
    {label: 'US Dollar', value: '$'},
    {label: 'Euro', value: '€'},
    {label: 'British Pound', value: '£'},
    {label: 'Swiss Franc', value: 'CHF'},
    {label: 'Canadian Dollar', value: 'CA$'},
    {label: 'Australian Dollar', value: 'A$'},
    {label: 'Chinese Yuan', value: '¥'},
    {label: 'Indian Rupee', value: '₹'},
    {label: 'Brazilian Real', value: 'R$'},
    {label: 'Pakistan Rupees', value: 'PKR'},
  ];
  function getCurrentDate() {
    // Get current date
    const currentDate = new Date()

    // Extract year, month, and day
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are zero-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, "0")

    // Format the date as 'YYYY-MM-DD'
    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Invoice" />
          
          <Row>
          <Col md={12} className="mt-3 mb-3">
            <button  className="btn btn-primary" onClick={()=>setIsRight(!isRight)}>Add Invoice</button>
          </Col>
            <Col md={12}>
              <Row>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Total</p>
                          <h5 className="mb-2">{total}</h5>
                          <p className="mb-0 text-muted mb-2">
                            {currency}
                            {totalAmount.toFixed(2)}
                          </p>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div
                            style={{ background: "transparent" }}
                            className="avatar-title bg-soft bg-secondary rounded-circle text-primary font-size-20"
                          >
                            <i
                              className="mdi mdi-file"
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Paid</p>
                          <h5 className="mb-2">{paid}</h5>
                          <p className="text-muted mb-2">
                            {currency}
                            {paidAmount.toFixed(2)}
                          </p>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div
                            style={{ background: "transparent" }}
                            className="avatar-title  text-primary font-size-20"
                          >
                            {/* <i className="bx bxs-note"></i> */}
                            <div
                              style={{ background: "transparent" }}
                              className="avatar-title bg-success rounded-circle text-primary font-size-20"
                            >
                              <i
                                className="mdi mdi-file"
                                style={{ color: "white" }}
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Pending</p>
                          <h5 className="mb-2">{pending}</h5>
                          <p className="text-muted mb-2">
                            {currency}
                            {pendingAmount.toFixed(2)}
                          </p>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div
                            style={{ background: "transparent" }}
                            className="avatar-title bg-warning rounded-circle text-primary font-size-20"
                          >
                            <i
                              className="mdi mdi-file"
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={3}>
                  <Card>
                    <CardBody>
                      <div className="d-flex flex-wrap">
                        <div className="me-3">
                          <p className="text-muted mb-2">Overdue</p>
                          <h5 className="mb-2">{overdue} </h5>
                          <p className="text-muted mb-2">
                            {currency}
                            {overdueAmount.toFixed(2)}{" "}
                          </p>
                        </div>

                        <div className="avatar-sm ms-auto">
                          <div
                            style={{ background: "transparent" }}
                            className="avatar-title bg-danger rounded-circle text-primary font-size-20"
                          >
                            <i
                              className="mdi mdi-file"
                              style={{ color: "white" }}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xl="12" className="mt-4">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Total <Badge color="secondary">{total}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Paid <Badge color="success">{paid}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleTab("3")
                    }}
                  >
                    Pending <Badge color="warning">{pending}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleTab("4")
                    }}
                  >
                    Overdue <Badge color="danger">{overdue}</Badge>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={invoiceData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={paidData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={pendingData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={overdueData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="bottom"
            style={{ height: 700 }}
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader toggle={toggleRightCanvas}>
              Create Invoice
            </OffcanvasHeader>
            <OffcanvasBody>
              <Row>
                 <Col md={6}>
                  <Row>
                <Col md={4} className="mb-3">
                  <Label>Created Data</Label>
                  <Input
                  type="date"
                  name="created_data"
                  className="form-control"
                  min={getCurrentDate()}
                  value={createdDate}
                  onChange={(e)=>setCreatedDate(e.target.value)}
                  
                  />
                </Col>
                <Col md={4}>
                  <Label>Due Date</Label>
                  <Input
                  type="date"
                  name="due_date"
                  min={getCurrentDate()}
                  value={dueDate}
                  onChange={(e)=>setDueDate(e.target.value)}
                  className="form-control"
                 />
                </Col>
                <Col md={12}>
                  <p>Select client</p>
                  <Row>
                    <Col md={12}>
                      <Input
                        type="radio"
                        value={"existing_client"}
                        name="select_client"
                        onChange={e => setClientType(e.target.value)}
                      />{" "}
                      Exiting Client
                      <Input
                        style={{ marginLeft: 10 }}
                        type="radio"
                        value={"new_client"}
                        onChange={e => setClientType(e.target.value)}
                        name="select_client"
                      />{" "}
                      New Client
                    </Col>
                    {clientType == "new_client" ? (
                      <Col md={12} className="mt-4">
                        <Row>
                          <Col md={12}>
                            <p>Client Information</p>
                          </Col>
                          <Col md={6}>
                            <Label>Name</Label>
                            <Input value={existingClient.billingName} onChange={(e)=>setClientData(prev=>({
                              ...prev,
                              billingName:e.target.value
                            }))} type="text" name="client_name" />
                          </Col>
                          <Col md={6}>
                            <Label>Email</Label>
                            <Input value={existingClient.billingEmail} onChange={(e)=>setClientData(prev=>({
                              ...prev,
                              billingEmail:e.target.value
                            }))}  type="email" name="client_email" />
                          </Col>
                          <Col md={12} className="mt-3">
                            <Label>Address</Label>
                            <Input value={existingClient.billingAddress} onChange={(e)=>setClientData(prev=>({
                              ...prev,
                              billingAddress:e.target.value
                            }))}  type="text" name="client_address" />
                          </Col>
                        </Row>
                      </Col>
                    ) : null}
                    {clientType == "existing_client" ? (
                      <Row>
                      <Col md={12} className="mt-4">
                       <Row>
                        <Col md={12}>
                        <Select
                           onChange={(e)=>changeClickSelect(e.value)}
                          className="select2-selection"
                          name="exiting_client"
                          options={clientList}
                        />
                        </Col>
                       </Row>
                      </Col>
                       <Col md={12} className="mt-4">
                       <Row>
                         <Col md={12}>
                           <p>Existing Information</p>
                         </Col>
                         <Col md={6}>
                           <Label>Name</Label>
                           <Input value={existingClient.billingName} onChange={(e)=>setClientData(prev=>({
                             ...prev,
                             billingName:e.target.value
                           }))} type="text" name="client_name" />
                         </Col>
                         <Col md={6}>
                           <Label>Email</Label>
                           <Input value={existingClient.billingEmail} onChange={(e)=>setClientData(prev=>({
                             ...prev,
                             billingEmail:e.target.value
                           }))}  type="email" name="client_email" />
                         </Col>
                         <Col md={12} className="mt-3">
                           <Label>Address</Label>
                           <Input value={existingClient.billingAddress} onChange={(e)=>setClientData(prev=>({
                             ...prev,
                             billingAddress:e.target.value
                           }))}  type="text" name="client_address" />
                         </Col>
                       </Row>
                     </Col>
                     </Row>
                    ) : null}
                    <Col md={12} className="mt-4">
                      <Row>
                        {
                          clientType == '' ? null : 

                        <Col md={12}>
                          <Row>
                            <Col md={6} >
                          <p>Item Details</p>

                            </Col>
                            <Col md={6} >
                              <div className="row text-end">
                                <div className="col-md-6 text-end">
                                  <p className="btn btn-success" onClick={() => HitDoneButton()} style={{fontWeight:'600',cursor:'pointer'}}>Done</p>
                                </div>
                                <div className="col-md-6 text-start">
                                    <p className="btn btn-info"  onClick={() => addDynamicLayout()} style={{fontWeight:'600',cursor:'pointer'}}>Add Item</p>
                                </div>

                              </div>

                            </Col>
                          
                          </Row>
                        
                        </Col>
                        }
                        <Col md={12}>
                          {itemName.map((itemName, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-12 mt-2 mb-2">
                                <span>Item {index + 1}</span>
                              </div>
                              <div className="col-md-3">
                                <Input
                                  onChange={value =>
                                    updateItemValue(
                                      "itemName",
                                      index,
                                      value.target.value
                                    )
                                  }
                                  value={itemName}
                                  placeholder="Item Name"
                                />
                              </div>

                              <div className="col-md-2">
                                <Input
                                  onChange={value =>
                                    updateItemValue(
                                      "itemQuantities",
                                      index,
                                      value.target.value
                                    )
                                  }
                                  value={itemQuantities[index]}
                                  placeholder="Quantity"
                                />
                              </div>
                              <div className="col-md-2">
                                <Input
                                  onChange={value =>
                                    updateItemValue(
                                      "itemPrices",
                                      index,
                                      value.target.value
                                    )
                                  }
                                  value={itemPrices[index]}
                                  placeholder="Price"
                                />
                              </div>

                              <div className="col-md-3">
                                <Input
                                  type="input"
                                  onChange={value =>
                                    updateItemValue(
                                      "itemDescriptions",
                                      index,
                                      value.target.value
                                    )
                                  }
                                  value={itemDescriptions[index]}
                                  placeholder="Description...."
                                />
                              </div>
                              <div className="col-md-2">
                              <button
                                  className="btn btn-danger"
                                  onClick={() => removeDynamicLayout(index)}
                                >
                                  <DeleteSharp/>
                                </button>
                              </div>
                            
                            </div>
                          ))}
                        </Col>
                       
                      </Row>
                    </Col>
                  </Row>
                </Col>

                  </Row>
                 </Col>
                 <Col md={6}>
                                  <Row>
                                    <Col md={6}>
                                    <p>Currency</p>
                                    </Col>
                                    <Col md={6}>
                                    <Select
                                     className="select2-selection"
                                     name="exiting_client"
                                     options={currencyArray}
                                     onChange={(e)=>setCurrencyInvoice(e.value)}
                                    />
                                    </Col>
                                  </Row>
                                  <Row className="mt-2 mb-2">
                                    <Col md={6} >
                                    <p>Subtotal</p>
                                    </Col>
                                    <Col md={6}>
                                     {currencyInvoice} {subTotalInvoice.toFixed(2)}
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col md={6}>
                                      Discount in %
                                    </Col>
                                    <Col md={6}>
                                    <input className="form-control" type="text" onChange={e => onchangeDiscount(e.target.value)}/>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col md={6}>
                                    <p>Tax in %</p>
                                    </Col>
                                    <Col md={6}>
                                    <input className="form-control" type="text" onChange={e => onchangeInvoiceTax(e.target.value)}/>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col md={6}>
                                    <p>Grand Total</p>
                                    </Col>
                                    <Col md={6}>
                                     {currencyInvoice}{invoiceTotalAfterTax.toFixed(2)}
                                    </Col>
                                  </Row>
                                  <Row >
                                    <Col md={12} className="text-center mt-4">
                                       <button  className="col-md-8 btn btn-primary" onClick={()=>saveInvoiceinToDb()}>Add Invoice</button>
                                    </Col>
                                    <Col md={12} className="text-center mt-3" >
                                       <button  className="col-md-8 btn btn-danger" onClick={()=>setIsRight(!isRight)}>Cancel </button>
                                    </Col>
                                  </Row>
                          
                          
                          
                          
                        
                 </Col>
              </Row>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  )
}

InvoicesList.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(InvoicesList)
