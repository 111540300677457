import { Box, Breadcrumbs } from "@mui/material"
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, Container, Form, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader, Row, Spinner, UncontrolledTooltip } from "reactstrap"
import { styled } from "@mui/material/styles"
import Swal from "sweetalert2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}))

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Annoncement</Box>
    </StyledGridOverlay>
  )
}
const HomeAnnouncements = () => {
  document.title = `Announcements | ${process.env.REACT_APP_NAME}`
  const [dataFor, setdataFor] = useState([])
  const [tableLoader, settableLoader] = useState(true)
  const [loader, setLoader] = useState(false)
  const [isRight , setIsRight]  = useState(false)
 const [editMode,setEditMode]= useState(false)
  const [singleAnn,setSingleAnn] = useState({
        description: "Vvv",
        id: "",
        shown_date: "",
        title: "Bbv"
        })
  useEffect(() => {
    fetchAnnouncements()
  }, [1])
  const fetchAnnouncements = async () => {
    let valueFrom = localStorage.getItem("authUser")
    let mapingVal = JSON.parse(valueFrom)
    let org = mapingVal.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-announcements")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
      redirect: "follow",
    }
    console.warn(form)
    fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/announcements.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setdataFor(result)
        if (result.length == 0) {
          settableLoader(false)
        }
      })
      .catch(error => console.log("error", error))
  }
  const columnsFor: GridColDef[] = [
    {
      field: "title",
      headerName: "Title",
      width: 200,
      renderCell: param => <p>{param.value}</p>,
    },
    { field: "shown_date", headerName: "Posted Date", width: 200 ,renderCell:(param)=><p>{formatDateOtherOne(param.value)}</p> },
    { field: "description", headerName: "Description", width: 400 },
    {
      field: "id",
      headerName: "Actions",
      width: 250,
      renderCell: param => {
        return (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <button
                onClick={()=>editFunctionCall(param.row)}
                className="btn btn-sm btn-soft-primary"
              >
                <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
              </button>
            </li>
            <UncontrolledTooltip placement="top" target="viewtooltip">
              View
            </UncontrolledTooltip>
            <li>
              <Link
                to="#"
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  //   tog_center();
                  onSubmitDelete(param.value)
                }}
              >
                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Terminate
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        )
      },
    },
  ]
  const toggleRightCanvas = ()=>{
    setIsRight(!isRight);
  }
  function formatDateShownDate(inputDate) {
    // Create a new Date object from the input date string
    const date = new Date(inputDate);
    
    // Extract the day, month, and year components from the date
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    
    // Construct the formatted date string in 'MM/DD/YYYY' format
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
  }
  const editFunctionCall = (item)=>{
    setEditMode(true)
    setIsRight(true)
    setSingleAnn(item)
  }
  function formatDateBack(inputDate) {
    // Split the input date string into day, month, and year components
    const parts = inputDate.split('/');
    const day = parts[1];
    const month = parts[0];
    const year = parts[2];
    
    // Construct the formatted date string in 'YYYY-MM-DD' format
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    
    return formattedDate;
}
function formatDateOtherOne(inputDate) {
    // Split the input date string into day, month, and year components
    const parts = inputDate.split('/');
    const day = parts[1];
    const month = parts[0];
    const year = parts[2];
    
    // Define an array of month names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    
    // Get the month name based on the month component
    const monthName = monthNames[parseInt(month, 10) - 1]; // Months are zero-based
    
    // Construct the formatted date string in 'DD Mon YYYY' format
    const formattedDate = `${day} ${monthName} ${year}`;
    
    return formattedDate;
}
const onSubmitDelete = (Id) =>{
    Swal.fire({
        title:'Confirmation',
        backdrop:'static',
        text:'Are you sure that you want to delete this announcement',
        showCancelButton:true,
        cancelButtonColor:'red',
        cancelButtonText:"No I don't",
        confirmButtonColor:'green',
        confirmButtonText:'Yes, I want',

    }).then((res)=>{
        if(res.isConfirmed){
            
                let form = new FormData();
                form.append('method' , 'delete-announcement')
                form.append('delete_id' , Id);
                var requestOptions = {
                 method: 'POST',
                 body: form,
                 redirect: 'follow',
               };
               fetch(process.env.REACT_APP_LEAVE_API_URL +"/announcements.php", requestOptions)
                 .then(response => response.text())
                 .then(result => {
                     if(result == 'success'){
                         fetchAnnouncements();
                         toastr.success('You successfully delete announcement');
                     }else{
                         toastr.error('Error while delete the announcement');
                     }
                 })
                 .catch(error => console.log('error', error));
 
             
        }else{
            Swal.close();
        }
    })
    
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Announcements"  />
          <Row>
            <Col md={12} className="mb-3 mt-3">
                <button className="btn btn-primary" onClick={()=>{
                    setEditMode(false);
                    setIsRight(true)
                }}>Add Annoncement</button>
            </Col>
            <Col xl="12">
              <Box sx={{ width: "100%", height: "100%" }}>
                <DataGrid
                  autoHeight
                  sx={{ "--DataGrid-overlayHeight": "300px" }}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  rows={dataFor}
                  loading={
                    dataFor.length == 0 && tableLoader == true ? true : false
                  }
                  columns={columnsFor}
                />
              </Box>
            </Col>

          </Row>

        </Container>
                                     <Offcanvas
                                        isOpen={isRight}
                                        direction="end"
                                        toggle={toggleRightCanvas}>
                                        <OffcanvasHeader toggle={toggleRightCanvas}>
                                            {editMode == true ? 'Edit Annoncement':'Add Annoncement'} 
                                        </OffcanvasHeader>
                                        <OffcanvasBody>
                                          {
                                            editMode == false?
                                            <Form
                                            onSubmit = {(e)=>{
                                                e.preventDefault();
                                                
                                                    let valueFrom =  localStorage.getItem('authUser');
                                                    let mapingVal = JSON.parse(valueFrom);
                                                    let org  = mapingVal.map((e)=>e.org_id);
                                                    if(e.target.elements.title.value == "" || e.target.elements.description.value == "" || e.target.elements.selected_date.value == ""){
                                                        toastr.error('All field are required');
                                                        return false;
                                                    }else{
                                                        setLoader(true)
                                                        let form = new FormData();
                                                        form.append('method', 'create-announcements');
                                                        form.append('title', e.target.elements.title.value);
                                                        form.append('description', e.target.elements.description.value)
                                                        form.append('shown_date' , formatDateShownDate(e.target.elements.selected_date.value))
                                                        form.append('org_id' , org)
                                                        var requestOptions = {
                                                            method: 'POST',
                                                            body: form,
                                                            redirect: 'follow',
                                                        };
                                                        fetch("https://api.frontforcecrm.com/announcements.php", requestOptions)
                                                            .then(response => response.text())
                                                            .then(result => {
                                                                setLoader(false)
                                                                if(result == 'success'){
                                                                Swal.fire({
                                                                    title:'Success',
                                                                    text:'You successfully added announcement',
                                                                    icon:'success',
                                                                    timer : 2000,
                                                                    timerProgressBar:true,
                                                                    showCancelButton:false,
                                                                    showConfirmButton:false
                                                                });
                                                                fetchAnnouncements();
                                                                    setIsRight(false);
                                                                    e.target.elements.title.value = ''
                                                                    e.target.elements.description.value = ''
                                                                    e.target.elements.selected_date.value = ''
                                                                }else{
                                                                    Swal.fire({
                                                                        title:'Error',
                                                                        text:'Please check your input fields and Try again',
                                                                        icon:'error',
                                                                        timer : 2000,
                                                                        timerProgressBar:true,
                                                                        showCancelButton:false,
                                                                        showConfirmButton:false
                                                                    });
                                                                    
                                                                }
                                                            })
                                                            .catch(error => console.log('error', error));
                                            
                                                    }
                                                
                                            }}
                                        >

                                            <Row>
                                                <Col md={12} className="mb-2">
                                                    <Label>Title</Label>
                                                    <Input
                                                    type="text"
                                                    name="title"
                                                    className="form-control"
                                                    />
                                                </Col>
                                                <Col md={12} className="mb-2">
                                                    <Label>Select Date</Label>
                                                    <Input
                                                    type="date"
                                                    name="selected_date"
                                                    className="form-control"
                                                    />
                                                </Col>
                                                <Col md={12} className="mb-2">
                                                    <Label>Description</Label>
                                                    <Input
                                                    type="textarea"
                                                    name="description"
                                                    className="form-control"
                                                    />
                                                </Col>
                                                <Col md={12} className="mb-2 mt-2" >
                                                    {
                                                        loader == true ? 
                                                        <Spinner color="info" />:
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    }
                                                    <span style={{cursor:'pointer',marginLeft:10}} onClick={()=>setIsRight(false)}  className="btn btn-outline btn-danger">Cancel</span>
                                                    </Col>
                                            </Row>
                                      </Form>:
                                       <Form
                                       onSubmit = {(e)=>{
                                           e.preventDefault();
                                           
                                               let valueFrom =  localStorage.getItem('authUser');
                                               let mapingVal = JSON.parse(valueFrom);
                                               let org  = mapingVal.map((e)=>e.org_id);
                                               if(e.target.elements.title.value == "" || e.target.elements.description.value == "" || e.target.elements.selected_date.value == ""){
                                                   toastr.error('All field are required');
                                                   return false;
                                               }else{
                                                   setLoader(true)
                                                   let form = new FormData();
                                                   form.append('method', 'edit-announcements');
                                                    form.append('edit_id', singleAnn.id);
                                                    form.append('title', singleAnn.title);
                                                    form.append('description', singleAnn.description)
                                                    form.append('shown_date' , formatDateShownDate(singleAnn.shown_date))
                                                 
                                                   var requestOptions = {
                                                       method: 'POST',
                                                       body: form,
                                                       redirect: 'follow',
                                                   };
                                                   fetch("https://api.frontforcecrm.com/announcements.php", requestOptions)
                                                       .then(response => response.text())
                                                       .then(result => {
                                                           setLoader(false)
                                                           if(result == 'success'){
                                                           Swal.fire({
                                                               title:'Success',
                                                               text:'You updated added announcement',
                                                               icon:'success',
                                                               timer : 2000,
                                                               timerProgressBar:true,
                                                               showCancelButton:false,
                                                               showConfirmButton:false
                                                           });
                                                           fetchAnnouncements();
                                                               setIsRight(false);
                                                               editMode(false)
                                                             
                                                           }else{
                                                               Swal.fire({
                                                                   title:'Error',
                                                                   text:'Please check your input fields and Try again',
                                                                   icon:'error',
                                                                   timer : 2000,
                                                                   timerProgressBar:true,
                                                                   showCancelButton:false,
                                                                   showConfirmButton:false
                                                               });
                                                               
                                                           }
                                                       })
                                                       .catch(error => console.log('error', error));
                                       
                                               }
                                           
                                       }}
                                   >

                                       <Row>
                                           <Col md={12} className="mb-2">
                                               <Label>Title</Label>
                                               <Input
                                               type="text"
                                               value={singleAnn.title}
                                               onChange={(e)=>setSingleAnn((prev)=>({
                                                ...prev,
                                                title: e.target.value
                                               }))}
                                               name="title"
                                               className="form-control"
                                               />
                                           </Col>
                                           <Col md={12} className="mb-2">
                                               <Label>Select Date</Label>
                                               <Input
                                               type="date"
                                               value={singleAnn.shown_date != "" ? formatDateBack(singleAnn.shown_date) : singleAnn.shown_date}
                                               onChange={(e)=>setSingleAnn((prev)=>({
                                                ...prev,
                                                shown_date: e.target.value
                                               }))}
                                               name="selected_date"
                                               className="form-control"
                                               />
                                           </Col>
                                           <Col md={12} className="mb-2">
                                               <Label>Description</Label>
                                               <Input
                                               type="textarea"
                                               name="description"
                                               value={singleAnn.description}
                                               onChange={(e)=>setSingleAnn((prev)=>({
                                                ...prev,
                                                description: e.target.value
                                               }))}
                                               className="form-control"
                                               />
                                           </Col>
                                           <Col md={12} className="mb-2 mt-2" >
                                               {
                                                   loader == true ? 
                                                   <Spinner color="info" />:
                                                   <button type="submit" className="btn btn-primary">Save Changes</button>
                                               }
                                               <span style={{cursor:'pointer',marginLeft:10}} onClick={()=>setIsRight(false)}  className="btn btn-outline btn-danger">Cancel</span>
                                               </Col>
                                       </Row>
                                 </Form>
                                          }
                                        </OffcanvasBody>
                                    </Offcanvas>
      </div>
    </React.Fragment>
  )
}
export default HomeAnnouncements
