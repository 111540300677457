import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes"
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper"
import Swal from "sweetalert2"

const fireBaseBackend = getFirebaseBackend()

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const {email} = user ; 
    let form = new FormData();
    form.append('method', 'Forgot Password');
    form.append('username', email);
    // alert("Done Successfully");
    var requestOptions = {
      method: 'POST',
      body: form,
      redirect: 'follow',
    };
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/login.php', requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        if (result !== 'success') {
           put(userForgetPasswordError(result))
          Swal.fire({
            title: 'Invalid Email',
            text:`${result}`,
            icon:'error'
          })
        } else if (result == 'success') {
          Swal.fire({
            title: 'Email Sent',
            text:`${result}`,
            icon:'success',
            confirmButtonColor:'#094eab',
            cancelButtonColor:'#ff4848',  
            cancelButtonText:'Check Inbox',
            confirmButtonText:'Login Page',
            showCancelButton:true
          }).then((is)=>{
            if(is.isConfirmed){
              window.location.href = '../login';
            }else if(is.isDismissed){
              window.open('https://mail.google.com/mail/u/0/#inbox','_blank')
            }
          })
           put(
                  userForgetPasswordSuccess(
                    "Reset link are sended to your mailbox, check there first"
                  )
                )
        }
      
      })
      .catch(error => {
         put(userForgetPasswordError(error))
       
      });
    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.forgetPassword, user.email)
    //   if (response) {
    //     yield put(
    //       userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //       )
    //     )
    //   }
    // } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
    //   const response = yield call(postJwtForgetPwd, "/jwt-forget-pwd", {
    //     email: user.email,
    //   })
    //   if (response) {
    //     yield put(
    //       userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //       )
    //     )
    //   }
    // } else {
    //   const response = yield call(postFakeForgetPwd, "/fake-forget-pwd", {
    //     email: user.email,
    //   })
    //   if (response) {
    //     yield put(
    //       userForgetPasswordSuccess(
    //         "Reset link are sended to your mailbox, check there first"
    //       )
    //     )
    //   }
    // }
  } catch (error) {
    yield put(userForgetPasswordError(error))
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
