import Activity from 'pages/Dashboard-Blog/Activity'
import React, { useEffect, useState } from 'react'
import { Container, Row,  Card,
  CardBody,
  Col,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  Input,
  Label, } from 'reactstrap'
import { Link, useLocation } from "react-router-dom"
import SimpleBar from 'simplebar-react'
import Switch from 'react-switch'
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {

} from "reactstrap"
import axios from 'axios'
export default function TaskRemarks() {
  document.title = `Task Remarks | ${process.env.REACT_APP_NAME}`
  const [currentUserID , setCurrentUserID]= useState(null)
  const [currentUserRole , setCurrentUserRole]= useState(null)
  const [remarksList , setremarksList]= useState([])
  const [statusForFinishButton , setstatusForFinishButton]= useState(false)
  const [isAttachmentSwitch , setisAttachmentSwitch]= useState(false)
  const [loadingSpinner , setloadingSpinner]= useState(false)
  const [editloadingSpinner , setEditloadingSpinner]= useState(false)
  const [createTaskShown , setCreateTaskShown]= useState(false)
  const [createEditTaskShown , setCreateEditTaskShown]= useState(false)
  const [editDescription , setEditDescription]= useState('')
  const [editDescriptionID , setEditDescriptionID]= useState('')
  const [files, setFiles] = useState([]);

  const {state} = useLocation();
  console.log(state)
  useEffect(()=>{
    fetchRemarksfromDB(1);
    FirstCheckFinishTask()
  },[1])
  const  fetchRemarksfromDB =  (pram) => {
    let localValue = localStorage.getItem('authUser');
    let paringStatus = JSON.parse(localValue);
    let emp_id = paringStatus.map(e => e.user_id);
    let role = paringStatus.map(e => e.role);
    setCurrentUserID(emp_id)
    setCurrentUserRole(role)
    let form = new FormData();

    form.append('task_id', state.task_data.task.id);
    form.append('project_id', state.task_data.task.project_id);
    form.append('method', 'fetch-task-remarks');
    var requestOptions = {
      method: 'POST',
      body: form,
    };
    console.warn(form);
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php', requestOptions)
      .then(res => res.json())
      .then(result => {
        setremarksList(result)
      
      })
      .catch(er => console.log(er));
  };
  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const formattedDate = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short'
    });
    return formattedDate;
  }

  const calculateDaysToDueDate = deadlineDate => {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const currentDate = new Date()
    const deadline = new Date(deadlineDate)

    // Calculate the difference in days
    const differenceInDays = Math.round((deadline - currentDate) / oneDay)

    // Determine status message based on the difference
    let statusMessage
    if (differenceInDays === 1) {
      statusMessage = "Tomorrow"
    } else if (differenceInDays === 0) {
      statusMessage = "Today"
    } else if (differenceInDays < 0) {
      statusMessage = `${Math.abs(differenceInDays)} days overdue`
    } else {
      statusMessage = `${differenceInDays} days remaining`
    }

    return statusMessage
  }
  const FirstCheckFinishTask =  () =>{
       let local =  localStorage.getItem('authUser');
       let mapingVal  = JSON.parse(local);
       let emp_id = mapingVal.map((e)=>e.user_id);
       var formdata = new FormData();
         formdata.append("method", "check-task-done-by-employee");
         formdata.append("emp_id", emp_id);
         formdata.append("task_id", state.task_data.task.id);
         var requestOptions = {
           method: 'POST',
           body: formdata,
           redirect: 'follow'
         };
         fetch(process.env.REACT_APP_LEAVE_API_URL+"/tasks.php", requestOptions)
           .then(response => response.text())
           .then(result =>{
             console.log(result)
            //  this.setState({loaderTaskFinish: false})
             
             if(result == 'done'){
              setstatusForFinishButton(true)
            }else{
                setstatusForFinishButton(false)
              }
           })
           .catch(error => console.log('error', error));
  }
  const onClickfinishTask =  ()=>{
    
     let local =  localStorage.getItem('authUser');
     let mapingVal  = JSON.parse(local);
     let emp_id = mapingVal.map((e)=>e.user_id);
     var formdata = new FormData();
       formdata.append("method", "task-done-by-employee");
       formdata.append("emp_id", emp_id);
       formdata.append("task_id", state.task_data.task.id);
       var requestOptions = {
         method: 'POST',
         body: formdata,
         redirect: 'follow'
       };
       fetch(process.env.REACT_APP_LEAVE_API_URL+"/tasks.php", requestOptions)
         .then(response => response.text())
         .then(result =>{
           console.log(result)
           if(result == 'success'){
            Swal.fire('Task Done' , 'You successfully done this task');
            FirstCheckFinishTask();
           }else if(result == 'already added'){
            Swal.fire('Already Finish' , 'You already finish this task');
            setstatusForFinishButton(true)
           }
           else{
            Swal.fire('Server Error' , 'Please check your input fields are try again');
           }
         })
         .catch(error => console.log('error', error));
  
}
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  );
};
const formatDateTime = (inputDate) => {
  // Parse the input date string into a Date object
  const date = new Date(inputDate);
  
  // Extract the year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
  
  // Construct the formatted date string in MM/DD/YYYY format
  const formattedDate = `${month}/${day}/${year}`;
  
  return formattedDate;
}
const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  );
}
const handleFileSelection = (e) => {
    setFiles([...e.target.files]);

};
const functionThatIwant = (desc, id) =>{
  setEditDescription(desc)
  setEditDescriptionID(id)
  setCreateEditTaskShown(true)
}
const DeleteTaskFromDB = (passingId) =>{
  
    Swal.fire({
      title: 'Delete Remarks',
      html:'Are you sure to want delete remarks',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      cancelButtonColor:'red',
      confirmButtonColor:'green'
      
  }).then((result) => {
      if (result.isConfirmed) {
          // Handle action for Button 1
          let form = new FormData();
          form.append('method', 'delete-task-remarks');
          form.append('project_id', state.task_data.task.project_id);
          form.append('remarks_id', passingId);
          form.append('task_id', state.task_data.task.id);
          var requestOptions = {
            method: 'POST',
            body: form,
          };
          fetch('process.env.REACT_APP_LEAVE_API_URL+"/tasks.php', requestOptions)
            .then(res => res.text())
            .then(result => {
              if (result == 'success') {
               Swal.fire('Deleted', 'Remarks is successfully delete');
                fetchRemarksfromDB();
              } else {
                Swal.fire('Server Error','Error while updating the status. Try again');
                
              }
            });
          

      } else if (result.dismiss === 'cancel') {
         Swal.close()
      }
  });
    

   
  
}
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <div className="ms-auto">
          <Row>
           <Col md={12}>
            <Card>
              <CardBody>
              <Row>
                <Col md={4}>
                <label>Project Name</label>
                <p>{state.task_data.project}</p>
                </Col>
                <Col md={4}>
                <label>Deadline Date</label>
                <p style={{fontWeight:'normal'}}>{calculateDaysToDueDate(state.task_data.task.deadline_date)}</p>
                </Col>
                <Col md={4}>
                  <button className='btn btn-success' onClick={()=>onClickfinishTask()} disabled={statusForFinishButton == true?  true : false }>Task Done</button>
                  <button style={{marginLeft:5}} className='btn btn-info' onClick={()=>setCreateTaskShown(true)} disabled={statusForFinishButton == true?  true : false }>Add Remarks</button>
                </Col>
              </Row>
              <Row>

                <Col md={4}>
                <label>Task </label>
                <br/>
                <p>{state.task_data.task.task_title}</p>
                </Col>
              </Row>  
              <Row>

                <Col md={12}>
                <label>{'Description'}</label>
                <br/>
                <p>{state.task_data.task.task_description} </p>
                </Col>
                <Col md={12}>
                  <label>Assigned To</label>
                  <br/>
                  <div className='row'>

                {
                state.task_data.assigned_to.map((e,index)=>{
                  
                  return(
                    <div key={index} className='col-md-1'>

                      <img
                      
                      src={e.profile_image}
                      alt=""
                      title={e.first_name + '-' + e.role}
                      className="rounded-circle avatar-xs"
                    />
                    <br/>
                   <p className={`badge ${e.status == 'active' ? 'bg-warning' : 'bg-success'}`}>{e.status}</p> 
                    </div>
                   
                  );
                })
              }
                  </div>
                </Col>
              </Row>
              
           
              </CardBody>
            </Card>
           </Col>
           <Col md={12}>
           <Card>
          <CardBody>
            <div className="d-flex align-items-start">
              <div className="me-2">
                <h5 className="card-title mb-4">Tasks Remarks</h5>
              </div>
             
            </div>
            <Col md={12}>
             {
              remarksList.map((e,index)=>{
                return(
                  <SimpleBar key={index} className="mt-2" style={{ maxHeight: "280px" }}>
                  <ul className="verti-timeline list-unstyled">
                    {/* <img
                      key={index}
                      src={e.employee_detail.profile_image}
                      alt=""
                      title={e.employee_detail.first_name + '-' + e.employee_detail.role}
                      className="rounded-circle avatar-xs"
                    /> */}
                    
                    <li className="event-list">

                      <div className="event-timeline-dot">
                        <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right"></i>
                      </div>
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-3">
                          <h5 className="font-size-14" style={{textTransform: 'uppercase'}}>{e.employee_detail.first_name + '-' + e.employee_detail.role}<i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i></h5>
                          <h5 className="font-size-14">{formatDate(e.created_at)}</h5>
                          {
                            currentUserID  == e.employee_detail.id ? 
                            <h5 className="font-size-14" ><span style={{cursor:'pointer'}} onClick={()=>functionThatIwant(e.project_remarks ,e.remarks_id)}>Edit </span><span style={{cursor:'pointer'}} onClick={()=>DeleteTaskFromDB(e.remarks_id)}>Delete</span></h5>:null

                          }
                        </div>
                        <div className="flex-grow-1">
                          <div>
                          {e.project_remarks} <span className="fw-semibold"></span>
                            {/* <Link to="#">View</Link> */}
                          </div>
                        </div>
                      </div>
                    </li>
                  
                  </ul>
                </SimpleBar>
                );
              })
             }
            </Col>
            
          </CardBody>
        </Card>
           </Col>
      </Row>

      </div>
      </Container>
      
    </div>
    <Offcanvas
          isOpen={createTaskShown}
          direction="bottom"
          style={{height: 400}}
          toggle={()=>setCreateTaskShown(false)}
        >
          <OffcanvasHeader
          toggle={()=>setCreateTaskShown(false)}
          >
            Add Remarks
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
             onSubmit={(e)=>{
              e.preventDefault();
              if (e.target.elements.task_title.value === '') {
                toastr.error('Please add some remarks description');
                return false;
              } else {
                // this.setState({submitDisabled: true});
                let localValue = localStorage.getItem('authUser');
                let paringStatus = JSON.parse(localValue);
                let emp_id = paringStatus.map(e => e.user_id);
                let form = new FormData();
                form.append('method', 'create-remarks-for-tasks');
                form.append('project_id', state.task_data.task.project_id);
                form.append('emp_id', emp_id);

                form.append('task_id', state.task_data.task.id);
                form.append('project_description', e.target.elements.task_title.value);
                
                if (isAttachmentSwitch) {
                  for (let i = 0; i < files.length; i++) {
                    form.append(`file_upload_${i}`, files[i]);
                  }
                 
                }
               
                axios.post(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php' , form ,{
                  headers:{
                    'Content-Type': 'multipart/form-data',
                  }
                }).then(result => {
                  setCreateTaskShown(false)
                  e.target.elements.task_title.value = "";
                  setloadingSpinner(false)
                    if (result.data == 'success') {
                    Swal.fire('Remark Added' , 'Your remarks are added to project');
                     fetchRemarksfromDB()
                    } else {

                      Swal.fire('Server Error' , 'Please check you input fields and try again');
                      return false;
                    }
                  })
                  .catch(er => {
                    console.log(er);
                    setloadingSpinner(false)
                  });
              }
             }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                  <Label>Description</Label>
                  <Input
                    name="task_title"
                    type="textarea"
                    className="form-control"
                    placeholder="..."
                  />
                </Col>
                
               
                <Col md={12}>
                <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            setisAttachmentSwitch(!isAttachmentSwitch);
                          }}
                    />
                    </div>
                </Col>
                {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            onChange={handleFileSelection}
                            multiple
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'end'}}>
                    <button type="button"  onClick={()=>setCreateTaskShown(false)} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        // loadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Add Remarks</button>
                        // :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
    <Offcanvas
          isOpen={createEditTaskShown}
          direction="bottom"
          style={{height: 400}}
          toggle={()=>setCreateEditTaskShown(false)}
        >
          <OffcanvasHeader
          toggle={()=>setCreateEditTaskShown(false)}
          >
            Edit Remarks
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
             onSubmit={(e)=>{
              e.preventDefault();
              if (e.target.elements.edit_task_Description.value === '') {
                toastr.error('Please add some remarks description');
                return false;
              } else {
                setEditloadingSpinner(true)
               let form = new FormData();
                form.append('method', 'edit-remarks-for-task');
                form.append('remarks_id', e.target.elements.edit_id.value);
             
                form.append('task_id', state.task_data.task.id);
                form.append('project_description', e.target.elements.edit_task_Description.value);
                
               
                axios.post(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php' , form ).then(result => {
                     setEditloadingSpinner(false)
                     setCreateEditTaskShown(false)
                    if (result.data == 'success') {
                    Swal.fire('Remark Updated' , 'Your remarks are updated to project');
                     fetchRemarksfromDB()
                     
                    } else {
                      Swal.fire('Server Error' , 'Please check you input fields and try again');
                      return false;
                    }
                  })
                  .catch(er => {
                    console.log(er);
                    setEditloadingSpinner(false)
                  });
              }
             }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                  <Label>Edit Description</Label>
                  <Input
                    name="edit_task_Description"
                    id='edit_task'
                    type="textarea"
                    className="form-control"
                    placeholder="..."
                    onChange={(e)=>setEditDescription(e.target.value)}
                    value={editDescription}
                  />
                  <Input
                    name="edit_id"
                    id='edit_id'
                    type="hidden"
                    className="form-control"
                    
                    value={editDescriptionID}
                  />
                </Col>
                
                <Col md={12} style={{textAlign:'end'}}>
                    <button type="button"  onClick={()=>setCreateEditTaskShown(false)} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        editloadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Save Changes</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
    </React.Fragment>
  )
}
