import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { AssignmentTurnedInSharp, CalendarMonthRounded, CasesSharp, CompareArrowsSharp, DashboardSharp, DescriptionSharp, EventNoteSharp, ForumSharp, GpsFixedSharp, GroupSharp, PaidSharp, PinDropSharp, PointOfSaleSharp, ReceiptSharp, SettingsPowerSharp, SettingsSharp, TaskSharp, WorkHistorySharp } from "@mui/icons-material"
import axios from "axios"

const SidebarContent = props => {
  const ref = useRef()
  const [currentrole , setCurrentRole ] = useState(null);
  const [hasvalue, sethasValue] = useState('');
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    getFromLocal();
    ref.current.recalculate()

  }, [])
  
  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }
 const getFromLocal = () =>{
  let local = JSON.parse(localStorage.getItem('authUser'));
  setCurrentRole(local.map((e)=>e.role));
  fetchZKConfig(local.map((e)=>e.org_id));
 }
 const fetchZKConfig = (org)=>{
  const form =  new FormData();
  form.append('method', 'machine-configuration-checker')
  form.append('org_id', org)
  axios.post(process.env.REACT_APP_LEAVE_API_URL + '/machine-config.php' , form).then((res)=>{
      if(res.data == 'has-value'){
        sethasValue('yes');
      }else{
        sethasValue('');
      }
  }).catch((error)=>console.log(error))
 }
 if(currentrole == 'admin'){
   return (
     <React.Fragment>
       <SimpleBar className="h-100" ref={ref}>
         <div id="sidebar-menu">
           <ul className="metismenu list-unstyled" id="side-menu">
             <li className="menu-title">{props.t("Menu")} </li>
             <li>
               <Link to="/dashboard">
                <DashboardSharp />
                 <span>{props.t("Dashboard") }</span>
               </Link>
             </li>
             <li className="step_timesheet">
               <Link to="/timesheet">
                 <EventNoteSharp/>
                 {/* <i className="bx bx-time"></i> */}
                 <span>{props.t("Timesheet")}</span>
               </Link>
             </li>
             <li className="step_live_location">
               <Link to="/track-location">
                 <GpsFixedSharp />
                 <span>{props.t("Track Locations")}</span>
               </Link>
             </li>
 
             <li>
               <Link to="/review/timeoff">
               <CasesSharp/>
                 <span>{props.t("Time Off")}</span>
               </Link>
             </li>
             {/* <li>
               <Link to="/report/overview">
                 <DescriptionSharp/>
                 <span>{props.t("Reports")}</span>
               </Link>
             </li> */}
               <li className="step_employee_">
                   <Link to="/people/members">
                     <GroupSharp/>
                     <span>{props.t("Employees")}</span>
                   </Link>
                 </li>
                 <li>
                   <Link to="/activities">
                     <AssignmentTurnedInSharp/>
                     <span>{props.t("Activities & Projects")}</span>
                   </Link>
                 </li>
                 <li>
                   <Link to="/payroll">
                     <PointOfSaleSharp/>
                     <span>{props.t("Pay roll")}</span>
                   </Link>
                 </li>
                 <li>
                   <Link to="/requests">
                     <PaidSharp/>
                     <span>{props.t("Requests")}</span>
                   </Link>
                 </li>
                 <li>
                   <Link to="/invoices">
                     <ReceiptSharp/>
                     <span>{props.t("Invoices")}</span>
                   </Link>
                 </li>
                 <li>
                   <Link to="/recruitment">
                     <PointOfSaleSharp/>
                     <span>{props.t("Recruitment")}</span>
                   </Link>
                 </li>
                 {/* <li>
                   <Link to="/time-tracking">
                     <i className="bx bx-timer"></i>
                     <span>{props.t("Time Tracking")}</span>
                   </Link>
                 </li> */}
                 <li className="step_schedule_for">
                   <Link to="/schedules">
                     <WorkHistorySharp/>
                     <span>{props.t("Work Schedules")}</span>
                   </Link>
                 </li>
                 {/* <li>
                   <Link to="/time-off-setting">
                     <i className="bx bx-briefcase-alt"></i>
                     <span>{props.t("Time Off & Holidays")}</span>
                   </Link>
                 </li> */}
                 <li className="step_addlocation_for">
                   <Link to="/locations">
                     <PinDropSharp/>
                     <span>{props.t("Location")}</span>
                   </Link>
                 </li>
               
                 <li>
                   <Link to="/organization/profile">
                     <SettingsSharp/>
                     <span>{props.t("Organization") }</span>
                   </Link>
                 </li>
                {
                  hasvalue !== '' ?
                  <li>
                   <Link to="/zk-config">
                     <CompareArrowsSharp/>
                     <span>{props.t("ZK Config")}</span>
                   </Link>
                 </li>: null
                }
                
                 
                 <li>
                    <Link to="/chat">
                      <ForumSharp/>
                      <span>{props.t("Chat")}</span>
                    </Link>
                  </li>
                 <li>
                    <Link to="/announcements">
                      <CalendarMonthRounded/>
                      <span>{props.t("Announcements")}</span>
                    </Link>
                  </li>
                 <li>
                    <Link to="/logout">
                      <SettingsPowerSharp/>
                      <span>{props.t("SignOut")}</span>
                    </Link>
                  </li>
                
             {/* <li>
               <Link to="/#" className="has-arrow">
                 <i className="bx bx-cog"></i>
                 <span>{props.t("Setting")}</span>
               </Link>
               <ul className="sub-menu">
                 <li>
                   <Link to="/people/members">
                     <i className="bx bx-user-circle"></i>
                     <span>{props.t("People")}</span>
                   </Link>
                 </li>
                 
               </ul>
             </li> */}
           </ul>
         </div>
       </SimpleBar>
     </React.Fragment>
   )
   
  }else{

    return (
      <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to="/dashboard">
                  <DashboardSharp/>
                  <span>{props.t("Dashboard") }</span>
                </Link>
              </li>
              <li>
                <Link to="/timesheet">
                <EventNoteSharp/>
                  <span>{props.t("Timesheets")}</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/live-location">
                  <i className="bx bx-map"></i>
                  <span>{props.t("Live Locations")}</span>
                </Link>
              </li> */}
  
              <li>
                <Link to="/review/timeoff">
                <CasesSharp/>
                  <span>{props.t("Time Off")}</span>
                </Link>
              </li>
              {/* <li>
                <Link to="/report/overview">
                  <i className="bx bx-file"></i>
                  <span>{props.t("Reports")}</span>
                </Link>
              </li> */}
                {/* <li>
                    <Link to="/people/members">
                      <i className="bx bx-user-circle"></i>
                      <span>{props.t("People")}</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/time-tracking">
                      <i className="bx bx-timer"></i>
                      <span>{props.t("Time Tracking")}</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/schedules">
                      <i className="bx bx-task"></i>
                      <span>{props.t("Work Schedules")}</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/time-off-setting">
                      <i className="bx bx-briefcase-alt"></i>
                      <span>{props.t("Time Off & Holidays")}</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to="/locations">
                      <i className="bx bx-map"></i>
                      <span>{props.t("Location")}</span>
                    </Link>
                  </li> */}
                  <li>
                   <Link to="/tasks">
                     <TaskSharp/>
                     <span>{props.t("Tasks")}</span>
                   </Link>
                 </li>
                    <li>
                   <Link to="/requests">
                   <PaidSharp/>
                     <span>{props.t("Requests")}</span>
                   </Link>
                 </li>
                  <li>
                    <Link to="/chat">
                      <ForumSharp/>
                      <span>{props.t("Chat")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout">
                    <SettingsPowerSharp/>
                      <span>{props.t("SignOut")}</span>
                    </Link>
                  </li>
                  
                  
                  {/* <li>
                    <Link to="/integration">
                      <i className="bx bx-git-compare"></i>
                      <span>{props.t("Integration")}</span>
                    </Link>
                  </li> */}
              {/* <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-cog"></i>
                  <span>{props.t("Setting")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/people/members">
                      <i className="bx bx-user-circle"></i>
                      <span>{props.t("People")}</span>
                    </Link>
                  </li>
                  
                </ul>
              </li> */}
            </ul>
          </div>
        </SimpleBar>
      </React.Fragment>
    )
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
