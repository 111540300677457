import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty, map } from "lodash"
import moment from "moment"
import { getDatabase,ref,onValue ,off ,push,update} from "firebase/database"
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar"
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import avatar1 from "../../assets/images/users/avatar-1.jpg"

import {
  addMessage as onAddMessage,
  getChats as onGetChats,
  getContacts as onGetContacts,
  getGroups as onGetGroups,
  getMessages as onGetMessages,
} from "store/actions"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
//redux
import { useSelector, useDispatch } from "react-redux"
import { Idno } from "pages/Contacts/ContactsProfile/CryptoCol"
import { ChatOutlined } from "@mui/icons-material"

function ImageComponent({ imageUrl }) {
  const [base64Image, setBase64Image] = useState('');

  useEffect(() => {
    const convertImageUrlToBase64 = async () => {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          setBase64Image(reader.result);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error('Error converting image to base64:', error);
      }
    };

    convertImageUrlToBase64();
  }, [imageUrl]);

  return (
    <a rel="noreferrer" target="_blank" href={base64Image}>
      <img
        // onLoad={()=> <p>Text Here</p>}
        style={{ width: 100, height: 100 }}
        src={base64Image || 'https://via.placeholder.com/200'}
        alt="Image"
      />
    </a>
  );
}
const Chat = () => {
  //meta title
  document.title = `Chat | ${process.env.REACT_APP_NAME}`

  const dispatch = useDispatch()

  const { chats } = useSelector(state => ({
    chats: state.chat.chats,
  }))

  const [messageBox, setMessageBox] = useState(null)
  const [isRight , setIsRight] = useState(false);
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState({
    _id: '',
    name:'',
    avatar:'',
  })
  const [currentUser, setCurrentUser] = useState({
    name: "",
    isActive: true,
    id: null,
    image: null
  })
  const toggleRightCanvas = () => {
    setIsRight(!isRight);
};
  const [search_Menu, setsearch_Menu] = useState(false)
  const [settings_Menu, setsettings_Menu] = useState(false)
  const [other_Menu, setother_Menu] = useState(false)
  const [activeTab, setactiveTab] = useState("1")
  const [Chat_Box_Username, setChat_Box_Username] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState(null)
  const [curMessage, setcurMessage] = useState("")
  const [contacts, setContacts] = useState([])
  const [groups, setGroups] = useState([])
  const [messageList, setMessageList] = useState([])

  const [senderID, setSenderID] = useState(null); // Set your sender user ID here
  const [senderName, setSenderName] = useState(null); // Set your sender user ID here
  const [messages, setMessages] = useState([]);
  const db = getDatabase();

  const [currentProfile, setCurrentProfile] = useState(null)
  // useEffect(() => {
  //   let sender =  localStorage.getItem('authUser');
  //   let senderSender = JSON.parse(sender);
  //   const data = 1012;
  //   console.log(senderSender);
  //   let senderThat = senderSender.map(e => e.user_id);
  //   let firstName = senderSender.map(e => e.first_name);
  //   let profileimage = senderSender.map(e => e.profile_image);
  //   setSenderName(firstName);
  //   setSenderID(senderThat);
  //   setProfileImageUrl(profileimage);
  //   const chatId = `${Math.min(senderThat, data)}_${Math.max(
  //     senderThat,
  //     data,
  //   )}`;
  //   const messagesRef = firebase.database().ref(`chats/${chatId}/messages`);
  //   messagesRef.on('value', snapshot => {
  //     const messageList = [];
  //     snapshot.forEach(child => {
  //       messageList.push(child.val());
  //     });
  //     console.log(messageList.reverse())
  //     // setMessages();
  //   });
  //   return () => messagesRef.off('value');
  // }, []);
  const fetchUserForMessage = async () => {
    // this.setState({isLoading: true});
    let valuesFrom =  localStorage.getItem('authUser');
    let ParsingValuesFrom = JSON.parse(valuesFrom);
    let org_id = ParsingValuesFrom.map(e => e.org_id);
    let user_id = ParsingValuesFrom.map(e => e.user_id);
    let role = ParsingValuesFrom.map(e => e.role);
    let username = ParsingValuesFrom.map(e => e.username);
    let profile = ParsingValuesFrom.map(e => e.profile_image);
    setCurrentUser({
      name :username,
      isActive : true,
      id : user_id,
      image: profile
    })
    setCurrentProfile(profile)
      const formdata = new FormData();
      formdata.append("method", "fetch_single_list_chat");
      formdata.append("emp_id", user_id[0]);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

      fetch("https://api.frontforcecrm.com/messages.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          // this.setState({isLoading: false});
          
          result.sort((a, b) => {
            // Convert date-time strings to Date objects
            let dateA = new Date(a.update_at);
            let dateB = new Date(b.update_at);
            if (dateA > dateB) return -1;
            if (dateA < dateB) return 1;
            return 0;
        });

          
          // setMessageList(result)
          // this.setState({setMessageList: result});
          
       
        })
        .catch((error) => console.error(error));

  };
  const fetchMessages = (receiver_id,name,image) => {
    // alert(receiver_id)
    let local =JSON.parse(localStorage.getItem('authUser'));
    let emp_id = local.map((e)=>e.user_id)
    let org_id = local.map((e)=>e.org_id)
    let username = local.map((e)=>e.username)
    let profile = local.map((e)=>e.profile_image)
    
    setCurrentUser({
      name :username,
      isActive : true,
      id : emp_id,
      image: profile
    })
    const chatId = `${Math.min(emp_id[0], receiver_id)}_${Math.max(
      emp_id[0],
      receiver_id,
    )}`;
    
    const messagesRef = ref(db, `/chats/${chatId}/messages`);
    
    onValue(messagesRef, (snapshot) => {
      
      const messageList = [];
      let updates = {}
      snapshot.forEach((child) => {
        const message = child.val();
        
        if (message.receiver._id == emp_id[0]) {
          message.status = "read";
          updates[child.key] = message;
        }
        messageList.push(child.val());
      });
     
      update(messagesRef, updates)
      .then(() => {
        setMessages(messageList);
        console.log("Status updated successfully!");
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
    });
  };
  const fetchEmployeeListForMessage = async () =>{
  let local =JSON.parse(localStorage.getItem('authUser'));
  let emp_id = local.map((e)=>e.user_id)
  let org_id = local.map((e)=>e.org_id)
  let username = local.map((e)=>e.username)
  let profile = local.map((e)=>e.profile_image)

  setCurrentUser({
    name :username,
    isActive : true,
    id : emp_id,
    image: profile
  })
  setCurrentProfile(profile) 
  var formdata = new FormData();
  formdata.append("method", "fetch_employees_for_message_desktop");
  formdata.append("emp_id",emp_id );
  formdata.append("org_id", org_id);

  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
   
  await fetch(process.env.REACT_APP_LEAVE_API_URL+"/messages.php", requestOptions)
    .then(response => response.json())
    .then(result =>  {
     
      setContacts(result)
    })
    .catch(error => console.log('error', error));
}
const fetchGroupsListForMessage = async ()=>{
  let local = JSON.parse(localStorage.getItem('authUser'));
  let emp_id = local.map((e)=>e.user_id)
  let role = local.map((e)=>e.role)
  var formdata = new FormData();
  formdata.append('method', 'get-tasks-assigned-all');
  formdata.append('id', emp_id);
  formdata.append('role', role);
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };
  
  await fetch(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php', requestOptions)
    .then(response => response.json())
    
    .then(result => {
      
      // this.setState({isLoading: false})
      const filteredTasks = result.filter(task => {
        // console.log(task)
        const assignedToIds = task.task.assigned_to.split(',');
        assignedToIds
        return assignedToIds.includes(emp_id) && assignedToIds.length > 1;
      });
      
      // Update the state with the filtered tasks
      // this.setState({ filterFetch: filteredTasks });
      setGroups(result)

      
    })
    .catch(error => console.log('error', error));
}

  useEffect(() => {
    
    fetchEmployeeListForMessage();
    fetchUserForMessage()
 
  }, [ currentRoomId.id])

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom()
  }, [messages])

  // const toggleNotification = () => {
  //   setnotification_Menu(!notification_Menu)
  // }

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu)
  }

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu)
  }

  const toggleOther = () => {
    setother_Menu(!other_Menu)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  //Use For Chat Box
  const runFirst =  (receiverId)=> {
   const receiverIds = receiverId.toString();
    let sender =  localStorage.getItem('authUser');
    let senderSender = JSON.parse(sender);
    
    let senderThat = senderSender.map(e => e.user_id);
    let firstName = senderSender.map(e => e.first_name);
    let profileimage = senderSender.map(e => e.profile_image);
    setCurrentUser({
      name :firstName[0],
      isActive : true,
      id : senderThat[0],
      image: profileimage[0]
    })
    setCurrentProfile(profileimage[0])
    const newArray = receiverIds.split(',');
    const receiverIdsArray = newArray.filter(item => item !== senderThat[0]);
    receiverIdsArray.push(senderThat[0]);
    const groupChatId = receiverIdsArray.sort().join('_');
    const messagesRef = ref(db,`/groupChat/${groupChatId}/messages/${senderThat[0]}`);
    
    onValue(messagesRef, (snapshot) => {
      const messageList = [];
      snapshot.forEach((child) => {
        messageList.push(child.val());
      });
      setMessages(messageList);
    })
   
  }
  const userChatOpen = (id, name, status) => {
    setChat_Box_Username(name)
    setCurrentRoomId({
      _id: id,
      name: name,
      avatar : status
    })
    fetchMessages(id, name, status)
  }
  const userChatGrupOpen = (id, name, status) => {
    setChat_Box_Username(name)
    // setCurrentRoomId(id)
    // console.log(id);
    runFirst(id)
    
    // dispatch(onGetMessages(id))
  }

  const addMessage = (roomId, sender) => {
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: curMessage,
      createdAt: new Date(),
    }
    setcurMessage("")
    dispatch(onAddMessage(message))
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }

  const onKeyPress = e => {
    const { key, value } = e;

  //  console.log(e.target.value)
    if (key == "Enter") {
      // setcurMessage(value)
      onSend()
      // addMessage(currentRoomId, currentUser.name)
    }
  }

  //serach recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue
    input = document.getElementById("search-user")
    filter = input.value.toUpperCase()
    ul = document.getElementById("chat-list")
    li = ul.getElementsByTagName("li")
    
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")
      
      txtValue = a.textContent || a.innerText
      
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = ""
      } else {
        li[i].style.display = "none"
      }
    }
  }
  const [deleteMsg, setDeleteMsg] = useState("")
  const toggle_deleMsg = ele => {
    setDeleteMsg(!deleteMsg)
    ele.closest("li").remove()
  }
  const copyMsg = ele => {
    var copyText = ele
      .closest(".conversation-list")
      .querySelector("p").innerHTML
    navigator.clipboard.writeText(copyText)
    toastr.success('You successfully copied the message');
  }
  
  // Create a reference to the messages node in your database
  
  // Push a new message to the messages node
  
  const onSend = () => {
   
    
   const text =  document.getElementById('chat_message').value;
    const currentDate = new Date();

    const convertedCreatedAt = currentDate.toString()
  
  const chatId = `${Math.min(currentUser.id[0], currentRoomId._id)}_${Math.max(currentUser.id[0], currentRoomId._id)}`;
  const messagesRef = ref(db, `chats/${chatId}/messages`);
    // // Push the new message to the messages node
    const user = {
      _id : currentUser.id[0],
      name : currentUser.name[0],
      avatar: currentUser.image[0]
    }
  
    push(messagesRef, {
      createdAt: convertedCreatedAt,
      text,
      user,
      receiver:{
        _id: currentRoomId._id,
        name:currentRoomId.name,
        avatar:currentRoomId.avatar
      },
      status:'un-read'
    }).then((e)=>{
      var formdata = new FormData();
        formdata.append("message", text);
        formdata.append("method", "Push Notification");
        formdata.append("emp_id",currentRoomId._id);
        formdata.append("type", "Chat");
        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };
        fetch(process.env.REACT_APP_LEAVE_API_URL+"/tasks.php", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
        setcurMessage('')

    }).catch((e)=>{
      console.error(e)
    })

   
    
  };

  useEffect(() => {
    // setMessageList([])
    const fetchLastMessages = async () => {
      setMessages([])
      const userId = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.user_id);
      
      const chatIdsRef = ref(db, 'chats');
      onValue(chatIdsRef, async (snapshot) => {
        const chatIds = snapshot.val();
        
        let array = [];
        if (!chatIds) return;
       Object.keys(chatIds).map(async (chatId) => {
          
          // Use userId here to filter chatIds based on participants
          const chatIdParts = chatId.split('_');
          
          if(chatIdParts.includes(userId[0]) == true){
            const lastMessageRef = ref(db, `chats/${chatId}/messages`);
            console.log('last_messageRef' + lastMessageRef)
            onValue(lastMessageRef, async (messageSnapshot) => {
              
              const mess = messageSnapshot.val();
              const sortedMessages = Object.values(mess).sort((a, b) => {
                // Convert createdAt to date objects for comparison
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                // Sort messages by createdAt in descending order
                return dateB - dateA;
              });
              
              const otherUserMessages = sortedMessages.filter(message => message.user._id !== userId[0]);
             
            
            array.push({
              text: sortedMessages[0].text,
              last_message: sortedMessages[0],
               user: sortedMessages[0].user._id == userId[0] ? sortedMessages[0].receiver : otherUserMessages[0].user,
              createdAt: sortedMessages[0].createdAt,
              status : sortedMessages[0].user._id == userId[0] ? null : sortedMessages[0].status
            });
            });
            const sortedMessagesArray = Object.values(array).sort((a, b) => {
              // Convert createdAt to date objects for comparison
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              // Sort messages by createdAt in descending order
              return dateB - dateA;
            });

            setMessageList(sortedMessagesArray)
            
          }
        });
      });
  
      return () => {
        // Detach the listener
        off(chatIdsRef);
      };
    };
    fetchLastMessages();
  }, [db]);
  function formatMessageTime(timestamp) {
    const currentDate = new Date();
    const messageDate = new Date(timestamp);

    // Calculate the difference in milliseconds between the current date and the message date
    const diffMilliseconds = currentDate - messageDate;

    // Convert milliseconds to seconds
    const diffSeconds = Math.floor(diffMilliseconds / 1000);

    // Define time intervals in seconds
    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60
    };

    // Function to calculate plural or singular form
    const pluralize = (count, noun) => count === 1 ? noun : `${noun}s`;

    // Loop through intervals to find the appropriate one
    for (const [interval, seconds] of Object.entries(intervals)) {
        const count = Math.floor(diffSeconds / seconds);
        if (count > 0) {
            return count === 1 ? 'just now' : `${count} ${pluralize(count, interval)} ago`;
        }
    }

    // If message is more than a minute ago, return the time only
    return messageDate.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
}

const filterDataMessage = text => {
  let arrayNew = []
  arrayNew = messageList;
  console.log(text.length);
  if (text.length !== 0) {
    const newData = arrayNew.filter(item => {
      // Check if the receiver's name or message contains the specified text
      return item.receiver_name.toLowerCase().includes(text.toLowerCase()) || item.message.toLowerCase().includes(text.toLowerCase());
  });
  setMessageList(newData); 
  // this.setState({filterFetch: newData});
  } else {
    fetchUserForMessage();
    // setMessageList(messageList)
    // this.setState({filterFetch: filterFetch});
  }
};

const runnerMeFor = (user,sender)=>{
  const formdata = new FormData();
  formdata.append("method", "update_status_message");
  formdata.append("emp_id", user); 
  formdata.append("sender_id", sender); 
   
  const requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow"
  };

  fetch("https://api.frontforcecrm.com/messages.php", requestOptions)
    .then((response) => response.text()).then((res)=>{
      if(res == 'success'){
        console.error('success');
        fetchUserForMessage();
      }else{
        console.error('error');
      }
    })
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="" breadcrumbItem="Chat" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div>
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <img
                            src={currentProfile || avatar1}
                            className="avatar-xs rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">
                            {currentUser.name}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active
                          </p>
                        </div>

                        <div>
                          {/* <Dropdown
                            isOpen={menu1}
                            toggle={() => setMenu1(!menu1)}
                            className="chat-noti-dropdown"
                          > */}
                            {/* <DropdownToggle tag="a" className="btn"> */}
                              <ChatOutlined style={{cursor:'pointer'}} onClick={()=>setIsRight(true)}/>
                            {/* </DropdownToggle> */}
                         
                          {/* </Dropdown> */}
                        </div>
                      </div>
                    </div>
                    
                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onChange={(e)=>filterDataMessage(e.target.value)}
                          id="search-user"
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1")
                              fetchEmployeeListForMessage();
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Chat</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2")
                              fetchGroupsListForMessage();
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Groups</span>
                          </NavLink>
                        </NavItem>
                        {/* <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3")
                            }}
                          >
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Contacts</span>
                          </NavLink>
                        </NavItem> */}
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                        <div>
                            <PerfectScrollbar style={{ height: "310px" }}>
                           
                                {messageList &&
                                  messageList.map((contact,index) => (
                                    <div
                                      key={"test_" + index}
                                    
                                    >
                                      
                                      <ul id="chat-list" className="list-unstyled chat-list">
                                      
                                          <li  style={{width: '100%'}}>
                                          
                                            <Link
                                              to="#"
                                              style={{display:'flex',columnGap: 10}}
                                              onClick={() => {
                                                // runnerMeFor(currentUser.id , contact.user._id)
                                                userChatOpen(
                                                  contact.user._id,
                                                  contact.user.name,
                                                  contact.user.avatar
                                                )
                                              }}
                                            >
                                               <img src={contact.user.avatar || 'https://via.placeholder.com/80'} className="avatar-sm rounded-circle"/>
                                            <div style={{justifyContent:'center'}} className="mt-1">
                                             <h5 className="font-size-14 mb-1">
                                                {contact.user.name}
                                              </h5>
                                              <p>{contact.text}</p>
                                            </div>
                                              <div>
                                                  {contact.last_message.receiver._id ==  currentUser.id[0]  && contact.status == 'un-read' ? <Badge color="success">{contact.status}</Badge> : null }
                                              </div> 
                                            </Link>
                                          </li>
                                    
                                      </ul>
                                    </div>
                                  ))}
                                {
                                  messageList.length == 0 ? 
                                  <p>No Chat Found</p>:null
                                }
                            </PerfectScrollbar>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          <h5 className="font-size-14 mb-3">Group </h5>
                          <ul className="list-unstyled chat-list">
                            
                            <PerfectScrollbar style={{ height: "310px" }}>
                              {groups &&
                                groups.map(group => (
                                  <li key={"test" }>
                                    <Link
                                      to="#"
                                      onClick={() => {
                                        userChatGrupOpen(
                                          group.assigned_to.map((e)=>e.id),
                                          group.task.task_title,
                                          Math.floor(Math.random() * 100)
                                        )
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-xs me-3">
                                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                            {group.task.task_title.charAt(0)}
                                          </span>
                                        </div>

                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14 mb-0">
                                            {group.task.task_title}
                                          </h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                            </PerfectScrollbar>
                          </ul>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
               {
                Chat_Box_Username == null ? 
                null:
                <div className="w-100 user-chat">
                <Card>
                  <div className="p-4 border-bottom ">
                    <Row>
                      <Col md="4" xs="9">
                        <h5 className="font-size-15 mb-1">
                          {Chat_Box_Username}
                        </h5>

                        <p className="text-muted mb-0">
                          <i
                            className={
                              Chat_Box_User_Status === "Active Now"
                                ? "mdi mdi-circle text-success align-middle me-2"
                                : Chat_Box_User_Status === "intermediate"
                                ? "mdi mdi-circle text-warning align-middle me-1"
                                : "mdi mdi-circle align-middle me-1"
                            }
                          />
                          {Chat_Box_User_Status}
                        </p>
                      </Col>
                      <Col md="8" xs="3">
                       
                      </Col>
                    </Row>
                  </div>

                  <div>
                    <div className="chat-conversation p-3">
                      <ul className="list-unstyled">
                        <PerfectScrollbar
                          style={{ height: "350px" }}
                          containerRef={ref => setMessageBox(ref)}
                        >
                          <li>
                            <div className="chat-day-title">
                              <span className="title">Today</span>
                            </div>
                          </li>
                          {/* <p>{JSON.stringify(messages)}</p> */}
                          {messages &&
                            map(messages,( message, index )=> (
                              <li
                                key={index}
                                className={
                                  message.user._id == currentUser.id[0]
                                    ? "right"
                                    : "left"
                                }
                              >
                                <div className="conversation-list">
                                  { 
                                   message.user._id == currentUser.id[0] ?
                                   <UncontrolledDropdown>
                                    <DropdownToggle
                                      href="#"
                                      tag="a"
                                      className="dropdown-toggle"
                                    >
                                      <i className="bx bx-dots-vertical-rounded" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      <DropdownItem
                                        onClick={e => copyMsg(e.target)}
                                        href="#"
                                      >
                                        Copy
                                      </DropdownItem>
                                     
                                      
                                    </DropdownMenu>
                                  </UncontrolledDropdown>: null
                                  }
                                  <div className="ctext-wrap">
                                    <div className="conversation-name">
                                      {message.user.name}
                                    </div>
                                    {
                                      message.image !==undefined ? 
                                      <ImageComponent imageUrl={message.image}/>: null
                                    }
                                    <p>{message.text}</p>
                                    <p className="chat-time mb-0">
                                      <i className="bx bx-time-five align-middle me-1"></i>{" "}
                                      {formatMessageTime(String(message.createdAt))}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </PerfectScrollbar>
                      </ul>
                    </div>
                    <div className="p-3 chat-input-section">
                      <Row>
                        <Col>
                          <div className="position-relative">
                            <input
                              type="text"
                              value={curMessage}
                              onKeyPress={onKeyPress}
                              onChange={e => setcurMessage(e.target.value)}
                              className="form-control chat-input"
                              placeholder="Enter Message..."
                              id="chat_message"
                            />
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-emoticon-happy-outline me-1"
                                        id="Emojitooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Emojitooltip"
                                      >
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-image-outline me-1"
                                        id="Imagetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Imagetooltip"
                                      >
                                        Images
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-file-document-outline"
                                        id="Filetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Filetooltip"
                                      >
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            type="button"
                            color="primary"
                            onClick={() =>

                              onSend()
                            }
                            className="btn btn-primary btn-rounded chat-send w-md "
                          >
                            <span className="d-none d-sm-inline-block me-2">
                              Send
                            </span>{" "}
                            <i className="mdi mdi-send" />
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </div>  
               }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Offcanvas
                                        isOpen={isRight}
                                        direction="end"
                                        toggle={toggleRightCanvas}>
                                        <OffcanvasHeader toggle={toggleRightCanvas}>
                                            Contact List
                                        </OffcanvasHeader>
                                        <OffcanvasBody>
                                        <div>
                            <PerfectScrollbar style={{ height: "100%" }}>
                              {contacts &&
                                contacts.map(contact => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul id="chat-list" className="list-unstyled chat-list">
                                      {contact.child.map(array => (
                                        <li key={array.id} style={{width: '100%'}}>
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              setIsRight(false)
                                              userChatOpen(
                                                array.id,
                                                array.name,
                                                array.image
                                              )
                                            }}
                                          >
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </PerfectScrollbar>
                          </div>
                                        </OffcanvasBody>
                                    </Offcanvas>
    </React.Fragment>
  )
}

Chat.propTypes = {
  chats: PropTypes.array,
  groups: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  onGetChats: PropTypes.func,
  onGetGroups: PropTypes.func,
  onGetContacts: PropTypes.func,
  onGetMessages: PropTypes.func,
  onAddMessage: PropTypes.func,
}

export default Chat
