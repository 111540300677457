import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Card,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  Modal,
  CardTitle,
  TabContent,
  TabPane,
  Spinner,
  InputGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import classnames from "classnames"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.0.2/firebase-analytics.js";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {onBackgroundMessage} from 'firebase/messaging/sw'
import firebase from 'firebase/app';
import 'firebase/messaging';
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Circle,
} from "google-maps-react"
import Tour from 'reactour'
//import Charts
import Trackedhour from "./Trackedhourchart"

//import action
import { getChartsData as onGetChartsData } from "../../store/actions"

// Pages Components
import WelcomeComp from "./WelcomeComp"

import { useSelector, useDispatch } from "react-redux"
import Tasks from "./Taskchart"
import axios from "axios"
import { messaging } from "helpers/firebase"

import { Backdrop, CircularProgress } from "@mui/material"
import Swal from "sweetalert2"
const LoadingContainer = () => <div>Loading...</div>
// const firebaseConfig = {
//   apiKey: "AIzaSyA_6M_8PIdIldfei5f9KTn5B8qrBooXl90",
//   authDomain: "big-coil-356918.firebaseapp.com",
//   databaseURL: "https://big-coil-356918-default-rtdb.firebaseio.com",
//   projectId: "big-coil-356918",
//   storageBucket: "big-coil-356918.appspot.com",
//   messagingSenderId: "264726020315",
//   appId: "1:264726020315:web:b96f671f4c58858e35ccfe",
//   measurementId: "G-GJQQT1XD90"
// };
// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);
const Dashboard =   props => {
  const [modal, setmodal] = useState(false)

  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

 
  const [alllocation, setAlllocation] = useState(false)
  const [allgroups, setAllgroups] = useState(false)
  const [allschedules, setAllschedules] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [sidebar, setSidebar] = useState("10")
  const [singlebtn1, setSinglebtn1] = useState(false)
  const [currentUserID, setCurrentUserID] = useState(null)
  const [currentUserName, setCurrentUserName] = useState(null)
  const [currentUserRole, setCurrentUserRole] = useState(null)
  const [orgID, setOrgID] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(localStorage.getItem('selectedLocationLocally') == null ? "All Location" : localStorage.getItem('selectedLocationLocally'))

  const [mockData2, setmockData2] = useState([])
  const [activeScreen, setActiveScreen] = useState(null)
  const [filteredData, setfilteredData] = useState([])
  const [attendanceData, setattendanceData] = useState([])
  const [Types, setTypes] = useState([])
  const [orgName, setOrgName] = useState("")
  const [alllocations, setAlllocations] = useState(false)
  const [mockData2s, setMockDatas] = useState([])
  const [attendanceDatas, setAttendanceDatas] = useState([])
  const [showPlaceHolder, setShowPlaceHolder] = useState(false)
  const [total, setTotal] = useState(0)
  const [absent, setAbsent] = useState(0)
  const [present, setPresent] = useState(0)
  const [companyLat, setCompanyLat] = useState(null)
  const [companyLong, setCompanyLong] = useState(null)
  const [company_Name, setCompanyName] = useState("")
  const [selectedLocations, setSelectedLocations] = useState(null)
  const [selectedMarkerData, setSelectedMarkerData] = useState({})
  const [infoWindowVisible, setInfoWindowVisible] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [circleGeo, setCircleGeo] = useState(null)
  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")
  const [profileImage , setProfileImage ] = useState('')
  const [leaves, setLeaves] = React.useState('');
  const [totalEmployee, setTotalEmployee] = React.useState('');
  const [presentEmployee, setPresentEmployee] = React.useState('');
  const [absentEmployee, setAbsentEmployee] = React.useState('');
  const [requested, setRequested] = React.useState(0);
  const [currentDate, setDate] = React.useState('');
  const [optionsForTask ,setOptionsForTasks] = useState([])
  const [showLoader ,setShowLoader] = useState(false)
  const [modal_center, setmodal_center] = useState(true);
  const [announcementsArray, setannoucementArray] = useState([]);
  const [comeFirstTimeOnScreen ,setEditComeFirstTimeOnScreen] = useState(localStorage.getItem('ComesFirstTimeOnScreen') == null ? true: false)
  const handleLocationSelect = val => {
    setSelectedLocation(val)
    localStorage.setItem('selectedLocationLocally' , val);
    
  }
  const getTasksCounter = () => {
    let localArray =JSON.parse( localStorage.getItem('authUser'));
    
    let org = localArray.map((e)=>e.org_id);
    let user_id = localArray.map((e)=>e.user_id);
    let role = localArray.map((e)=>e.role);
    var formdata = new FormData();
    formdata.append('method', 'get-tasks-assigned-all-by-status');
    formdata.append('id', user_id);
    formdata.append('role', role);
    formdata.append('status', 'null');
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    fetch(process.env.REACT_APP_LEAVE_API_URL+ '/tasks.php', requestOptions)
      .then(response => response.json())
      .then(result => {

        if(role == 'admin'){
          const total = result.filter(item => {
            return item ;
          })
          console.log(total)
          const active = result.filter(item => {
            let currentDate = new Date();
            let deadlineDate = new Date(item.task.deadline_date);
            if(item.task.status == 'active' &&  deadlineDate > currentDate){
              return item;
            }

          })
          const overdue = result.filter(item => {
            let currentDate = new Date();
            let deadlineDate = new Date(item.task.deadline_date);
            
            if(deadlineDate < currentDate && item.task.status == 'active'){
              return item;
            }
          })
          const completed = result.filter(item => {
            if(item.task.status == 'completed' ){
              return item;
            }
          })
          let option  =  {
              'completed' : completed.length || 0,
              'active' : active.length || 0,
              'total' : total.length || 0,
              'overdue' : overdue.length || 0,
    
          }
          setOptionsForTasks(option)
        }else{
        const total = result.filter(item => {
          let assignedTo = item.assigned_to.find(
            user => user.id == user_id,
          );
          if(assignedTo.lenght !== 0 ){
            return item;
          } 
        })
        
        const active = result.filter(item => {
          let currentDate = new Date();
          let deadlineDate = new Date(item.task.deadline_date);
          let assignedTo = item.assigned_to.find(
            user => user.id == user_id,
          );
         
          if(assignedTo.lenght !== 0 && item.task.status == 'active' &&  deadlineDate > currentDate){
            return item;
          }

        })
        const overdue = result.filter(item => {
          let currentDate = new Date();
          let deadlineDate = new Date(item.task.deadline_date);
          let assignedTo = item.assigned_to.find(
            user => user.id == user_id,
          );
          if(assignedTo.lenght !=0 && deadlineDate < currentDate && item.task.status == 'active'){
            return item;
          }
        })
        const completed = result.filter(item => {
          let assignedTo = item.assigned_to.find(
            user => user.id == user_id,
          );
          if(assignedTo.lenght !=0 && item.task.status == 'completed' ){
            return item;
          }
        })
        let option  =  {
          'completed' : completed.length || 0,
          'active' : active.length || 0,
          'total' : total.length || 0,
          'overdue' : overdue.length || 0,

      }
      setOptionsForTasks(option)
      }
        
      })
      .catch(error =>{
        window.location.href = '/no-internet'
        console.log('error', error)});
  };
  function formatDateFroFutureUse(inputDateStr) {
    // Parse the input date string
    const inputDate = new Date(inputDateStr);
    
    // Extract year, month, and day components
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month starts from 0
    const day = String(inputDate.getDate()).padStart(2, '0');
    
    // Assemble the formatted date string in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`;
    
    return formattedDate;
}
  const getValuesFromLocal = () => {
    setOpen(true);
    const local = localStorage.getItem("authUser")
    const convertArray = JSON.parse(local)
    let user_id = convertArray.map(e => e.user_id)
    let username = convertArray.map(e => e.first_name)
    let role = convertArray.map(e => e.role)
    let org_id = convertArray.map(e => e.org_id)
    let org_name = convertArray.map(e => e.org_title)
    let profile_image = convertArray.map(e => e.profile_image)
    setProfileImage(profile_image)
    setOrgName(org_name)
    setCurrentUserID(user_id)
    setCurrentUserName(username)
    setCurrentUserRole(role)
    setOrgID(org_id)
    if (role == "admin" || role == 'supervisor') {
      setActiveScreen("4")
      
        let dateof = new Date();
        const value = formatDateFroFutureUse(dateof.toString())
       setDate(value);
      fetchforadmin(value , user_id , org_id)
    } else {
      setActiveScreen("5")
    }
    handleEmployeeFrombackend(user_id,role,org_id, "in")
    setSidebar("in")
    fetchCompaines(user_id, role, org_id)
    setOpen(false);
  }
  
  const fetchCompaines = async (id, role, org) => {
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", id)
    formdata.append("org_id", org)

    axios
      .post(process.env.REACT_APP_LEAVE_API_URL+"/company.php" , formdata)
      .then(result => {
        if (result.data.length !== 0) {
          let array = []
          result.data.map(e => {outerHeight
            let val = {
              value: e.location,
              label: e.location,
            }
            array.push(val)
          })
          setmockData2(array)
        }
      })
      .catch(error => console.log("error", error))
  }
  const categorizeAttendanceData = (attendanceData, employeeData,attDatas,role) => {
    if(role[0] == 'admin' && selectedLocation == 'All Location'){
      console.log(employeeData)
    return employeeData.map((employee,index) => {
      const attendance = attendanceData.find((data) => data && data.emp_id === employee.emp_id);
      if (attendance) {
        if(employee.current_mode == 'Break'){
          return { ...employee, attendance: "Break" };
        }
        if (attendance.punch_time_in  && attendance.punch_time_out) {
          return { ...employee, attendance: "Out" };
        }
        if (attendance.punch_time_in) {
          return { ...employee, attendance: "In" };
        }
      }else if(attDatas[index] == 'yes'){
        return { ...employee, attendance: "Leave" };
      } else {
        return { ...employee, attendance: "Absent" };
      }
    });
   }else if(role[0] == 'admin' && selectedLocation != 'All Location'){
    
    return employeeData.map((employee,index) => {
      const attendance = attendanceData.find((data) => data && data.emp_id === employee.id);
      if (attendance) {
        if(employee.current_mode == 'Break'){
          return { ...employee, attendance: "Break" };
        }
        if (attendance.punch_time_in  && attendance.punch_time_out) {
          return { ...employee, attendance: "Out" };
        }
        if (attendance.punch_time_in) {
          return { ...employee, attendance: "In" };
        }
      }else if(attDatas[index] == 'yes'){
        return { ...employee, attendance: "Leave" };
      } else {
        return { ...employee, attendance: "Absent" };
      }
    });
  }else{
    return employeeData.map((employee ,index) => {
      const attendance = attendanceData.find((data) => data && data.emp_id === employee.emp_id);
      if (attendance) {
        if(employee.current_mode == 'Break'){
          return { ...employee, attendance: "Break" };
        }
        if (attendance.punch_time_in  && attendance.punch_time_out) {
          return { ...employee, attendance: "Out" };
        }
        if (attendance.punch_time_in) {
          return { ...employee, attendance: "In" };
        }
      } else if(attDatas[index] == 'yes'){
        return { ...employee, attendance: "Leave" };
      } else {
        return { ...employee, attendance: "Absent" };
      }
    });
   }
  };
  const handleEmployeeFrombackend = (user,role,id, val) => {
    setShowLoader(true)
    setattendanceData([])
    var formdata = new FormData()

    formdata.append("method", "employee-by-role-for-dashboard")
    formdata.append("id_for", id)
    formdata.append("role", role)
    formdata.append("emp_id", user)
    formdata.append("location", selectedLocation == 'All Location' ? 'All' : selectedLocation)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL+"/kiosk.php", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(result => {
        setShowLoader(false)
        const arrayFor = result.data.map(e => e.employee_data)
        console.log(result.data.filter(e => e.attendance_data != null).map(e => e.attendance_data))
        
        setattendanceData(result.data.map(e => e.attendance_data))

         
        const attData =result.data.map(e => e.attendance_data);
         const attDatas =result.data.map(e => e.leave_status);
         
         const categorizedData = categorizeAttendanceData(attData, arrayFor,attDatas,role);
         console.log(categorizedData)
         
        if (val == "all") {
          setfilteredData(arrayFor)
        }
        if (val == "in") {
         
          setfilteredData(categorizedData.filter(e => e.attendance == "In"))
        }
        if (val == "break") {
          setfilteredData(arrayFor.filter(e => e.current_mode == "Break"))
        }
        if (val == "out") {
      
          setfilteredData(categorizedData.filter(e => e.attendance == "Out"))
        }
        if (val == "absent") {
          setfilteredData(categorizedData.filter(e => e.attendance == "Absent"))
        }
        if (val == "leave") {
          const filteredDatas = result.data.filter(e => e.leave_status === "yes" && e.employee_data);
          
          setfilteredData(filteredDatas.map((e)=>e.employee_data));
         
        }
        const breakRes = result.data.filter(
          e => e.employee_data.current_mode === "Break"
        )
        const LeaveRes = result.data.filter(
          e => e.leave_status == "yes"
        )
        const InRes = categorizedData.filter(
          e => e.attendance == "In"
        )
        const OutRes = categorizedData.filter(
          e => e.attendance == "Out" 
        )
        const absentRes = categorizedData.filter(
          e => e.attendance == 'Absent'
        )
        const radioDatas = [
          { label: `${InRes.length}`, value: "in" },
          { label: `${breakRes.length}`, value: "break" },
          { label: `${OutRes.length}`, value: "out" },
          { label: `${absentRes.length}`, value: "absent" },
          { label: `${LeaveRes.length}`, value: "leave" },
        ]
        setTypes(radioDatas)
      })
      .catch(e => console.log(e))
  }
  function extractFirstWords(sentence, numWords) {
    // Split the sentence into an array of words
    const words = sentence.split(' ');

    // Take the first 'numWords' words and join them back into a sentence
    const firstWords = words.slice(0, numWords).join(' ');

    return firstWords;
  }
useEffect(()=>{
  requestNotificationPermission();
}, []);

// useEffect(() => {
 
// }, []);

const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      // new Notification({title:'Hi'})
      const token = await getToken(messaging, { vapidKey: "BHuFkhY9qYHOjiozIl7ydDnUAEmI-BWFQdHQwF5fuekJkMBPqeQ0v2sJi_S5m8LVyxLHyb28XzypFUHT3iB4jPg" });
      console.log('FCM Token:', token);
      postTokenToApi(token);
    } else if (permission === 'denied') {
      console.log('Notification permission denied');
      // Handle denied permission gracefully
    } else {
      console.log('Notification permission dismissed');
    }
  } catch (error) {
    console.error('Error requesting permission:', error);
  }
};
  const fetchAnnouncements = () => {
    let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
    
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    
    var formdata = new FormData();
    formdata.append('method', 'fetch-upcoming-events');
    formdata.append('inputDate', formattedDate);
    formdata.append('org_id', org);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
   
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/announcements.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        setannoucementArray(result)
      })
      .catch(error => console.log('error', error));
  };
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  const postTokenToApi = async (token)=>{
    let form = new FormData();
    const value =  localStorage.getItem('authUser');
    let convertValues = JSON.parse(value);
    let userId = convertValues.map(e => e.user_id);
    form.append('method', 'update-fcm-token');
    form.append('emp_id', userId);
    form.append('fcm_token', token);
    var requestOptions = {
      method: 'POST',
      body: form,
      redirect: 'follow',
    };
    fetch('https://api.frontforcecrm.com/login.php', requestOptions)
      .then(response => response.text())
      .then(result => {
         console.log(result)
      })
      .catch(er => console.log(er));
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const onchangeRadioButton = val => {
    setSidebar(val)
    handleEmployeeFrombackend(currentUserID,currentUserRole,orgID, val)
    // if (val == "in") {
    //   setfilteredData(filteredData.filter(e => e.current_mode == "In"))
    // }
    // if (val == "break") {
    //   setfilteredData(filteredData.filter(e => e.current_mode == "Break"))
    // }
    // if (val == "out") {
    //   setfilteredData(filteredData.filter(e => e.current_mode == "Out"))
    // }
    // if (val == "absent") {
    //   setfilteredData(filteredData.filter(e => e.current_mode == "Absent"))
    // }
  }
  const limitSet = 1
  useEffect(() => {
    getTasksCounter()
    fetchAnnouncements()
    getValuesFromLocal()
    fetchEmployee()
    setPeriodData(chartsData)
  }, [chartsData , selectedLocation , limitSet])
  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const toggleTab2 = tab => {
    if (activeScreen !== tab) {
      setActiveScreen(tab)
    }
  }
  const toggleTab3 = tab => {
    if (sidebar !== tab) {
      setSidebar(tab)
    }
  }
  const formatTimeWithAmPm = dateString => {
    const date = new Date(dateString)

    // Extract hours, minutes, and seconds
    let hours = date.getHours()
    const minutes = ("0" + date.getMinutes()).slice(-2)
    const seconds = ("0" + date.getSeconds()).slice(-2)

    // Determine AM or PM
    const ampm = hours >= 12 ? "PM" : "AM"

    // Convert hours to 12-hour format
    hours = hours % 12 || 12

    // Format the time as "hh:mm:ss AM/PM"
    const formattedTime = `${hours}:${minutes} ${ampm}`

    return formattedTime
  }
  document.title = `Dashboard | ${process.env.REACT_APP_NAME}`
  const fetchforadmin = (valueofdate, another, org_id) => {
    var formdata = new FormData();
    formdata.append('method', 'fetch_attendance_by_date');
    formdata.append('filter_date', valueofdate);
    formdata.append('emp_id', another);
    formdata.append('org_id', org_id);
    formdata.append('location_for', selectedLocation == 'All Location'  ? 'All' : selectedLocation);

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    
  fetch(process.env.REACT_APP_LEAVE_API_URL+'/attendance.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        setTotalEmployee(result.total);
        setAbsentEmployee(result.absent);
        setPresentEmployee(result.present);
        setLeaves(result.leaves);
        setRequested(result.requested);
      })
      .catch(error => console.log('error', error));
  };
const fetchEmployee = async id => {
  setCompanyLat(null)
  setCompanyLong(null)
  var formdata = new FormData()
  let local = JSON.parse(localStorage.getItem('authUser'));
  let org = local.map((e)=>e.org_id)
  formdata.append("method", "fetch_company_by_name")  
  
  formdata.append("id", selectedLocation == 'All Location' ? 'All' : selectedLocation)
  formdata.append("org_id", org[0])
  var requestOptions = {
    method: "POST",
    body: formdata,
    redirect: "follow",
  }
  await fetch(process.env.REACT_APP_LEAVE_API_URL+ "/company.php", requestOptions)
    .then(response => response.json())
    .then(e => {
      console.log(e);
      if(selectedLocation !== 'All Location'){

        setAttendanceDatas(e.present_data)
        setPresent(e.present)
        setAbsent(e.absent)
        setCompanyLat(e.c_lat)
        setCompanyLong(e.c_long)
        setCompanyName(e.company_name)
        setTotal(e.total)
        setCircleGeo(e.geofence)
      }
    })
    .catch(error => {
      // this.setState({isLoading:false});
      console.log("error", error)
    })
}
  const handleDropdownItemClick = (val, id) => {
    setSelectedLocations(val)
    
    setAlllocation(false)
  }
  const onMouseoverMarker = (props, marker, e) => {
    // setSelectedMarkerData(props)
    // setInfoWindowVisible(true)
    // setActiveMarker(marker)
    console.log(e)
  }
  const onCloseClick = props => {
    if (infoWindowVisible) {
      setInfoWindowVisible(false)
      setActiveMarker({})
    }
  }
  const convertTime = inputTime => {
    if (inputTime !== 0) {
      const [hours, minutes, seconds] = inputTime.split(":")
      const dateObj = new Date()
      dateObj.setHours(hours)
      dateObj.setMinutes(minutes)
      dateObj.setSeconds(seconds)
      const formattedTime = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })

      return formattedTime.toLowerCase() // Convert to lowercase for consistency with 'pm'
    } else {
      return "N/a"
    }
  }
  const [searchQuery, setSearchQuery] = useState('');

  // useEffect(() => {
  //   // Filter the data based on the search query
  //   const filtered = filteredData.filter(employee => {
  //     const { id, first_name, last_name, role } = employee;
  //     const searchTerm = searchQuery.toLowerCase();
  //     return (
  //       id.includes(searchTerm) ||
  //       first_name.toLowerCase().includes(searchTerm) ||
  //       last_name.toLowerCase().includes(searchTerm) ||
  //       role.toLowerCase().includes(searchTerm)
  //     );
  //   });
  //   setfilteredData(filtered);
  // }, [searchQuery, filteredData]);
  const handleSearchChange = (event) => {
    
    if(event.target.value.length == 0 ){
        handleEmployeeFrombackend(currentUserID,currentUserRole, orgID , sidebar )
        setSearchQuery(event.target.value);
    }else{
      setSearchQuery(event.target.value);
    }

  };
  function formatDateByMe(inputDateStr) {
    const inputDate = new Date(inputDateStr);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return inputDate.toLocaleDateString('en-GB', options);
}
const [selectedDateForSending, setselectedDateForSending] = useState(new Date());
const [isTourOpen, setIsTourOpen] = useState(false);
const [currentStep, setCurrentStep] = useState(0);
const nextStep = () => {
  setCurrentStep(prevStep => prevStep + 1)
};
const prevStep = () => {
  setCurrentStep(prevStep => prevStep - 1)
};
const closeTour = () => {
  setCurrentStep(0)
  setIsTourOpen(false)};
const steps = [
  {
    selector: '.step_addlocation_for',
    content: 'Step 1: Add Location and enable geofencing',
    position: 'right',
    
  },
  {
    selector: '.step_schedule_for',
    content: 'Step 2: Add Work Schedule for your organization',
    position: 'top',
    
  },
  {
    selector: '.step_employee_',
    content: 'Step 3: Add Employees before adding employee add department.',
    position: 'bottom',
    
  },
  {
    selector: '.step_live_location',
    content: 'Step 4: Track live locations those which you want.',
    position: 'left',
    
  },
  {
    selector: '.step_timesheet',
    content: 'Step 5: Manage Timesheet Daily, Weekly & Monthly &',
    position: 'bottom',
    
  },
  {
    selector: '.employment_in_out_status',
    content: 'Step 6: View organization employee real-time in out status',
    position: 'right',
    
  },
  {
    selector: '.current_Day_present_absent',
    content: 'Step 7: Here you can see the current day present absent employee information',
    position: 'top',
    
  },
  {
    selector: '.weekly_tracked_Chart_data',
    content: 'Step 8: Here you can see the weekly timesheet graph data according to selected location',
    position: 'top',
    
  },
];

const formatShowCaseDate = inputDateString => {
  const [month, day, year] = inputDateString.split('/');
  const date = new Date(`${year}-${month}-${day}`);
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: '2-digit',
  };
  return date.toLocaleDateString('en-US', options);
};


const handleEventForDate = (e)=>{
  
  setselectedDateForSending(e)
  const gettingValue = formatDateFroFutureUse(e.toString());
  setDate(gettingValue)
  fetchforadmin(gettingValue , currentUserID , orgID)
}
const [open, setOpen] = React.useState(true);
const handleClose = () => {
  setOpen(false);
};
const handleOpen = () => {
  setOpen(true);
};


  return (
    <React.Fragment>
      <div className="page-content">
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
            
            <Tour
              steps={steps}
              isOpen={isTourOpen}
              onRequestClose={closeTour}
              goToStep={currentStep}
              showButtons={true}
              lastStepNextButton={<button className="btn btn-primary" onClick={closeTour}>Finish</button>}
              disableDotsNavigation={true}
              nextButton={<button className="btn btn-primary" onClick={nextStep}>Next</button>}
              prevButton={currentStep!== 0 ? <button className="btn btn-info" onClick={prevStep}>Prev</button> : null}
              skipButton={<button className="btn btn-secondary" onClick={closeTour}>Skip</button>}
             />
          </div>
       
        <Container fluid>
          <div className="ms-auto">

            <Row>
             
              <Col className="offset-xl-4" xl="5">
                <TabContent activeTab={activeScreen}>
                  <TabPane tabId="4">
                    <div className="float-end">
                      <div className="d-flex flex-end flex-wrap gap-2">
                        {currentUserRole == "admin" && activeScreen == "4" ? (
                          <Dropdown
                            isOpen={alllocation}
                            toggle={() => setAlllocation(!alllocation)}
                            // onChange={(e)=>console.log(e.target.value)}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn-sm btn btn-light"
                            >
                              {selectedLocation || "All Location"}
                              <i className="mdi mdi-chevron-down" />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() =>
                                  handleLocationSelect("All Location")
                                }
                              >
                                {"All Location"}
                              </DropdownItem>
                              {mockData2.map((e, index) => {
                                return (
                                  <DropdownItem
                                    key={index}
                                    onClick={() =>
                                      handleLocationSelect(e.value)
                                    }
                                  >
                                    {e.value}
                                  </DropdownItem>
                                )
                              })}
                            </DropdownMenu>
                          </Dropdown>
                        ) : null}
                        
                      </div>
                    </div>
                  </TabPane>
                </TabContent>
              </Col>

              <Col xl="3">
                <div className="float-end">
                  <div className="btn-group btn-group-sm" role="group">
                    {currentUserRole == "admin"  || currentUserRole == 'supervisor'? (
                      <Link
                        to="#"
                        className={`btn btn-outline-primary  ${
                          activeScreen === "4" ? "active" : ""
                        }`}
                        onClick={() => {
                          toggleTab2("4")
                        }}
                      >
                        
                        {currentUserRole == 'admin' ? 'Organization' : 'Managed by me'}
                      </Link>
                    ) : null}
                    <Link
                      to="#"
                      className={`btn btn-outline-primary ${
                        activeScreen === "5" ? "active" : ""
                      }`}
                      onClick={() => {
                        toggleTab2("5")
                        setSelectedLocation('All Location');
                      }}
                    >
                      Personal
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <br />

          <Row>
            <Col xl={activeScreen === "4" ? "8" : "12"}>
              <Row>
                <Col xl="12">
                  <TabContent activeTab={activeScreen}>
                    <TabPane tabId="4">
                      <Row>
                        <Col md={6}>
                          <Card
                            className="overflow-hidden"
                            style={{
                              minHeight: "200px",
                              // backgroundImage: `url(${profileImg})`,
                              backgroundSize: "50% auto", // Adjust as needed
                              backgroundPosition: "bottom right",
                              backgroundRepeat: "no-repeat",
                            }}
                          >
                            <CardBody className="pt-3">
                              <Row>
                                <Col sm="12">
                                  <h4 className="text-primary">{orgName}</h4>
                                  <p className="text-muted mb-0">
                                    Welcome to {orgName}, here you can manage
                                    your organization data.
                                  </p>
                                  <p>
                                  <div className="no-border-form">

                                    <Flatpickr
                                      
                                      placeholder="dd M,yyyy"
                                      value={selectedDateForSending}
                                      style={{borderWidth:0}}
                                      onChange={(e)=>{
                                        handleEventForDate(e)
                                      }}
                                      options={{
                                        altInput: true,
                                        altFormat: "F j, Y",
                                        dateFormat: "Y-m-d",
                                        maxDate: new Date(),
                                        className: "custom-flatpickr"
                                        
                                      
                                      }}
                                    
                                    />
                                  </div>
                                   
                                  </p>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>

                        <Col md={6}>
                          <Card
                            className="overflow-hidden"
                            style={{ minHeight: "200px" }}
                          >
                            <CardBody className="pt-3">
                              <Row>
                                <Col sm="12">
                                  <h6 className="text-primary">
                                    Upcoming Annoncements
                                  </h6>
                                  <p className="text-muted mb-0">
                                    Here's what's happening at Unihandle <br />{" "}

                                    {/* {JSON.stringify(announcementsArray)} */}
                                    {
                                      announcementsArray.map((e,index)=>{
                                       return (
                                        <Row key={index} style={{backgroundColor:'#f3f3f3',padding:10,borderRadius:5}} className="mt-2">
                                          <Col md={5}>
                                          <p key={index} style={{margin:0,fontWeight:'bold'}}>
                                          {formatShowCaseDate(e.shown_date).split(',').map((e,index)=>{if(index==0){return(e) ;}})}
                                          <br></br>
                                          {formatShowCaseDate(e.shown_date).split(',').map((e,index)=>{if(index==1){return(e) ;}})}
                                           </p>
                                          </Col>
                                          <Col md={6}>
                                          <p key={index} style={{margin:0}}>
                                             {e.title}
                                           </p>
                                          <p style={{margin:0,cursor:'pointer'}} id={`_id_for_description_${index}`}>
                                             {extractFirstWords(e.description,3)}....
                                           </p>
                                           <UncontrolledTooltip placement="left-end" target={`_id_for_description_${index}`}>
                                            {e.description}
                                           </UncontrolledTooltip>
                                          </Col>
                                        </Row>
                                       );
                                      })
                                    }
                                    {announcementsArray.length == 0 ?  'No Holiday Today' : null}

                                  </p>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={12}>
                        <Row className="current_Day_present_absent">
          <Col lg={3}>
            <Card className="mini-stats-wid">
              <CardBody>
                <Link
                to={'/attendance/total'}
                state={{
                  location: selectedLocation, 
                  filter_date  : currentDate
                }}
                >
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Total</p>
                    <h5 className="mb-0">{totalEmployee}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div style={{background:'transparent'}} className="avatar-title text-primary font-size-20">
                    <img src={require('../../assets/images/icons/Employees.png')} style={{width: 40,height : 40}}/>
                    </div>
                  </div>
                </div>
                </Link>
              </CardBody>
            </Card>
          </Col>

          <Col lg={3}>
            <Card className="blog-stats-wid">
              <CardBody >
              <Link
                to={'/attendance/present'}
                state={{
                  location: selectedLocation, 
                  filter_date  : currentDate
                }}
                >
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Present</p>
                    <h5 className="mb-0">{presentEmployee}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                      {/* <i className="bx bxs-note"></i> */}
                      <img src={require('../../assets/images/icons/Presents.png')} style={{width: 40,height : 40}}/>
                    </div>
                  </div>
                </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">

              <CardBody>
              <Link
                to={'/attendance/absent'}
                state={{
                  location: selectedLocation, 
                  filter_date  : currentDate
                }}
                >
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Absent</p>
                    <h5 className="mb-0">{absentEmployee}</h5>
                  </div>

                  <div className="avatar-sm ms-auto">
                    <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                    <img src={require('../../assets/images/icons/Absents.png')} style={{width: 40,height : 40}}/>
                    </div>
                  </div>
                </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3}>
            <Card className="blog-stats-wid">
              <CardBody>
                <Link 
                to={'/attendance/leave'}
                state={{
                  location: selectedLocation  == 'All Location' ? 'All' : selectedLocation, 
                  filterDate  : currentDate,
                  status: 'Total',
                }}
                
                
                >
                <div className="d-flex flex-wrap">
                  <div className="me-3">
                    <p className="text-muted mb-2">Leaves</p>
                    <h5 className="mb-0">{leaves}</h5>
                  </div>

                  <div className="avatar-sm ms-auto"> 
                    <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                    <img src={require('../../assets/images/icons/Leaves.png')} style={{width: 40,height : 40}}/>
                    </div>
                  </div>
                </div>
                </Link>
              </CardBody>
            </Card>
          </Col>
        </Row>
                        </Col>
                      </Row>
                      <Row>
                      <Col md={12}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col xl="6">
                                <div className="d-sm-flex flex-wrap">
                                  <h4 className="card-title mb-4">WEEKLY HOURS</h4>
                                </div>
                              </Col>
                              <Col xl="6">
                                <Link to={"/timesheet"} className="float-end">
                                  
                                  Go to timesheets
                                </Link>
                              </Col>
                              <Col md={12} className="weekly_tracked_Chart_data">
                              <Trackedhour  location={selectedLocation} />
                              </Col>  
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md={12}>
                       
                      {companyLat !== null && companyLong !== null && selectedLocation !== 'All Location'  ? (
                        // <SingleMapView company_Name={company_Name}  companyLat={companyLat} companyLong={companyLong} selectedLocation={selectedLocation} attendanceDatas={attendanceDatas} onMouseoverMarker={onMouseoverMarker} activeMarker={activeMarker} infoWindowVisible={infoWindowVisible} selectedMarkerData={selectedMarkerData} circleGeo={circleGeo} />
                        <Card>
                          <CardBody>
                            <div
                              id="gmaps-markers"
                              className="gmaps"
                              style={{ position: "relative"  ,width: '100%',height: '100%' }}
                            >
                              <p>{company_Name}</p>
                              <Map
                                onClick={onCloseClick}
                                initialCenter={{
                                  lat: parseFloat(companyLat),
                                  lng: parseFloat(companyLong),
                                }}
                             
                                google={props.google}
                                zoom={11}
                              >
                                <Marker
                                  title={`${company_Name}`}
                                  position={{
                                    lat: parseFloat(companyLat),
                                    lng: parseFloat(companyLong),
                                  }}
                                />
                                <Circle
                                  center={{
                                    lat: parseFloat(companyLat),
                                    lng: parseFloat(companyLong),
                                  }} // Set center of the circle
                                  radius={parseFloat(circleGeo)} // Set radius of the circle in meters
                                  strokeColor="red" // Set stroke color
                                  strokeOpacity={0.8} // Set stroke opacity
                                  strokeWeight={2} // Set stroke weight
                                  fillColor="red" // Set fill color
                                  fillOpacity={0.35} // Set fill opacity
                                />
                                {attendanceDatas.map((e, index) => {
                                  let arrayDefined = e.check_in_location.split(",")
                                  console.log(e.profile_image)
                                  let value = []
                                  if (e.check_in_time !== null) {
                                    value = e.check_in_time.split(" ")
                                  }
                                  let valuez = []
                                  if (e.check_out_time !== null) {
                                    valuez = e.check_out_time.split(" ")
                                  }
                                  let firstIn = convertTime(
                                    value.length !== 0 ? value[1] : 0
                                  )
                                  let lastOut = convertTime(
                                    valuez.length !== 0 ? valuez[1] : 0
                                  )
                                  return (
                                    <Marker
                                      key={index}
                                      profile={e.profile_image}
                                      onClick={(props,marker,e)=>{
                                        console.log(props)
                                      }}
                                      title={e.first_name}
                                      checkIn={firstIn}
                                      checkOut={lastOut}
                                      role={e.role}
                                      position={{
                                        lat: parseFloat(arrayDefined[0]),
                                        lng: parseFloat(arrayDefined[1]),
                                      }}
                                    />
                                  )
                                })}

                                <InfoWindow
                                  visible={infoWindowVisible}
                                  marker={activeMarker}
                                >
                                  <div style={{ overflowX: "hidden" }}>
                                    <Row>
                                      <Col md={2}>
                                        <img
                                          style={{
                                            width: 40,
                                            height: 40,
                                            borderRadius: 100,
                                          }}
                                          src={selectedMarkerData.profile}
                                        />
                                      </Col>
                                      <Col md={4}>
                                        <span
                                          style={{
                                            fontSize: 18,
                                            fontWeight: "500",
                                          }}
                                        >
                                          {selectedMarkerData.title}
                                        </span>
                                        <br></br>
                                        <span style={{ fontSize: 14 }}>
                                          {selectedMarkerData.role}
                                        </span>
                                      </Col>
                                      <Col md={5}>
                                        <Row>
                                          <span>
                                            Check In : {selectedMarkerData.checkIn}
                                          </span>
                                          <span>
                                            Check Out :{" "}
                                            {selectedMarkerData.checkOut}
                                          </span>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                </InfoWindow>
                              </Map>
                            </div>
                          </CardBody>
                        </Card>
                  ) : null}
                      </Col>
                    </Row>
                    </TabPane>
                    <TabPane tabId="5">
                    <Row>
                     
                     <Col md={6}>
                          <WelcomeComp dataForTask={optionsForTask} orgName={orgName} currentUserName={currentUserName}  profileImage={profileImage} currentRole={currentUserRole}/>
                        </Col>
                     
                      <Col md={8}>
                      <Card>
                          <CardBody>
                            <Row>
                              <Col xl="6">
                                <div className="d-sm-flex flex-wrap">
                                  <h4 className="card-title mb-4">WEEKLY HOURS</h4>
                                </div>
                              </Col>
                              <Col xl="6">
                              <Link to={"/timesheet"} className="float-end">
                                  
                                  Go to timesheets
                                </Link>
                              </Col>
                              <Trackedhour location={selectedLocation} />
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                  <Card>
                    <CardBody>
                      <CardTitle>Tasks</CardTitle>
                      <div id="doughnut-chart" className="e-chart">
                        <Tasks dataColors='["--bs-primary", "--bs-success"]' />
                      </div>
                    </CardBody>
                  </Card>
              </Col>
                    </Row>
                    </TabPane>
                  </TabContent>
                </Col>

               
              </Row>

              
              
            </Col>
            {activeScreen == "4" ? (
              <Col xl="4">
                <Row>
                <Col md={12}>

                <Card>
                  <CardBody className="employment_in_out_status" style={{ height: '500px', overflowY: 'scroll',scrollbarWidth: 'none' }}>
                    <div className="text-center" style={{ maxHeight: '100%', overflowY: 'auto' }}>

                      <CardTitle>Who's in/out</CardTitle>
                      {/* <p>{JSON.stringify(filteredData)}</p> */}
                      <Nav tabs className="nav-tabs-custom" role="tablist">
                        {Types.map((e, index) => (
                          <NavItem key={index}>
                            {e.label}
                            <NavLink
                              style={{ textTransform: "uppercase",padding:12 }}
                              className={classnames({
                                active: sidebar === e.value,
                              })}
                              onClick={() => {
                                onchangeRadioButton(e.value)
                              }}
                            >
                              {e.value}
                            </NavLink>
                          </NavItem>
                        ))}
                      </Nav>
                    </div>
                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <TabContent activeTab={sidebar}>
                          <TabPane tabId="in">
                            <Input
                               value={searchQuery}
                               onChange={handleSearchChange}
                              id="search-user"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                            <br />
                            <div>
                              {filteredData.length === 0 ? (
                                <p className="text-muted mb-0 justify-content-center">
                                  No record found
                                </p>
                              ) : null}
                              {/* <p className="text-muted mb-0 justify-content-center">
                            No Record found in "IN" section
                          </p> */}
                              {
                                showLoader == false ?(
                              filteredData.map((e, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: 10,
                                    }}
                                    key={index}
                                  >
                                    <div>
                                      <img
                                        src={e.profile_image}
                                        style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 100,
                                        }}
                                      />

                                      
                                      {/* <p>{JSON.stringify(e)}</p> */}
                                  </div>
                                      
                                    <div className="my_class_iwant" style={{ justifyContent: "center" }}>
                                      
                                      {attendanceData.map((myval, index) => {
                                        
                                        if (myval != null) {
                                          
                                          if(selectedLocation !== 'All Location'){
                                          if (e.id ==   myval.emp_id) {
                                            const obj = {
                                              status: 'present',
                                              attendance_id : myval.id,
                                              data: e,
                                              id: e.id,
                                              created_at: myval.created_at,
                                              time_punch_in:
                                                myval.punch_time_in,
                                              time_punch_out:
                                                myval.punch_time_out,
                                              total_work_time:
                                                e.total_work_time == null ? 0 : e.total_work_time,
                                              total_break_time:
                                                e.total_break_time != null ?  e.total_break_time : 0,
                                            }
                                          
                                            return (
                                              <div key={index}>
                                              
                                              <Link
                                                 state={{
                                                  obj : obj,
                                                  created_at: myval.created_at,
                                                 }}
                                                 
                                                 to={`../../timesheet/view/`}>{e.first_name} {e.last_name}</Link>
                                                 <p>{e.job_title}</p>
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Clock In{" "}
                                                  {formatTimeWithAmPm(
                                                    `${myval.punch_time_in}`
                                                  )}
                                                </span>
                                                <br />
                                                {myval.punch_time_out ? (
                                                  <span
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Clock Out{" "}
                                                    {formatTimeWithAmPm(
                                                      `${myval.punch_time_out}`
                                                    )}
                                                  </span>
                                                ) : null}
                                              </div>
                                            )
                                         
                                          }
                                          }else{
                                            if (e.emp_id ==   myval.emp_id) {
                                                const obj = {
                                                    status: 'present',
                                                    data: e,
                                                    id: e.emp_id,
                                                    attendance_id : myval.id,
                                                    created_at: myval.created_at,
                                                    time_punch_in:
                                                      myval.punch_time_in,
                                                    time_punch_out:
                                                      myval.punch_time_out,
                                                    total_work_time:
                                                      e.total_work_time == null ? 0 : e.total_work_time,
                                                    total_break_time:
                                                      e.total_break_time != null ?  e.total_break_time : 0,
                                                  }
                                              return (
                                                <div key={index}>
                                          

                                                 <Link
                                                 state={{
                                                  obj : obj,
                                                  created_at: myval.created_at,
                                                 }}
                                                 
                                                 to={`../../timesheet/view/`}>{e.first_name} {e.last_name}</Link>
                                                 <p>{e.job_title}</p>
                                                  <span
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Clock In{" "}
                                                    {formatTimeWithAmPm(
                                                      `${myval.punch_time_in}`
                                                    )}
                                                  </span>
                                                  <br />
                                                  {myval.punch_time_out ? (
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Clock Out{" "}
                                                      {formatTimeWithAmPm(
                                                        `${myval.punch_time_out}`
                                                      )}
                                                    </span>
                                                  ) : null}
                                                </div>
                                              )
                                           
                                            } 
                                          }
                                        }
                                      })}
                                    </div>
                                  </div>
                                )
                              })):<Spinner color="info" />
                            }
                            </div>
                          </TabPane>
                          <TabPane tabId="break">
                            <Input
                              value={searchQuery}
                              onChange={handleSearchChange}
                              id="search-user"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                            <br />
                            <div>
                              {filteredData.length === 0 ? (
                                <p>No record found</p>
                              ) : null}
                              {
                               showLoader == false ?(
                              filteredData.map((e, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: 10,
                                    }}
                                    key={index}
                                  >
                                    <div>
                                      <img
                                        src={e.profile_image}
                                        style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 100,
                                        }}
                                      />

                                      
                                    </div>
                                    <div style={{ justifyContent: "center" }}>
                                      <p>
                                   
                                        {attendanceData.map((myval, index) => {
                                          if (myval !== null) {
                                            if(selectedLocation !== 'All Location'){
                                              if (e.id ==   myval.emp_id) {
                                                const obj = {
                                                  status: 'present',
                                                  data: e,
                                                  id: e.id,
                                                  attendance_id : myval.id,
                                                  created_at: myval.created_at,
                                                  time_punch_in:
                                                    myval.punch_time_in,
                                                  time_punch_out:
                                                    myval.punch_time_out,
                                                  total_work_time:
                                                    e.total_work_time == null ? 0 : e.total_work_time,
                                                  total_break_time:
                                                    e.total_break_time != null ?  e.total_break_time : 0,
                                                }
                                                return (
                                                  <div key={index}>
                                                      <Link
                                                    state={{
                                                      obj : obj,
                                                      created_at: myval.created_at,
                                                    }}
                                                    
                                                    to={`../../timesheet/view/`}>{e.first_name} {e.last_name}</Link>
                                                    <p>{e.job_title}</p>
                                                   {/* <p>{JSON.stringify(myval)}</p> */}
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Clock In{" "}
                                                      {formatTimeWithAmPm(
                                                        `${myval.punch_time_in}`
                                                      )}
                                                    </span>
                                                    <br />
                                                    {myval.punch_time_out ? (
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Clock Out{" "}
                                                        {formatTimeWithAmPm(
                                                          `${myval.punch_time_out}`
                                                        )}
                                                      </span>
                                                    ) : null}
                                                  </div>
                                                )
                                             
                                              }
                                              }else{
                                                if (e.emp_id ==   myval.emp_id) {
                                                  const obj = {
                                                    status: 'present',
                                                    data: e,
                                                    id: e.emp_id,
                                                    attendance_id : myval.id,
                                                    created_at: myval.created_at,
                                                    time_punch_in:
                                                      myval.punch_time_in,
                                                    time_punch_out:
                                                      myval.punch_time_out,
                                                    total_work_time:
                                                      e.total_work_time == null ? 0 : e.total_work_time,
                                                    total_break_time:
                                                      e.total_break_time != null ?  e.total_break_time : 0,
                                                  }
                                                  return (
                                                    <div key={index}>
                                                        <Link
                                                      state={{
                                                        obj : obj,
                                                        created_at: myval.created_at,
                                                      }}
                                                      
                                                      to={`../../timesheet/view/`}>{e.first_name} {e.last_name}</Link>
                                                      <p>{e.job_title}</p>
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Clock In{" "}
                                                        {formatTimeWithAmPm(
                                                          `${myval.punch_time_in}`
                                                        )}
                                                      </span>
                                                      <br />
                                                      {myval.punch_time_out ? (
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          Clock Out{" "}
                                                          {formatTimeWithAmPm(
                                                            `${myval.punch_time_out}`
                                                          )}
                                                        </span>
                                                      ) : null}
                                                    </div>
                                                  )
                                               
                                                } 
                                              }
                                          }
                                        })}
                                      </p>
                                    </div>
                                  </div>
                                )
                              })):<Spinner color="info"/>}
                            </div>
                          </TabPane>
                          <TabPane tabId="out">
                            <Input
                              value={searchQuery}
                              onChange={handleSearchChange}
                              id="search-user"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                            <br />
                            <div>
                              {filteredData.length === 0 ? (
                                <p>No record found</p>
                              ) : null}
                              {
                               showLoader == false ?(
                              filteredData.map((e, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: 10,
                                    }}
                                    key={index}
                                  >
                                    <div>
                                      <img
                                        src={e.profile_image}
                                        style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 100,
                                        }}
                                      />

                                      
                                    </div>
                                    <div style={{ justifyContent: "center" }}>
                                      <p>
                                    
                                        {attendanceData.map((myval, index) => {
                                         if(myval != null){
                                          if(selectedLocation !=='All Location'){
                                            if (e.id ==   myval.emp_id) {
                                              const obj = {
                                                status: 'present',
                                                data: e,
                                                id: e.id,
                                                attendance_id : myval.id,
                                                created_at: myval.created_at,
                                                time_punch_in:
                                                  myval.punch_time_in,
                                                time_punch_out:
                                                  myval.punch_time_out,
                                                total_work_time:
                                                  e.total_work_time == null ? 0 : e.total_work_time,
                                                total_break_time:
                                                  e.total_break_time != null ?  e.total_break_time : 0,
                                              }
                                              return (
                                                <div key={index}>
                                                    <Link
                                                  state={{
                                                    obj : obj,
                                                    created_at: myval.created_at,
                                                  }}
                                                  
                                                  to={`../../timesheet/view/`}>{e.first_name} {e.last_name}</Link>
                                                  <p>{e.job_title}</p>
                                                  <span
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Clock In{" "}
                                                    {formatTimeWithAmPm(
                                                      `${myval.punch_time_in}`
                                                    )}
                                                  </span>
                                                  <br />
                                                  {myval.punch_time_out ? (
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Clock Out{" "}
                                                      {formatTimeWithAmPm(
                                                        `${myval.punch_time_out}`
                                                      )}
                                                    </span>
                                                  ) : null}
                                                </div>
                                              )
                                           
                                            }
                                            }else  if(selectedLocation =='All Location'){
                                              if (e.emp_id ==   myval.emp_id) {
                                                const obj = {
                                                  status: 'present',
                                                  data: e,
                                                  id: e.emp_id,
                                                  created_at: myval.created_at,
                                                  attendance_id : myval.id,
                                                  time_punch_in:
                                                    myval.punch_time_in,
                                                  time_punch_out:
                                                    myval.punch_time_out,
                                                  total_work_time:
                                                    e.total_work_time == null ? 0 : e.total_work_time,
                                                  total_break_time:
                                                    e.total_break_time != null ?  e.total_break_time : 0,
                                                }
                                                return (
                                                  <div key={index}>
                                                      <Link
                                                    state={{
                                                      obj : obj,
                                                      created_at: myval.created_at,
                                                    }}
                                                    
                                                    to={`../../timesheet/view/`}>{e.first_name} {e.last_name}</Link>
                                                    <p>{e.job_title}</p>
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Clock In{" "}
                                                      {formatTimeWithAmPm(
                                                        `${myval.punch_time_in}`
                                                      )}
                                                    </span>
                                                    <br />
                                                    {myval.punch_time_out ? (
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Clock Out{" "}
                                                        {formatTimeWithAmPm(
                                                          `${myval.punch_time_out}`
                                                        )}
                                                      </span>
                                                    ) : null}
                                                  </div>
                                                )
                                             
                                              }
                                              }
                                            
                                            else{
                                              if (e.emp_id ==   myval.emp_id) {
                                                return (
                                                  <div key={index}>
                                                   {/* <p>{JSON.stringify(myval)}</p> */}
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Clock In{" "}
                                                      {formatTimeWithAmPm(
                                                        `${myval.punch_time_in}`
                                                      )}
                                                    </span>
                                                    <br />
                                                    {myval.punch_time_out ? (
                                                      <span
                                                        style={{
                                                          fontSize: 12,
                                                          fontWeight: "bold",
                                                        }}
                                                      >
                                                        Clock Out{" "}
                                                        {formatTimeWithAmPm(
                                                          `${myval.punch_time_out}`
                                                        )}
                                                      </span>
                                                    ) : null}
                                                  </div>
                                                )
                                             
                                              } 
                                            } if(currentUserRole[0] == 'admin'){
                                          if (e.id ==   myval.emp_id) {
                                            return (
                                              <div key={index}>
                                               {/* <p>{JSON.stringify(myval)}</p> */}
                                                <span
                                                  style={{
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                  }}
                                                >
                                                  Clock In{" "}
                                                  {formatTimeWithAmPm(
                                                    `${myval.punch_time_in}`
                                                  )}
                                                </span>
                                                <br />
                                                {myval.punch_time_out ? (
                                                  <span
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Clock Out{" "}
                                                    {formatTimeWithAmPm(
                                                      `${myval.punch_time_out}`
                                                    )}
                                                  </span>
                                                ) : null}
                                              </div>
                                            )
                                         
                                          }
                                          }else{
                                            if (e.emp_id ==   myval.emp_id) {
                                              return (
                                                <div key={index}>
                                                 {/* <p>{JSON.stringify(myval)}</p> */}
                                                  <span
                                                    style={{
                                                      fontSize: 12,
                                                      fontWeight: "bold",
                                                    }}
                                                  >
                                                    Clock In{" "}
                                                    {formatTimeWithAmPm(
                                                      `${myval.punch_time_in}`
                                                    )}
                                                  </span>
                                                  <br />
                                                  {myval.punch_time_out ? (
                                                    <span
                                                      style={{
                                                        fontSize: 12,
                                                        fontWeight: "bold",
                                                      }}
                                                    >
                                                      Clock Out{" "}
                                                      {formatTimeWithAmPm(
                                                        `${myval.punch_time_out}`
                                                      )}
                                                    </span>
                                                  ) : null}
                                                </div>
                                              )
                                           
                                            } 
                                          }
                                         }
                                        })}
                                      </p>
                                    </div>
                                  </div>
                                )
                              })):<Spinner color="info"/>}
                            </div>
                          </TabPane>
                          <TabPane tabId="absent">
                            <Input
                              value={searchQuery}
                              onChange={handleSearchChange}
                              id="search-user"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                            <br />
                            <div>
                              {filteredData.length === 0 ? (
                                <p>No record found</p>
                              ) : null}
                              {
                               showLoader == false ?(
                              filteredData.map((e, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: 10,
                                    }}
                                    key={index}
                                  >
                                    <div>
                                      <img
                                        src={e.profile_image}
                                        style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 100,
                                        }}
                                      />

                                      
                                    </div>
                                    <div style={{ justifyContent: "center" }}>
                                      <p>
                                      <Link to={`../../people/members/view/${selectedLocation == 'All Location' ?e.emp_id :  e.id }`}>{e.first_name} {e.last_name}</Link>
                                      <p>{e.job_title}</p>
                                      </p>
                                    </div>
                                  </div>
                                )
                              })):<Spinner color="info"/>}
                            </div>
                          </TabPane>
                          <TabPane tabId="leave">
                            <Input
                              // onKeyUp={searchUsers}
                              id="search-user"
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                            />
                            <i className="bx bx-search-alt search-icon" />
                            <br />
                            <div>
                              {filteredData.length === 0 ? (
                                <p>No record found</p>
                              ) : null}
                              {
                               showLoader == false ?(
                              filteredData.map((e, index) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      columnGap: 10,
                                    }}
                                    key={index}
                                  >
                                    <div>
                                      <img
                                        src={e.profile_image}
                                        style={{
                                          width: 40,
                                          height: 40,
                                          borderRadius: 100,
                                        }}
                                      />

                                      <p>{e.role}</p>
                                    </div>
                                    <div style={{ justifyContent: "center" }}>
                                      <p>
                                      <Link to={`../../people/members/view/${e.id}`}>{e.first_name} {e.last_name}</Link>
                                      <p>{e.job_title}</p>
                                      </p>
                                    </div>
                                  </div>
                                )
                              })):<Spinner color="info"/>}
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                </Col>
                <Col md={12}>
                <Card>
                    <CardBody>
                      <CardTitle>Tasks</CardTitle>
                      <div id="doughnut-chart" className="e-chart">
                        <Tasks dataColors='["--bs-primary", "--bs-success"]' />
                      </div>
                    </CardBody>
                  </Card>

                </Col>
                </Row>
                {/* <Col lg="4"> */}
                                
              </Col>
            ) : null}

          </Row>
        </Container>
        <Modal
                      isOpen={
                        JSON.parse(localStorage.getItem('authUser')).map((e)=>e.terminate_status) != '' ?
                        true: false
                      }
                      toggle={() => {
                        tog_center();
                      }}
                      centered
                    >
                      <div className="modal-header bg-danger">
                        <h5 className="modal-title mt-0 text-white">Terminate Alert</h5>
                       
                      </div>
                      <div className="modal-body">
                        <p>{
                           JSON.parse(localStorage.getItem('authUser')).map((e)=>e.terminate_status)
                          }</p>
                        <p>{
                           formatDateByMe(JSON.parse(localStorage.getItem('authUser')).map((e)=>e.terminate_date))
                          }</p>
                        <p>
                          {
                            JSON.parse(localStorage.getItem('authUser')).map((e)=>e.terminate_message)
                          }
                        </p>
                     
                      </div>
                    </Modal>
        <Modal
                      isOpen={comeFirstTimeOnScreen}
                      backdrop='static'
                      centered
                    >
                      <div className="modal-header bg-info">
                        <h5 className="modal-title mt-0 text-white">Hi, {currentUserName}</h5>
                       
                      </div>
                      <div className="modal-body">
                        <Row>
                         <Col md={12}>
                         <h4>Welcome To Track Presence</h4>
                         </Col>
                         <Col md={12}>
                          <p>We really apperaiate on your choose you will enjoy to manage your employee record</p>
                          <h5 className="mt-3 mb-3">Key Features</h5>
                         
                            <p className="feature_list_before">Attendance Management</p>
                            <p className="feature_list_before">TimeSheet Management</p>
                            <p className="feature_list_before">Live Tracking</p>
                            <p className="feature_list_before">Time Off</p>
                            <p className="feature_list_before">Payroll</p>
                          
                         </Col>
                         <Col md={12} className="mt-3 text-end" >
                           <button className="btn btn-info"
                           style={{marginRight:5}}
                           onClick={()=>{
                            setIsTourOpen(true)
                            localStorage.setItem('ComesFirstTimeOnScreen' , 'value_is_set');
                            setEditComeFirstTimeOnScreen(false)
                           }}>
                            Start Tour
                          </button>
                           <button  className="btn btn-primary" onClick={()=>{
                            localStorage.setItem('ComesFirstTimeOnScreen' , 'value_is_set');
                            setEditComeFirstTimeOnScreen(false)
                           }}>
                            Maybe Later
                          </button>
                         </Col>
                        </Row>
                     
                      </div>
                    </Modal>
      </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
  google: PropTypes.object,
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyAIiEgVMMUA5gil8H1mRK3y7WrMcghm8jY",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(Dashboard)
)

//  withTranslation()(Dashboard)
