import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { Link } from "react-router-dom";

const PieChartCustom = ({dataFor,dataColors}) => {
  const PieApexChartColors = getChartColorsArray(dataColors);
  
  const series = [parseFloat(dataFor.active),parseFloat(dataFor.overdue),parseFloat(dataFor.completed)]
  const options = {
    chart: {
      height: 320,
      type: 'pie',
      events: {
        dataPointSelection: (event, chartContext, config) => { 
            if(config.w.config.labels[config.dataPointIndex] == 'Active'){
                window.location.href = './tasks/active';
            }else if(config.w.config.labels[config.dataPointIndex] == 'Overdue'){
                window.location.href = './tasks/overdue';
            }
        }, 
     },
    },
    series: [parseFloat(dataFor.active),parseFloat(dataFor.overdue),parseFloat(dataFor.completed)][dataFor.active,dataFor.overdue,dataFor.completed],
    labels: ["Active","Overdue", "Completed" ],
    colors: PieApexChartColors,
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      verticalAlign: 'middle',
      floating: false,
      fontSize: '14px',
      offsetX: 0,
    },
    responsive: [{
      breakpoint: 600,
      options: {
        chart: {
          height: 240
        },
        legend: {
          show: false
        },
      }
    }]
  }

  return (
    <div>
    <div style={{paddingBottom: 10}} className="text-center">
      <Link to='../tasks/active'>
        <div
            className={`badge bg-success`}
            style={{
              cursor: "pointer",
            }}
          >
            <div>
              <span>{`Active: ${dataFor.active}`}</span>
            </div>
          </div>
      </Link>
      <Link style={{marginLeft: 5, marginRight: 5}} to='../tasks/overdue'>
        <div
            className={`badge bg-warning`}
            style={{
              cursor: "pointer",
            }}
          >
            <div>
              <span>{`Overdue: ${dataFor.overdue}`}</span>
            </div>
          </div>
      </Link>
      <Link to='../tasks/completed'>
        <div
            className={`badge bg-info`}
            style={{
              cursor: "pointer",
            }}
          >
            <div>
              <span>{`Completed: ${dataFor.completed}`}</span>
            </div>
          </div>
      </Link>
    </div>
    <ReactApexChart options={options} series={series} type="pie" height="320" />
    </div>
    )
}

export default PieChartCustom
