import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';
const firebaseConfig = {
    apiKey: "AIzaSyA_6M_8PIdIldfei5f9KTn5B8qrBooXl90",
    authDomain: "big-coil-356918.firebaseapp.com",
    databaseURL: "https://big-coil-356918-default-rtdb.firebaseio.com",
    projectId: "big-coil-356918",
    storageBucket: "big-coil-356918.appspot.com",
    messagingSenderId: "264726020315",
    appId: "1:264726020315:web:b96f671f4c58858e35ccfe",
    measurementId: "G-GJQQT1XD90"
  };



const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };