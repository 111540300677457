import React, { useEffect, useState, useMemo } from "react"
import PropTypes, { array } from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
  Modal,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"

import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import Switch from "react-switch"
import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./MembersData"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import { Link } from "react-router-dom"
import ImageWithOrientation from "components/Image/ImageWithOrientation"
import Swal from "sweetalert2"
import {
  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridColDef,
} from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import { ArchiveRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded, ThreePRounded } from "@mui/icons-material"
const Timeoff = props => {
  //meta title
  document.title = `Employees | ${process.env.REACT_APP_NAME}`
  function getCurrentDate() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0") // January is 0!
    const yyyy = today.getFullYear()

    return `${yyyy}-${mm}-${dd}`
  }
  let firstDateIwant = getCurrentDate()
  const [orders, onGetOrders] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [startDate, setStartDate] = useState(new Date(firstDateIwant))
  const [selectCoin, setselectCoin] = useState(null)
  const [selectType, setselectType] = useState("Buy")
  const [selectStatus, setselectStatus] = useState("Completed")
  const [productData, setSetProductData] = useState([orders])
  const [mockData2, setmockData2] = useState([])
  const [mockData, setmockData] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [avatarSrc, setAvatarSrc] = useState("")
  const [switch1, setswitch1] = useState(false)
  const [addMemberModal, setaddMemberModal] = useState(false)
  const [addEmployeeStatus, setaddEmployeeStatus] = useState(false)
  const [markedAttendance, setmarkedAttendance] = useState(false)
  const [reviewLeaves, setreviewLeaves] = useState(false)
  const [ByManual, setByManual] = useState(false)
  const [trackStatus, settrackStatus] = useState(false)
  const [ByFaceRecong, setByFaceRecong] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [addDepartmentModal, setaddDepartmentModal] = useState(false)
  const [payrollType, setpayrollType] = useState(null)
  const [selectWorkModal, setSelectWorkModal] = useState(null)
  const [payrollRate, setpayrollRate] = useState(null)
  const [payrollCurrency, setpayrollCurrency] = useState("USD")
  const [tableLoader, setTableLoader] = useState(true)
  const [supervisorArray, setsupervisorArray] = useState([])
  const [separatedEmp, setSepratedEmp] = useState([])
  const [mockDataOther, setmockDataOther] = useState([])

  const getAllActivities = async () => {
    // const value = await JSON.parse(AsyncStorage.getItem('@loginSession')).map((e)=>e.org_id);
    // alert(value)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/departments.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        result.map(e => {
          let val = {
            value: e.name,
            label: e.name,
          }
          mockData.push(val)
        })
      })
      .catch(error => console.log("error", error))
  }

  const fetchCompaineName = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/company.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.warn(result)
        if (result.length !== 0) {
          result.map(e => {
            let val = {
              value: e.location,
              label: e.location,
            }
            mockDataOther.push(val)
          })
        }
      })
      .catch(error => console.log("error", error))
  }
  function filterAndCountEmploymentStatus(dataArray, department) {
    // Filter the array based on job_department
    const filteredData = dataArray.filter(item => item.job_department === department);

    // Initialize an object to store employment status counts
    const employmentStatusCounts = {};

    // Count occurrences of each employement_status
    filteredData.forEach(item => {
        const status = item.employement_status;
        if (status in employmentStatusCounts) {
            employmentStatusCounts[status]++;
        } else {
            employmentStatusCounts[status] = 1;
        }
    });

    // Convert counts to an array of objects
    const result = Object.entries(employmentStatusCounts).map(([status, count]) => ({
        name: status,
        value: count
    }));

    return result;
}
  const fetchEmployee = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "fetch_employees-desktop")

    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.length == 0) {
          setTableLoader(false)
        }
        onGetOrders(result.reverse())
      })
      .catch(error => console.log("error", error))
  }
  const fetchSeparatedEmployee = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "fetch_employees-desktop-seperated")

    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setSepratedEmp(result.reverse())
        if(result.length == 0){
          setTableLoader(false)
        }
      })
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    fetchEmployee()
    fetchCompaines()
    getAllActivities()
    fetchDepartment()
    fetchCompaineName()
  }, [])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      if (tab == "1") {
        fetchEmployee()
      } else {
        fetchSeparatedEmployee()
      }
      setActiveTab(tab)
    }
  }

  const handleFilter = () => {
    var allProduct = orders.filter(order => {
      return (
        order.type === selectType &&
        order.coin === selectCoin &&
        order.status === selectStatus
      )
    })
    setSetProductData(allProduct)
  }
  function formatDateHiring(inputDate) {
    // Split the input date string into month, day, and year parts
    var parts = inputDate.split("/")
    var month = parseInt(parts[0], 10)
    var day = parseInt(parts[1], 10)
    var year = parseInt(parts[2], 10)

    // Create a Date object using the parsed values
    var date = new Date(year, month - 1, day) // Subtracting 1 from month since it's zero-indexed

    // Get the day and month names
    var dayName = date.getDate()
    var monthName = date.toLocaleString("default", { month: "short" })

    // Format the date as 'day MonthName year'
    var formattedDate = dayName + " " + monthName + " " + year

    return formattedDate
  }

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }))

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          No Employee found
        </Box>
      </StyledGridOverlay>
    )
  }
  const columnsFor: GridColDef[] = [
    {
      field: "profile_image",
      headerName: "Image",
      width: 120,
      renderCell: param => (
        <img
          src={param.value}
          className="avatar-xs rounded-circle"
          style={{ objectFit: "cover" }}
        />
      ),
    },
    {
      field: "id",
      headerName: "Emp ID",
      width: 150,
      renderCell: param => <p>{param.value}</p>,
    },
    { field: "first_name", headerName: "Full Name", width: 150 },
    { field: "job_title", headerName: "Designation", width: 150 },
    { field: "job_department", headerName: "Department", width: 150 },
    { field: "super_name", headerName: "Supervisor Name", width: 150 },
    activeTab  == "1"?  { field: "employement_status", headerName: "Emp-Status", width: 150 } : { field: "terminate_status", headerName: "Terminate Status", width: 150 },
    activeTab  == "1"?  
    {
      field: "hiring_date",
      headerName: "Hiring Date",
      width: 100,
      renderCell: param => <p>{formatDateHiring(param.value)}</p>,
    }:
    {
      field: "clearance_date",
      headerName: "Clearance Date",
      width: 100,
      renderCell: param => <p>{param.value}</p>,
    },
    {
      field: "",
      headerName: "Actions",
      width: 150,
      renderCell: param => {
        return (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link
                to={`view/${param.row.id}`}
                className="btn btn-sm btn-soft-primary"
              >
                <ThreePRounded id="viewtooltip" />
              </Link>
            </li>
            <UncontrolledTooltip placement="top" target="viewtooltip">
              View
            </UncontrolledTooltip>
            <li>
              <Link
                to="#"
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  tog_center()
                  fetchEmployeeSingleById(param.row.id)
                }}
              >
                <ArchiveRounded id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Separtion
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        )
      },
    },
  ]

  const fetchCompaines = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/company.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.length !== 0) {
          result.map(e => {
            let val = {
              value: e.location,
              label: e.location,
            }
            mockData2.push(val)
          })
        } else {
          alert("Please add a location. Before add employee")
        }
      })
      .catch(error => console.log("error", error))
  }

  // Function to handle file selection
  const handleFileChange = event => {
    const file = event.target.files[0] // Get the first selected file
    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        setAvatarSrc(e.target.result) // Update the image source with the file data
      }
      reader.readAsDataURL(file) // Read the file as a data URL
    }
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const handleCheckboxChange = () => {
    settrackStatus(!trackStatus) // Toggle the trackStatus state
  }

  const fetchSupervisorFor = async val => {
    const value = localStorage.getItem("authUser")
    let jsonFormat = JSON.parse(value)
    let org = jsonFormat.map(e => e.org_id)
    var formdata = new FormData()

    formdata.append("method", "select-for-supervisor")
    formdata.append("org_id", org)
    formdata.append("location_for", val)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    console.error(formdata)
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setsupervisorArray(result)
      })
      .catch(error => console.log("error", error))
  }
  const onChangeLocation = val => {
    fetchSupervisorFor(val.value)
  }

  const [StatusForPayRollNew, setStatusForPayRollNew] = useState("")
  const [departmentsList, setDepartmentList] = useState([])
  const [editIdFor, setEditIdFor] = useState("")
  const runthisForaLittleFunction = val => {
    setStatusForPayRollNew(val + " to " + val)
  }
  const fetchDepartment = async () => {
    let local = localStorage.getItem("authUser")
    let mapsing = JSON.parse(local)
    let org = mapsing.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/departments.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setDepartmentList(result)
      })
      .catch(error => console.log("error", error))
  }

  let arrayForMe = []
  for (let i = 1; i <= 31; i++) {
    arrayForMe.push({
      label: i,
      value: i,
    })
  }
  const [modal_center, setmodal_center] = useState(false)
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const [infoData, setInfoData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    email_address: "",
    phone_code: "",
    phone_number: "",
    job_location: { label: "", value: "" },
    department: { label: "", value: "" },
    hiring_date: "",
    super_id: { label: "", value: "" },
    terminate_date: "",
    terminated_type: "",
    terminate_reason: "",
    terminate_reason_message: "",
    terminated_comments: "",
    date_for_clearance: "",
  })

  const fetchEmployeeSingleById = async passingId => {
    setEditIdFor(passingId)
    var formdata = new FormData()
    formdata.append("method", "fetch_employees_by_id")
    formdata.append("post_id", passingId)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.length !== 0) {
          result.map(e => {
            if (e.job_info.length != 0) {
              e.job_info.map(einner => {
                let phn_code = null
                let phn_number = null
                if (e.phone_number != null) {
                  let array = e.phone_number.split("|")
                  phn_code = array[0]
                  phn_number = array[1]
                }
                setInfoData({
                  first_name: e.first_name,
                  phone_code: phn_code,
                  phone_number: phn_number,
                  email_address: e.email_address,
                  last_name: e.last_name,
                  user_name: e.username,
                  department: {
                    label: einner.job_category,
                    value: einner.job_category,
                  },
                  job_location: {
                    label: einner.location,
                    value: einner.location,
                  },
                  hiring_date: einner.joing_date,
                  super_id: { label: e.super_id, value: e.super_id },
                  terminate_date: einner.terminate_date,
                  terminated_comments: einner.terminate_message,
                  terminated_type: einner.terminate_status,
                  date_for_clearance: einner.clearance_date,
                  terminate_reason: einner.terminate_reason,
                  terminate_reason_message: einner.t_reason_message,
                })
              })
            }
          })
        }
      })
      .catch(error => console.log("error", error))
  }

  function getCurrentDate() {
    // Get current date
    const currentDate = new Date()

    // Extract year, month, and day
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are zero-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, "0")

    // Format the date as 'YYYY-MM-DD'
    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
  }
  function generateRandomColorName() {
    // Array of color names
    const colorNames = ['info','primary','success','danger'];

    // Generate a random index to select a color name from the array
    const randomIndex = Math.floor(Math.random() * colorNames.length);

    // Get the randomly selected color name
    const randomColorName = colorNames[randomIndex];

    return randomColorName;
}
const [showDetails, setShowDetails] = useState({});
const toggleDetails = (index) => {
  setShowDetails(prevState => ({
      ...prevState,
      [index]: !prevState[index]
  }));
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="12">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Employees
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Separted Employee
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1">
                      <div className="mb-3">
                        <Button
                          type="button"
                          color="primary"
                          className="w-md"
                          onClick={() => setaddMemberModal(true)}
                        >
                          Add Employee
                        </Button>
                      </div>
                      {/* <p>{JSON.stringify(departmentsList)}</p> */}
                      {/* <p>{JSON.stringify(orders)}</p> */}
                      <Row>
                      {
                        departmentsList.map((department, index) => {
                            let counter = 0;
                            orders.forEach((order) => {
                                if (order.job_department === department.name) {
                                    counter++;
                                }
                            });
                            let employmentStatusPromise = filterAndCountEmploymentStatus(orders, department.name);
                            let color = generateRandomColorName()
                              return (
                                <Col lg={3} key={index}>
                                <Card  className="blog-stats-wid" style={{borderWidth:1,borderColor:'#c0c0c0'}}>
                                  <CardBody >
                                 
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2" style={{fontWeight:'700'}}>{department.name}</p>
                                        
                                         
                                        <p style={{cursor:'pointer'}} onClick={() => toggleDetails(index)}>{showDetails[index]  ?  'Hide Details' : 'Show Details'} {showDetails[index]  ?  <KeyboardArrowUpRounded/> : <KeyboardArrowDownRounded/>}</p>
                                            {showDetails[index] && (
                                                <div>
                                                    {employmentStatusPromise.map((e, innerIndex) => (
                                                        <p key={innerIndex}>
                                                            {e.name} &nbsp;&nbsp; <Badge color={color}>{e.value}</Badge>
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                      </div>
                    
                                      <div className="avatar-sm ms-auto">
                                        <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                                          {/* <i className="bx bxs-note"></i> */}
                                          <h5 className="mb-0" style={{fontSize:18,fontWeight:'bold',color:'#212529bf'}}>{counter}</h5>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </CardBody>
                                </Card>
                              </Col>
                              );
                          })
                      }
                      </Row>
                      <Box sx={{ width: "100%", height: "100%" }}>
                        <DataGrid
                          autoHeight
                          sx={{ "--DataGrid-overlayHeight": "300px" }}
                          slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: CustomNoRowsOverlay,
                          }}
                          rows={orders}
                          loading={
                            orders.length === 0 && tableLoader === true
                              ? true
                              : false
                          }
                          columns={columnsFor}
                        />
                      </Box>

                      <Modal
                        isOpen={modal_center}
                        toggle={() => {
                          tog_center()
                        }}
                        centered
                      >
                        <div className="modal-header">
                          <h5 className="modal-title mt-0">Mark Separation</h5>
                          <button
                            type="button"
                            onClick={() => {
                              setmodal_center(false)
                            }}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <Row>
                            <Col md={12}>
                              <Form
                                onSubmit={e => {
                                  e.preventDefault()
                                  if (
                                    e.target.elements.separation_type.value ==
                                    "null"
                                  ) {
                                    toastr.error("Select the separation type")
                                    return false
                                  } else if (
                                    e.target.elements.speration_date.value == ""
                                  ) {
                                    toastr.error("Separation date is required")
                                    return false
                                  } else {
                                    // let local =  localStorage.getItem('authUser');
                                    // let jsonFormat = JSON.parse(local);
                                    // const uid = jsonFormat.map((e)=>e.user_id);
                                    let form = new FormData()
                                    form.append(
                                      "type",
                                      e.target.elements.separation_type.value
                                    )
                                    form.append(
                                      "reason_type",
                                      e.target.elements.reason_type.value
                                    )
                                    form.append(
                                      "reason_message",
                                      e.target.elements.reason_comments.value
                                    )
                                    form.append(
                                      "separation_date",
                                      e.target.elements.speration_date.value
                                    )
                                    form.append(
                                      "clearance_date",
                                      e.target.elements.clearance_date.value
                                    )
                                    form.append(
                                      "comments",
                                      e.target.elements.comments.value
                                    )
                                    form.append("user_for", editIdFor)
                                    form.append("method", "mark-separation")
                                    var requestOptions = {
                                      method: "POST",
                                      body: form,
                                      redirect: "follow",
                                    }
                                    fetch(
                                      process.env.REACT_APP_LEAVE_API_URL +
                                        "/login.php",
                                      requestOptions
                                    )
                                      .then(response => response.text())
                                      .then(result => {
                                        if (result == "success") {
                                          Swal.fire(
                                            "Success",
                                            "You successfully mark the employee separation"
                                          )
                                          fetchEmployee()
                                        } else {
                                          Swal.fire(
                                            `Server Error`,
                                            `Check your input fields`
                                          )
                                        }
                                      })
                                      .catch(error =>
                                        console.log("error", error)
                                      )
                                  }
                                }}
                              >
                                <Row>
                                  <Col md={12} className="mb-3">
                                    <Label>Select Separation Type</Label>

                                    <Select
                                      className="select2-selection"
                                      name="separation_type"
                                      value={{
                                        label: infoData.terminated_type,
                                        value: infoData.terminated_type,
                                      }}
                                      onChange={e =>
                                        setInfoData(prev => ({
                                          ...prev,
                                          terminated_type: e.label,
                                        }))
                                      }
                                      options={[
                                        {
                                          label: "Terminate",
                                          value: "Terminate",
                                        },
                                        {
                                          label: "Laid off",
                                          value: "Laid off",
                                        },
                                        {
                                          label: "Resigned",
                                          value: "Resigned",
                                        },
                                      ]}
                                    />
                                  </Col>
                                  <Col md={12} className="mb-3">
                                    <Label>Select Reason</Label>

                                    <Select
                                      className="select2-selection"
                                      name="reason_type"
                                      value={{
                                        label: infoData.terminate_reason,
                                        value: infoData.terminate_reason,
                                      }}
                                      onChange={e =>
                                        setInfoData(prev => ({
                                          ...prev,
                                          terminate_reason: e.label,
                                        }))
                                      }
                                      options={[
                                        {
                                          label: "Personal",
                                          value: "Personal",
                                        },
                                        {
                                          label: "Marriage",
                                          value: "Marriage",
                                        },
                                        {
                                          label: "Better Opportunity",
                                          value: "Better Opportunity",
                                        },
                                      ]}
                                    />
                                  </Col>
                                  <Col md={12} className="mb-3">
                                    <Label>Reason Comments</Label>

                                    <Input
                                      className="form-control"
                                      type="textarea"
                                      placeholder="Comments...."
                                      name="reason_comments"
                                      value={infoData.terminate_reason_message}
                                      onChange={e =>
                                        setInfoData(prev => ({
                                          ...prev,
                                          terminate_reason_message:
                                            e.target.value,
                                        }))
                                      }
                                    />
                                  </Col>
                                  <Col md={12} className="mb-3">
                                    <Label>Select Date</Label>
                                    <input
                                      className="form-control"
                                      min={getCurrentDate()}
                                      name="speration_date"
                                      value={infoData.terminate_date}
                                      onChange={e =>
                                        setInfoData(prev => ({
                                          ...prev,
                                          terminate_date: e.target.value,
                                        }))
                                      }
                                      placeholder="Select Date"
                                      type="date"
                                    />
                                  </Col>
                                  <Col md={12} className="mb-3">
                                    <Label>Comments</Label>
                                    <Input
                                      className="form-control"
                                      name="comments"
                                      value={infoData.terminated_comments}
                                      onChange={e =>
                                        setInfoData(prev => ({
                                          ...prev,
                                          terminated_comments: e.target.value,
                                        }))
                                      }
                                      placeholder="Write some comments"
                                      type="textarea"
                                    />
                                  </Col>
                                  <Col md={12} className="mb-3">
                                    <Label>Select Clearance Date</Label>
                                    <input
                                      className="form-control"
                                      name="clearance_date"
                                      placeholder="Select Date"
                                      min={getCurrentDate()}
                                      value={infoData.date_for_clearance}
                                      onChange={e =>
                                        setInfoData(prev => ({
                                          ...prev,
                                          date_for_clearance: e.target.value,
                                        }))
                                      }
                                      type="date"
                                    />
                                  </Col>

                                  <Col md={12} style={{ paddingTop: 20 }}>
                                    <button
                                      className="btn btn-primary"
                                      type="submit"
                                    >
                                      Save Changes
                                    </button>
                                  </Col>
                                </Row>
                              </Form>
                            </Col>
                          </Row>
                        </div>
                      </Modal>
                    </TabPane>
                    <TabPane tabId="2">
                      {/* <Form>
                        <Row className="mb-4">
                          <Col sm={6} className="col-xl">
                            <FormGroup className="mb-3">
                              <Label className="form-label">Schedule</Label>
                              <select
                                className="form-control select2-search-disable"
                                value={selectCoin}
                                onChange={e => {
                                  setselectCoin(e.target.value)
                                }}
                              >
                                <option value="Bitcoin">Unassigned</option>
                              </select>
                            </FormGroup>
                          </Col>

                          <Col sm={6} className="col-xl">
                            <FormGroup className="mb-3">
                              <Label className="form-label">
                                Holidays Calendars
                              </Label>
                              <select
                                className="form-control select2-search-disable"
                                value={selectStatus}
                                onChange={e => {
                                  setselectStatus(e.target.value)
                                }}
                              >
                                <option value="Completed">Unassigned</option>
                              </select>
                            </FormGroup>
                          </Col>

                          <Col sm={6} className="col-xl align-self-end ">
                            <div className="mb-3">
                              <Button
                                type="button"
                                color="primary"
                                className="w-md"
                                onClick={() => handleFilter()}
                              >
                                Create Group
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form> */}

                      <Box sx={{ width: "100%", height: "100%" }}>
                        <DataGrid
                          autoHeight
                          sx={{ "--DataGrid-overlayHeight": "300px" }}
                          slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: CustomNoRowsOverlay,
                          }}
                          rows={separatedEmp}
                          loading={
                            separatedEmp.length == 0 && tableLoader == true
                              ? true
                              : false
                          }
                          columns={columnsFor}
                        />
                      </Box>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Offcanvas
          style={{
            width: 600,
          }}
          isOpen={addMemberModal}
          direction="end"
          toggle={() => setaddMemberModal(!addMemberModal)}
        >
          <OffcanvasHeader toggle={() => setaddMemberModal(!addMemberModal)}>
            {/* <h4>Add Member</h4> */}
            Add Employee
            <p style={{ fontSize: 14, fontWeight: "normal" }}>
              Provide the valid information. Before hit save button
            </p>
          </OffcanvasHeader>
          <OffcanvasBody>
            <Container>
              <Form
                onSubmit={async e => {
                  e.preventDefault()

                  const fileInput = document.querySelector('input[type="file"]')
                  const file = fileInput.files[0]

                  if (file == undefined) {
                    toastr.error("Please select the profile image")

                    return false
                  } else if (e.target.elements.first_name.value == "") {
                    toastr.error("First name is required")
                    return false
                  } else if (e.target.elements.last_name.value == "") {
                    toastr.error("Last name is required")
                    return false
                  } else if (e.target.elements.username.value == "") {
                    toastr.error("Username is required")
                    return false
                  } else if (e.target.elements.password.value == "") {
                    toastr.error("Password is required")
                    return false
                  } else if (e.target.elements.pin.value == "") {
                    toastr.error("PIN is required")
                    return false
                  } else if (e.target.elements.select_role.value == "") {
                    toastr.error("Select the role")
                    return false
                  } else if (e.target.elements.department.value == "") {
                    toastr.error("Select the department")
                    return false
                  } else if (e.target.elements.location.value == "") {
                    toastr.error("Select the Location")
                    return false
                  } else if (e.target.elements.employement_status.value == "") {
                    toastr.error("Select the Employement Status")
                    return false
                  } else if (e.target.elements.payroll_date.value == "") {
                    toastr.error("Select the Payroll date")
                    return false
                  } else if (switch1 == true) {
                    if (e.target.elements.extra_hours.value == "") {
                      toastr.error("Add the extra hours")
                      return false
                    } else {
                      setisLoading(true)
                      let form = new FormData()
                      let localVar = localStorage.getItem("authUser")
                      let localArray = JSON.parse(localVar)
                      let org = localArray.map(e => e.org_id)
                      form.append("method", "register_user")
                      form.append(
                        "first_name",
                        e.target.elements.first_name.value
                      )
                      form.append(
                        "last_name",
                        e.target.elements.last_name.value
                      )
                      form.append(
                        "department",
                        e.target.elements.department.value
                      )
                      form.append("area", e.target.elements.location.value)
                      const dd = String(startDate.getDate()).padStart(2, "0")
                      const mm = String(startDate.getMonth() + 1).padStart(
                        2,
                        "0"
                      ) // January is 0!
                      const yyyy = startDate.getFullYear()
                      let datetosend = `${mm}/${dd}/${yyyy}`
                      form.append("hire_date", datetosend)
                      form.append("other_method", "isLogin")
                      form.append("username", e.target.elements.username.value)
                      form.append("password", e.target.elements.password.value)
                      form.append("pin", e.target.elements.pin.value)
                      form.append("org_id", org)
                      form.append("role", selectedRole)
                      form.append("status", "Enabled")
                      form.append("shift_Start", "null")
                      form.append("shift_End", "null")
                      form.append("hours_status", switch1)
                      form.append(
                        "no_of_hours",
                        switch1 === true
                          ? e.target.elements.extra_hours.value
                          : null
                      )
                      form.append("leave_right", reviewLeaves)
                      form.append("tracking_status", trackStatus)
                      form.append("addEmployeeRight", addEmployeeStatus)
                      form.append("markAttendanceRight", markedAttendance)
                      form.append("byManual", ByManual)
                      form.append("byFaceRecong", ByFaceRecong)

                      form.append("profileimage", file)
                      form.append("payrollType", payrollType)
                      form.append(
                        "employement_status",
                        e.target.elements.employement_status.value
                      )

                      form.append(
                        "payroll_cycle_date",
                        e.target.elements.payroll_date.value
                      )

                      form.append(
                        "payrollRate",
                        e.target.elements.payroll_rate.value
                      )
                      form.append(
                        "payrollCurrency",
                        e.target.elements.currency_payroll.value
                      )
                      form.append(
                        "overTimeRate",
                        switch1 === true
                          ? e.target.elements.overtime_rate.value
                          : null
                      )
                      form.append(
                        "designation_for",
                        e.target.elements.designation.value
                      )
                      form.append(
                        "super_id",
                        selectedRole == "user"
                          ? e.target.elements.supervisor_id.value
                          : null
                      )
                      try {
                        await axios
                          .post(
                            process.env.REACT_APP_LEAVE_API_URL + "/login.php",
                            form,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                          .then(result => {
                            setisLoading(false)
                            if (result.data == "success") {
                              toastr.success("Employee is added")
                              fetchEmployee()
                              setaddMemberModal(false)
                            } else {
                              toastr.error(`${result.data}`)
                              setisLoading(false)
                              return false
                            }
                          })
                          .catch(er => {
                            console.log(er)
                            setisLoading(false)
                          })
                      } catch (error) {
                        console.error("Error:", error)
                      }
                    }
                  } else {
                    setisLoading(true)
                    let localVar = localStorage.getItem("authUser")
                    let localArray = JSON.parse(localVar)
                    let org = localArray.map(e => e.org_id)
                    let form = new FormData()
                    form.append("method", "register_user")
                    form.append(
                      "first_name",
                      e.target.elements.first_name.value
                    )
                    form.append("last_name", e.target.elements.last_name.value)
                    form.append(
                      "department",
                      e.target.elements.department.value
                    )
                    form.append("area", e.target.elements.location.value)
                    const dd = String(startDate.getDate()).padStart(2, "0")
                    const mm = String(startDate.getMonth() + 1).padStart(2, "0") // January is 0!
                    const yyyy = startDate.getFullYear()
                    let datetosend = `${mm}/${dd}/${yyyy}`
                    form.append("hire_date", datetosend)
                    form.append("other_method", "isLogin")
                    form.append("username", e.target.elements.username.value)
                    form.append("password", e.target.elements.password.value)
                    form.append("pin", e.target.elements.pin.value)
                    form.append("org_id", org)
                    form.append("role", selectedRole)
                    form.append("status", "Enabled")
                    form.append("shift_Start", "null")
                    form.append("shift_End", "null")
                    form.append("hours_status", switch1)
                    form.append(
                      "no_of_hours",
                      switch1 === true
                        ? e.target.elements.extra_hours.value
                        : null
                    )
                    form.append("leave_right", reviewLeaves)
                    form.append("tracking_status", trackStatus)
                    form.append("addEmployeeRight", addEmployeeStatus)
                    form.append("markAttendanceRight", markedAttendance)
                    form.append("byManual", ByManual)
                    form.append("byFaceRecong", ByFaceRecong)

                    form.append("profileimage", file)
                    form.append("payrollType", payrollType)
                    form.append("select_work_modal", selectWorkModal)
                    form.append(
                      "payrollRate",
                      e.target.elements.payroll_rate.value
                    )
                    form.append(
                      "payrollCurrency",
                      e.target.elements.currency_payroll.value
                    )
                    form.append(
                      "overTimeRate",
                      switch1 === true
                        ? e.target.elements.overtime_rate.value
                        : null
                    )
                    form.append(
                      "designation_for",
                      e.target.elements.designation.value
                    )
                    form.append(
                      "super_id",
                      selectedRole == "user"
                        ? e.target.elements.supervisor_id.value
                        : null
                    )
                    form.append(
                      "employement_status",
                      e.target.elements.employement_status.value
                    )

                    form.append(
                      "payroll_cycle_date",
                      e.target.elements.payroll_date.value
                    )

                    try {
                      await axios
                        .post(
                          process.env.REACT_APP_LEAVE_API_URL + "/login.php",
                          form,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        )
                        .then(result => {
                          setisLoading(false)

                          if (result.data == "success") {
                            toastr.success("Employee is added")
                            fetchEmployee()
                            setaddMemberModal(false)
                          } else {
                            setisLoading(false)
                            toastr.error(`${result.data}`)
                            return false
                          }
                        })
                        .catch(er => {
                          setisLoading(false)
                          console.log(er)
                        })
                    } catch (error) {
                      console.error("Error:", error)
                    }
                  }
                }}
              >
                <Row>
                  <Row>
                    <Col md={12} style={{ marginBottom: 20 }}>
                      <img
                        src={avatarSrc || "https://placehold.co/150x150"}
                        alt=""
                        style={{ objectFit: "cover" }}
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                      <input
                        type="file"
                        accept="image/*" // Accept only image files
                        onChange={handleFileChange} // Call handleFileChange function when file is selected
                        style={{ display: "none" }} // Hide the file input element
                      />
                      <span
                        style={{
                          background: "transparent",
                          border: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          document.querySelector('input[type="file"]').click()
                        }
                      >
                        Choose Image
                      </span>
                    </Col>
                  </Row>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>First Name</Label>
                    <Input
                      name="first_name"
                      className="form-control"
                      placeholder="First Name"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Last Name</Label>
                    <Input
                      name="last_name"
                      className="form-control"
                      placeholder="Last Name"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Username</Label>
                    <Input
                      name="username"
                      className="form-control"
                      placeholder="Username"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Password</Label>
                    <Input
                      name="password"
                      type="password"
                      className="form-control"
                      placeholder="Password"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>PIN</Label>
                    <Input
                      name="pin"
                      type="password"
                      maxLength={4}
                      className="form-control"
                      placeholder="PIN"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Role</Label>
                    <Select
                      options={[
                        {
                          label: "Admin",
                          value: "admin",
                        },
                        {
                          label: "Supervisor",
                          value: "supervisor",
                        },
                        {
                          label: "User",
                          value: "user",
                        },
                      ]}
                      onChange={e => setSelectedRole(e.value)}
                      className="select2-selection"
                      name="select_role"
                    />
                  </Col>
                  {selectedRole !== null ? (
                    <Col md={12}>
                      <div>
                        <p>Select Privaliages</p>
                        <div>
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor1"
                              name="rules"
                              onChange={e =>
                                setaddEmployeeStatus(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor1"
                            >
                              &nbsp;&nbsp;Add Employee
                            </label>
                          </div>
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              name="rules"
                              className="form-check-input"
                              id="customCheck-outlinecolor2"
                              onChange={e => {
                                setmarkedAttendance(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor2"
                            >
                              &nbsp;&nbsp;Mark Attendance
                            </label>
                          </div>
                          {markedAttendance == true ? (
                            <div>
                              <div className="mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="rules"
                                  id="customCheck-outlinecolor33"
                                  // checked={reviewLeaves}
                                  onChange={e => {
                                    setByManual(e.target.checked)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor33"
                                >
                                  &nbsp;&nbsp;By Manual
                                </label>
                              </div>
                              <div className="mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="rules"
                                  id="customCheck-outlinecolor35"
                                  onChange={e => {
                                    setByFaceRecong(e.target.checked)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor35"
                                >
                                  &nbsp;&nbsp;By Face
                                </label>
                              </div>
                            </div>
                          ) : null}
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="rules"
                              id="customCheck-outlinecolor3"
                              // checked={reviewLeaves}
                              onChange={e => {
                                setreviewLeaves(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor3"
                            >
                              &nbsp;&nbsp;Review Leaves
                            </label>
                          </div>
                          <div className=" mb-3">
                            <input
                              type="checkbox"
                              name="rules"
                              className="form-check-input"
                              id="customCheck-outlinecolor4"
                              onChange={e => settrackStatus(e.target.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor4"
                            >
                              &nbsp;&nbsp;Enable GPS
                            </label>
                          </div>

                          {/* <div className="form-check form-checkbox-outline form-check-danger">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor5"
                              checked={customOutlineDanger}
                              onChange={() => {
                                setcustomOutlineDanger(!customOutlineDanger)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor5"
                            >
                              Checkbox Outline Danger
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  ) : null}
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Department</Label>
                    <Select
                      options={mockData}
                      className="select2-selection"
                      name="department"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Select Location</Label>
                    <Select
                      options={mockData2}
                      onChange={e => onChangeLocation(e)}
                      className="select2-selection"
                      name="location"
                    />
                  </Col>
                  {selectedRole == "user" ? (
                    <Col md={6} style={{ marginBottom: 10 }}>
                      <Label>Supervisor Name</Label>
                      <Select
                        options={supervisorArray}
                        className="select2-selection"
                        name="supervisor_id"
                      />
                    </Col>
                  ) : null}
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Employement Status</Label>
                    <Select
                      options={[
                        {
                          label: "Internship",
                          value: "Internship",
                        },
                        {
                          label: "Permanent",
                          value: "Permanent",
                        },
                        {
                          label: "Project Based",
                          value: "Project Based",
                        },
                        {
                          label: "Freelancer",
                          value: "Freelancer",
                        },
                        {
                          label: "Probation",
                          value: "Probation",
                        },
                        {
                          label: "Contract Based",
                          value: "Contract Based",
                        },
                      ]}
                      className="select2-selection"
                      name="employement_status"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Designation Name</Label>
                    <input
                      name="designation"
                      type="text"
                      placeholder="Designation"
                      className="form-control"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Hiring Date</Label>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      className="form-control"
                      dateFormat="d-MM-yyyy"
                      name="hire_date"
                      placeholderText="Select date"
                    />
                  </Col>
                  <Col md={12} style={{ marginBottom: 10 }}>
                    <Label>Work Modal</Label>
                    <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor11"
                            name="customRadiocolor11"
                            className="form-check-input"
                            onChange={e => setSelectWorkModal(e.target.value)}
                            value={"remote"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor11"
                          >
                            Remote
                          </label>
                        </div>
                    <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor112"
                            name="customRadiocolor11"
                            className="form-check-input"
                            onChange={e => setSelectWorkModal(e.target.value)}
                            value={"on_site"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor112"
                          >
                            On-Site
                          </label>
                        </div>
                  </Col>

                  <Row>
                    <Col md={6} style={{ marginBottom: 10 }}>
                      <Label>Allow the Member for Extra Hours</Label>
                    </Col>
                    <Col md={6} style={{ textAlign: "end" }}>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                        onChange={() => {
                          setswitch1(!switch1)
                        }}
                        checked={switch1}
                      />
                    </Col>
                  </Row>
                  {switch1 === true ? (
                    <Col md={12}>
                      <input
                        name="extra_hours"
                        type="text"
                        placeholder="No of Hours"
                        className="form-control"
                      />
                    </Col>
                  ) : null}
                  <Col md={12} className="mt-3">
                    <h5>Payroll information</h5>
                  </Col>
                  <Col md={12}>
                    <Label>Select the payout type</Label>
                    <Row>
                      <Col md={3}>
                        <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor1"
                            name="customRadiocolor1"
                            className="form-check-input"
                            onChange={e => setpayrollType(e.target.value)}
                            value={"daily"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor1"
                          >
                            Daily
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor2"
                            name="customRadiocolor1"
                            className="form-check-input"
                            onChange={e => setpayrollType(e.target.value)}
                            value={"hourly"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor2"
                          >
                            Hourly
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor3"
                            name="customRadiocolor1"
                            className="form-check-input"
                            onChange={e => setpayrollType(e.target.value)}
                            value={"monthly"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor3"
                          >
                            Monthly
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={12} className="mt-2">
                    <Label>
                      {" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {payrollType}
                      </span>{" "}
                      Rate
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      name="payroll_rate"
                    />
                  </Col>
                  <Col md={12} className="mt-2">
                    <Label>Select Currency</Label>
                    <input
                      type="text"
                      className="form-control"
                      name="currency_payroll"
                    />
                    <Col md={12} className="mt-2">
                      <Label>
                        Payroll Cycle Date{" "}
                        {StatusForPayRollNew == "" ? null : StatusForPayRollNew}
                      </Label>

                      <Select
                        onChange={e => runthisForaLittleFunction(e.value)}
                        options={arrayForMe}
                        className="select2-selection"
                        name="payroll_date"
                      />
                    </Col>
                  </Col>
                  {switch1 == true ? (
                    <Col md={12} className="mt-2">
                      <Label>Overtime Rate</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="overtime_rate"
                      />
                    </Col>
                  ) : null}
                  <Col md={12} style={{ paddingTop: 20,paddingBottom:40 }}>
                    {isLoading == false ? (
                      <button type="submit" className="btn btn-primary">
                        Save Member
                      </button>
                    ) : (
                      <Spinner color="info" />
                    )}
                    &nbsp;&nbsp;
                    <button
                      onClick={() => setaddMemberModal(!addMemberModal)}
                      className="btn btn-danger"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </OffcanvasBody>
        </Offcanvas>
      </div>
    </React.Fragment>
  )
}

Timeoff.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Timeoff))
