import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import profile from "../../assets/images/users/avatar-1.jpg"
import Select from "react-select";

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "./Timeoffdata"
import toastr from "toastr"
import "toastr/build/toastr.min.css";
import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Link } from "react-router-dom"
import { Skeleton } from "@mui/material"
import { MicExternalOnSharp } from "@mui/icons-material"
const Timeoff = props => {
  const MySwal = withReactContent(Swal)

  //meta title
  document.title = `Timeoff | ${process.env.REACT_APP_NAME}`
  function getCurrentDate() {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
  
    return `${yyyy}-${mm}-${dd}`;
  }
  const currentDate = getCurrentDate();
  const [ orders, onGetOrders ] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [commentsFor, setCommentsFor] = useState("")
  const [startDate, setStartDate] = useState(new Date(currentDate))
  const [selectCoin, setselectCoin] = useState(null)
  const [selectType, setselectType] = useState("Buy")
  const [selectStatus, setselectStatus] = useState("Completed")
  const [productData, setSetProductData] = useState([orders])
  const [timeoffmodal, setTimeoffmodal] = useState(false)
  const [leaveStartDate, setLeaveStartDate] = useState(new Date(currentDate))
  const [leaveEndDate, setLeaveEndDate] = useState(new Date(currentDate))
  const [selectedUser, setSelectedUser] = useState([])
  const [selectedMulti2, setselectedMulti2] = useState(null);
  const [currentId, setCurrentId]= useState('')
  const [Approved, setApproved]= useState('')
  const [Rejected, setRejected]= useState('')
  const [showLoaderBeforeFetch, setshowLoaderBeforeFetch] = useState(false);

  const addtimeModal = () => {
    setTimeoffmodal(!timeoffmodal)
  }
  const fetchLeaves = () => {
    let localVar = localStorage.getItem('authUser');
    let localArray = JSON.parse(localVar);
    let emp = localArray.map((e)=>e.user_id)
    let org = localArray.map((e)=>e.org_id)
    setCurrentId(emp[0])
    let form = new FormData();
    form.append('method', 'fetch-leaves');
    form.append('emp_id', emp);
    form.append('org_id', org);
    var requestOptions = {
      method: 'POST',
      body: form,
    };
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/leave.php', requestOptions)
      .then(res => res.json())
      .then(result => {
        
        onGetOrders(result.reverse())
        const approved = result.filter((e)=>e.status == 'approved');
        const rejected = result.filter((e)=>e.status == 'rejected');
        setApproved(approved.length)
        setRejected(rejected.length)
        // setLeaveData(result);
        // setFilteredData(result);
        // setTimeout(() => {
        //   if (filteredData.length == 0) {
        //     setPlaceholderShown(false);
        //   }
        // }, 1000);
      })
      .catch(e => {
        console.log(e);
      });
  };
  useEffect(() => {
    fetchLeaves();
    fetchEmployeeList();
  }, [])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const OnPressNext = (id, val) => {
    MySwal.fire({
      title: 'Mark Leave as',
      html:'Change attendance status',
      showCancelButton: true,
      confirmButtonText: 'Sick Leave',
      cancelButtonText: 'Casual Leave',
      cancelButtonColor:'green',
      confirmButtonColor:'red'
      
  }).then((result) => {
      if (result.isConfirmed) {
          // Handle action for Button 1
          let form = new FormData();
          form.append('method', 'update_leave_status');
          form.append('leave_id', id);
          form.append('leave_status', val);
          form.append('leave_other', 'sick-leave');
          var requestOptions = {
            method: 'POST',
            body: form,
          };
          fetch(process.env.REACT_APP_LEAVE_API_URL+'/leave.php', requestOptions)
            .then(res => res.text())
            .then(result => {
              if (result == 'success') {
                let localVar = localStorage.getItem('authUser');
                let localArray = JSON.parse(localVar);
                let emp = localArray.map((e)=>e.user_id)
                let org = localArray.map((e)=>e.org_id)
                fetchLeaves(emp, org);
              } else {
                alert('Error while updating the status. Try again');
                
              }
            });
          

      } else if (result.dismiss === 'cancel') {
        let form = new FormData();
        form.append('method', 'update_leave_status');
        form.append('leave_id', id);
        form.append('leave_status', val);
        form.append('leave_other', 'casual-leave');
        var requestOptions = {
          method: 'POST',
          body: form,
        };
        fetch(process.env.REACT_APP_LEAVE_API_URL+'/leave.php', requestOptions)
          .then(res => res.text())
          .then(result => {
            if (result == 'success') {
              let localVar = localStorage.getItem('authUser');
              let localArray = JSON.parse(localVar);
              let emp = localArray.map((e)=>e.user_id)
              let org = localArray.map((e)=>e.org_id)
              fetchLeaves(emp, org);
            } else {
              alert('Error while updating the status. Try again');
              
            }
          });
      }
  });
    

   
  };
  const OnPressNextDecline = (id, val) => {
    let form = new FormData();
    form.append('method', 'update_leave_status');
    form.append('leave_id', id);
    form.append('leave_status', val);
    form.append('leave_other', 'un-paid');
    var requestOptions = {
      method: 'POST',
      body: form,
    };
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/leave.php', requestOptions)
      .then(res => res.text())
      .then(result => {
        if (result == 'success') {
          let localVar = localStorage.getItem('authUser');
          let localArray = JSON.parse(localVar);
          let emp = localArray.map((e)=>e.user_id)
          let org = localArray.map((e)=>e.org_id)
          fetchLeaves(emp, org);
          
        } else {
          alert('Error while updating the status. Try again');
          
        }
      });
    

   
  };
  // Table Data
  function formatDate(inputDate) {
    // Split the input date string into day, month, and year
    const [day, month, year] = inputDate.split('-');
  
    // Create a Date object with the provided year, month (subtracting 1 as months are zero-indexed), and day
    const date = new Date(year, month - 1, day);
  
    // Get the month name abbreviation using an array of month names
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthAbbreviation = monthNames[date.getMonth()];
  
    // Format the date string in "DD Mon YYYY" format
    const formattedDate = `${day} ${monthAbbreviation} ${year}`;
  
    return formattedDate;
  }

  const handleSpeechRecognition = () => {
    
    const recognition = new window.webkitSpeechRecognition(); // For Chrome
    recognition.lang = 'en-US';
    recognition.start();

    recognition.onresult = (event) => {
      const speechToText = event.results[0][0].transcript;
      setCommentsFor(commentsFor + ' ' + speechToText);
      // console.log(speechToText)
    };


    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event.error);
    };
  };
  const mockData = [
  
    {label: 'Matternity', value: 'Matternity'},
    {label: 'Personal', value: 'Personal'},
    {label: 'Casual Leave', value: 'Casual Leave'},
    {label: 'Medical Leave', value: 'Medical Leave'},
    {label: 'Sick Leave', value: 'Sick Leave'},
    {label: 'Half Leave', value: 'Half Leave'},
    {label: 'Emergency Leave', value: 'Emergency Leave'}
  ];
  const columns = useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "emp_id",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
             <Link to={`../../people/members/view/${cellProps.value}`}>{cellProps.cell.row.original.fullname}</Link>
              
            </div>
          );
        },
      },
      {
        Header: "Apply Date",
        accessor: "apply_date",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>{formatDate(cellProps.cell.value)}</div>
          );
        },
      },
      {
        Header: "Duration",
        accessor: "no_days",
        filterable: true,
        Cell: cellProps => {
          return(
            <span>{cellProps.cell.value + ' days'}</span>
          );
        },
      },
      {
        Header: "Time Off Policy",
        accessor: "leave_type",
        filterable: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <ValueinUSD {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          let localVar = localStorage.getItem('authUser');
          let localArray = JSON.parse(localVar);
          let emp = localArray.map((e)=>e.role)
         if(cellProps.row.original.status == 'requested' && emp == 'admin' || emp == 'supervisor'){
           return (
             <div>
               <button style={{fontSize:10,padding:7}}  className="btn btn-success" onClick={()=>OnPressNext(cellProps.cell.value, 'approved')}>{'Approve'}</button>
               <button className="btn btn-danger" style={{fontSize:10,padding:7}}  onClick={()=>OnPressNextDecline(cellProps.cell.value, 'rejected')}>{'Decline'}</button>
             </div>
           );
         }else{
          return (
            <div>
              <button disabled style={{fontSize:10,padding:7}}  className="btn btn-success" >{'Approve'}</button>
              <button disabled style={{fontSize:10,padding:7}}  className="btn btn-danger">{'Decline'}</button>
            </div>
          );
         }
        },
      },
    ],
    []
  )
  const fetchEmployeeList = () =>{
    let localVar = localStorage.getItem('authUser');
    let localArray = JSON.parse(localVar);

    let emp = localArray.map((e)=>e.user_id)
    setCurrentId(emp[0])
    let org = localArray.map((e)=>e.org_id)
    let leave = localArray.map((e)=>e.leave_right)
    let form  = new FormData();
    form.append('method','fetch-user-for-select');
    form.append('emp_id',emp);
    form.append('right',leave);
    form.append('org_id',org);
    var requestOptions = {
      method:'POST',
      body:form
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/login.php',requestOptions).then((res)=>res.json()).then((result)=>{
    
    setSelectedUser(result)
    
    }).catch((er)=>{
      console.log(er)
    })
  }
  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2);
    FetchSingleDetail(selectedMulti2.value)
    
  }
const [singleDetail,setSingleDetail] = useState([])
  const FetchSingleDetail = (passingId)=>{
  setshowLoaderBeforeFetch(true)
  setSingleDetail([])
let formdata = new FormData();
formdata.append("method", "fetch-single-per-select");
formdata.append("emp_id", passingId);

let requestOptions = {
  method: "POST",
  body: formdata,
  redirect: "follow"
};

fetch(process.env.REACT_APP_LEAVE_API_URL+"/leave.php", requestOptions)
  .then((response) => response.json())
  .then((result) => {
      setshowLoaderBeforeFetch(false)
      setSingleDetail(result)
    
  })
  .catch((error) => console.error(error));
  }
  const [showQuota , setShowQuota] = useState(false);
  const [showQuotaData , setShowQuotaData] = useState([]);
  const showModalForQuota = (param)=>{
    setShowQuota(true);
    setShowQuotaData(param);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Leave Balance
                  </NavLink>
                </NavItem> */}
              </Nav>
            </Col>
            <Col xl="6">
              <div className="float-end">
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm w-md"
                  
                  onClick={() => addtimeModal()}
                >
                  Add Time Off 
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1">
                    <Row>
                    <Col lg={3} >
                                <Card  className="blog-stats-wid" style={{borderWidth:1,borderColor:'#c0c0c0'}}>
                                  <CardBody >
                                 
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2" style={{fontWeight:'700'}}>{'Total'}</p>
                                        <h5 className="mb-0" style={{fontSize:18,fontWeight:'bold',color:'#212529bf'}}>{orders.length}</h5>
                                      </div>
                    
                                      <div className="avatar-sm ms-auto">
                                        <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                                          {/* <i className="bx bxs-note"></i> */}
                                         
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </CardBody>

                                </Card>
                              </Col>
                    <Col lg={3} >
                                <Card  className="blog-stats-wid" style={{borderWidth:1,borderColor:'#c0c0c0'}}>
                                  <CardBody >
                                 
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2" style={{fontWeight:'700'}}>{'Approved'}</p>
                                        <h5 className="mb-0" style={{fontSize:18,fontWeight:'bold',color:'#212529bf'}}>{Approved}</h5>
                                      </div>
                    
                                      <div className="avatar-sm ms-auto">
                                        <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                                          {/* <i className="bx bxs-note"></i> */}
                                       
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </CardBody>
                                </Card>
                              </Col>
                    <Col lg={3} >
                                <Card  className="blog-stats-wid" style={{borderWidth:1,borderColor:'#c0c0c0'}}>
                                  <CardBody >
                                 
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2" style={{fontWeight:'700'}}>{'Rejected'}</p>
                                        <h5 className="mb-0" style={{fontSize:18,fontWeight:'bold',color:'#212529bf'}}>{Rejected}</h5>
                                      </div>
                    
                                      <div className="avatar-sm ms-auto">
                                        <div style={{background:'transparent'}} className="avatar-title  text-primary font-size-20">
                                          {/* <i className="bx bxs-note"></i> */}
                                          
                                        </div>
                                      </div>
                                    </div>
                                    
                                  </CardBody>
                                </Card>
                              </Col>
                    </Row>
                      <TableContainer
                        columns={columns}
                        data={orders}
                        isGlobalFilter={true}
                        isAddOptions={false}
                        customPageSize={10}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="justify-content-center ">
                        <Col xl="12" className="text-center">
                          <div>
                            <div className="position-relative">
                              <img src={img2} alt="image" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={timeoffmodal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setTimeoffmodal(!timeoffmodal)
        }}
      >
        <div>
           <Form
            onSubmit={(e)=>{
              
              e.preventDefault();
              
              if(e.target.elements.member.value == ""){
                toastr.error('Please select the employee');
                return false;
              }else if(e.target.elements.policy.value == ""){
                 toastr.error('Please select the policy');
                 return false;
              }else{
                const dd = String(leaveStartDate.getDate()).padStart(2, '0');
                const mm = String(leaveStartDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yyyy = leaveStartDate.getFullYear();
                let firstDate =  `${mm}/${dd}/${yyyy}`;
                const dds = String(leaveEndDate.getDate()).padStart(2, '0');
                const mms = String(leaveEndDate.getMonth() + 1).padStart(2, '0'); // January is 0!
                const yyyys = leaveEndDate.getFullYear();
                let lastDate =  `${mms}/${dds}/${yyyys}`;
                
                let localVar = localStorage.getItem('authUser');
                let localArray = JSON.parse(localVar);
                // let emp = localArray.map((e)=>e.user_id)
                let org = localArray.map((e)=>e.org_id)
                let form = new FormData();
                form.append('emp_id',e.target.elements.member.value)
                form.append('leave_type',e.target.elements.policy.value)
                form.append('from_date',lastDate)
                form.append('org_id',org)
                form.append('to_date',firstDate)
                form.append('comments',e.target.elements.comments.value)
                form.append('method','assign-leave')
                var requestOptions = {
                  method:'POST',
                  body:form
                }

                
                fetch(process.env.REACT_APP_LEAVE_API_URL+'/leave.php',requestOptions).
                then((re)=>re.text())
                .then((ress)=>{
                  
                  if(ress == 'success'){
                   toastr.success('Leave is posted to admin');
                    fetchLeaves();
                    setTimeoffmodal(false);
                  }else{
                    toastr.error(`${ress}`);
                  }
                }).catch((e)=>{
                    toastr.error(`ERROR ${e}`);
                })
              }
            }}
            
            >
          <ModalHeader
            toggle={() => {
              setTimeoffmodal(!timeoffmodal)
            }}
          >
            Add Time Off
          </ModalHeader>
          <ModalBody>
          
           <Row>
              <Col md={12}>
              {
           showLoaderBeforeFetch == true?    <Card>
                <CardBody>
                
                    <Row>
                      <Col md={2}  style={{
                        display:'grid',
                        alignContent:'center',
                        marginRight:25

                      }}>
                       <Skeleton width={80} height={80} variant="circular" />
                        
                      </Col>
                      <Col md={8}>
                       <Skeleton width={200}/>
                       <Skeleton width={200}/>
                       <Skeleton width={200}/>
                       <Skeleton width={200}/>
                       
                      </Col>
                      <Col className="mt-2" md={12}>
                      <Row>
                        <Col md={2}>
                        <Skeleton width={50}/>
                        <Skeleton width={50}/>
                        </Col>
                        <Col md={3}>
                        <Skeleton width={50}/>
                        <Skeleton width={50}/>
                        </Col>
                        
                      </Row>
                      </Col>
                    </Row>
               
                
                </CardBody>
                </Card>
: null
}
              {
                singleDetail.length == 0 ? null :


                <Card>
                <CardBody>
                {
                  singleDetail.map((e,index)=>(
                    <Row key={index}>
                      <Col md={2}  style={{
                        display:'grid',
                        alignContent:'center',
                        marginRight:15

                      }}>
                        <img
                        style={{objectFit:'cover'}}
                        src={e.profile}
                        className="avatar-md rounded-circle"
                        />
                      </Col>
                      <Col md={8} className="myclass_iwant">
                       <p><span>ID</span> {e.emp_id}</p>
                       <p><span>Name </span> {e.fullname}</p>
                       <p><span>Designation</span> {e.job_title}</p>
                       <p><span>Department</span> {e.job_category}</p>
                      </Col>
                      <Col className="mt-2" md={12}>
                      <Row>
                        {/* <Col md={3}>
                        <p>Assigned</p>
                        <p>{e.assigned}</p>
                        </Col> */}
                        <Col md={3}>
                        <p>Approved</p>
                        <p>{e.approved}</p>
                        </Col>
                        <Col md={2}>
                        <p>Rejected</p>
                        <p>{e.rejected}</p>
                        </Col>
                        {/* <Col md={3}>
                        <p>Remaining</p>
                        <p>{e.remaining}</p>
                        </Col> */}
                        <Col md={12}>
                          {e.quota_remarks == 'assigned' ?<p style={{cursor:'pointer',fontWeight:'bold'}} onClick={()=>setShowQuota(!showQuota)} >{showQuota == false ? 'Show Quota' : 'Hide Quota'}</p> : <p>{'No Quota'}</p> }
                        </Col>
                        {
                          showQuota == true ? 
                          <Col md={12}>
                             <table className="table">
                                  <thead>
                                    <th>
                                      Type
                                    </th>
                                    <th>
                                      No Of Leaves
                                    </th>
                                  </thead>
                                  <tbody>

                            
                             
                             { 
                                e.quota.map((e,index)=>(
                                    <tr key={index}>
                                      <td>{e.type}</td>
                                      <td>{e.number}</td>
                                    </tr>
                                  ))}
                                
                                </tbody>
                                </table>
                          </Col>:null
                        }
                      </Row>
                      </Col>
                    </Row>
                  ))
                }
                
                </CardBody>
                </Card>
                
              }
              </Col>
            </Row>
            <Row>
              <Col className="col-12">
                <div className="mb-3 ">
                          <Label>Select Member</Label>
                          <Select
                            name="member"
                            onChange={(e) => handleMulti2(e)}
                            options={selectedUser}
                            value={selectedMulti2}
                            className="select2-selection"
                            
                          />
                  </div>
                <div className="mb-3">
                  <Label>Select Policy</Label>
                  <Input className="form-control" name="policy" type="select">
                    <option>Select a policy</option>
                    {
                      mockData.map((e,index)=><option key={index} value={e.value}>{e.label}</option>)
                    }
                  </Input>
                </div>
                <CardTitle>Days</CardTitle>
                <div className="mb-3">
                  <Row>
                    <Col xl="6">
                      <Label>Start Date</Label>
                       <DatePicker
                                // minDate={new Date()}
                                selected={leaveStartDate}
                                onChange={date => setLeaveStartDate(date)}
                                className="form-control"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select date"
                              />
                    </Col>
                    <Col xl="6">
                      <Label>End Date</Label>
                      <DatePicker
                              //  minDate={new Date()}
                                selected={leaveEndDate}
                                onChange={date => setLeaveEndDate(date)}
                                className="form-control"
                                dateFormat="dd-MM-yyyy"
                                placeholderText="Select date"
                              />
                    </Col>
                  </Row>
                </div>

                <div className="mb-3">
                  <Label >Description  <MicExternalOnSharp id="_icon_tip" style={{cursor:'pointer'}}  onClick={handleSpeechRecognition}/> <UncontrolledTooltip target={'_icon_tip'}>
                    Press this icon to record your description into text.
                    </UncontrolledTooltip> </Label>
                  <Input
                    className="form-control"
                    type="textarea"
                    name="comments"
                    value={commentsFor}
                    onChange={(e)=>setCommentsFor(e.target.value)}
                    placeholder="Add a note"
                  />
                </div>
              </Col>
            </Row>
            
           
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setTimeoffmodal(!timeoffmodal)
              }}
            >
              Close
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </ModalFooter>
          </Form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

Timeoff.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Timeoff))
