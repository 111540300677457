import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"

import { BarChart } from "@mui/x-charts/BarChart"
import {
  // BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"
import { axisClasses, legendClasses } from "@mui/x-charts"

const data = [
  {
    name: "M",
    WORKED_HOURS: 4000,
    BREAKS: 2400,
    OVERTIME_HOURS: 2400,
  },
  {
    name: "T",
    WORKED_HOURS: 3000,
    BREAKS: 1398,
    OVERTIME_HOURS: 2210,
  },
  {
    name: "W",
    WORKED_HOURS: 2000,
    BREAKS: 9800,
    OVERTIME_HOURS: 2290,
  },
  {
    name: "T",
    WORKED_HOURS: 2780,
    BREAKS: 3908,
    OVERTIME_HOURS: 2000,
  },
  {
    name: "F",
    WORKED_HOURS: 1890,
    BREAKS: 4800,
    OVERTIME_HOURS: 2181,
  },
  {
    name: "S",
    WORKED_HOURS: 2390,
    BREAKS: 3800,
    OVERTIME_HOURS: 2500,
  },
  {
    name: "S",
    WORKED_HOURS: 3490,
    BREAKS: 4300,
    OVERTIME_HOURS: 2100,
  },
]

export default function Trackedhour({ location }) {
  console.log(location)
  const [reportData, setDataForReport] = useState([])
  const [totalWorkingHours, settotalWorkingHours] = useState("")
  const [totalBreakHours, settotalBreakHours] = useState("")
  //  function formatDate(date) {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0');
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // }
  function getWeekDates() {
    const currentDate = new Date() // Get the current date
    const startOfWeek = new Date(currentDate) // Clone the current date
    const endOfWeek = new Date(currentDate)

    // Calculate the start of the week (Sunday)
    startOfWeek.setDate(currentDate.getDate() - currentDate.getDay())

    // Calculate the end of the week (Saturday)
    endOfWeek.setDate(currentDate.getDate() + (6 - currentDate.getDay()))

    // Format the dates as YYYY-MM-DD strings
    const formattedStartOfWeek = startOfWeek.toISOString().split("T")[0]
    const formattedEndOfWeek = endOfWeek.toISOString().split("T")[0]

    return { startOfWeek: formattedStartOfWeek, endOfWeek: formattedEndOfWeek }
  }
  const getWorkingReport = loc => {
    let localVar = localStorage.getItem("authUser")
    const { startOfWeek, endOfWeek } = getWeekDates()
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "get-activity-report")
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    formdata.append("role", "supervisor")
    formdata.append("start_date", startOfWeek)
    formdata.append("end_date", endOfWeek)
    formdata.append("location_for", loc == "All Location" ? "All" : loc)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    console.warn(formdata)
    fetch("https://api.frontforcecrm.com/activity.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setDataForReport(result.dataList)
        settotalWorkingHours(formatSecondsToTime(result.total_work))
        settotalBreakHours(formatSecondsToTime(result.total_break))
        // const label = result.dataList.map(e => e.date);
        // setLabelForReport(label);
        // if (result.total_work !== null) {
        //   setTotalWorkingHours(formatTime(parseInt(result.total_work)));
        // }
        // if (result.total_break !== null) {
        //   setTotalBreakHours(formatTime(parseInt(result.total_break)));
        // }
        const transformedData = result.dataList.map(item => [
          parseInt(item.working),
          parseInt(item.break),
        ])
        // console.log(transformedData);
        // setDataForReport(transformedData);
      })
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    getWorkingReport(location)
  }, [location])
  const formatSecondsToTime = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`

    return `${formattedHours}h:${formattedMinutes}m`
  }
  const CustomYAxisTick = props => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
          {formatSecondsToTime(payload.value)}
        </text>
      </g>
    )
  }
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          className="custom-tooltip"
          style={{ backgroundColor: "white", padding: 5 }}
        >
          <p className="label">{`Day: ${label}`}</p>
          <p className="desc">{`Working: ${formatSecondsToTime(
            payload[0].payload.working
          )}`}</p>
          <p className="desc">{`Break: ${formatSecondsToTime(
            payload[1].payload.break
          )}`}</p>
          <p className="desc">{`Overtime: 00h:00m`}</p>
        </div>
      )
    }

    return null
  }

   const uData = reportData.map((e)=>e.working ?parseFloat(e.working):0 );
   const pData = reportData.map((e)=>e.break ? parseFloat(e.break) : 0) ;
   const oData = reportData.map((e)=>e.break ? 0 : 0) ;
  // const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
  // const pData = [2400, 1398, 9800, 3908, 4800, 3800, 4300];
  // const oData = [2400, 2210, 2290, 2000, 2181, 2500, 2100];
  // const mergedArray = [...new Set(uData.concat(pData, oData))].sort((a, b) => a - b).reverse();


  const xLabels = reportData.map((e)=>e.date);
  const valueFormatter  = (row)=> formatSecondsToTime(row)


  const barChartsParams = {
    xAxis: [
      {
        data: xLabels,
        scaleType: 'band',
      },
    ],
    yAxis: [
      {
        valueFormatter: (row)=>formatSecondsToTime(row),
        
      },
    ],
    series: [
      { data: uData, stack: '1', label: 'Working' ,valueFormatter ,color:'#82ca9d' },
      { data: pData, stack: '1', label: 'Break' ,valueFormatter,color:'#ffc658'},
      { data: oData, stack: '1', label: 'Overtime' ,valueFormatter,color:'#8884d8'},
    ],
    margin: { top: 10, right: 10 },
    sx: {
      [`& .${legendClasses.root}`]: {
        display: 'none',
      },
    },
    height: 300,
  };
  
  return (
    <Row>
      <Col md={12}>
        
        <Row>
          <Col md={4}>
            <p style={{ fontSize: 18 }}>
              {"Working Hours"}&nbsp;
              <span style={{ background: "#82ca9d", color: "transparent" }}>
                --
              </span>
            </p>
            <p style={{ fontSize: 24, fontWeight: "600" }}>
              {totalWorkingHours}
            </p>
          </Col>
          <Col md={4}>
            <p style={{ fontSize: 18 }}>
              {"Break Hours"}&nbsp;
              <span style={{ background: "#ffc658", color: "transparent" }}>
                --
              </span>
            </p>
            <p style={{ fontSize: 24, fontWeight: "600" }}>{totalBreakHours}</p>
          </Col>
          <Col md={4}>
            <p style={{ fontSize: 18 }}>
              {"Overtime Hours"}&nbsp;
              <span style={{ background: "#8884d8", color: "transparent" }}>
                --
              </span>
            </p>
            <p style={{ fontSize: 24, fontWeight: "600" }}>00h:00m</p>
          </Col>
        </Row>
      </Col>
      <Col md={12}>
        <div style={{ width: "100%", height: 300 }}>
          {uData.length !== 0 && pData.length != 0 ? (
            <ResponsiveContainer>

          <BarChart
            // width={300}
            // height={300}
            {...barChartsParams}
            axisHighlight={{ y: 'line' }}
          //   layout="vertical"
            
            
          //   // yAxis={[{data: uData.map((e)=>formatSecondsToTime(e))}]}
          //   xAxis={[{ data: xLabels, scaleType: 'band' }]}
          // {...chartSetting}
          />
              {/* <BarChart
                width={500}
                height={300}
                series={[
                  {
                    data: pData.map(value => formatSecondsToTime(value)),
                    label: "Working",
                    id: "workingId",
                    stack: "stack1",
                  },
                  {
                    data: uData.map(value => formatSecondsToTime(value)),
                    label: "Break",
                    id: "breakId",
                    stack: "stack1",
                  },
                  {
                    data: oData.map(value => formatSecondsToTime(value)),
                    label: "Overtime",
                    id: "ovId",
                  },
                ]}
                // yAxis={[{data :mergedArray.map(val =>formatSecondsToTime(val)),  scaleType : 'band'}]}
                xAxis={[{ data: xLabels, scaleType: "band" }]}
              /> */}
              {/* <BarChart
          data={reportData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis tickFormatter={formatSecondsToTime} tick={<CustomYAxisTick />} />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="working"  stackId="a" fill="#82ca9d" />
          <Bar dataKey="break" stackId="a" fill="#ffc658 " />
          <Bar dataKey="over time" stackId="a" fill="#8884d8" />
        </BarChart> */}
            </ResponsiveContainer>
          ) : null}
        </div>
      </Col>
    </Row>
  )
}
