import React, { useEffect, useMemo, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"
import Switch from 'react-switch'
//import images
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "./TaskCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Spinner
} from "reactstrap"

const TasksLists = () => {
  //meta title
  const { state } = useLocation()
  const [counter, setCounter] = useState(1)
  const [assignTo, setassignTo] = useState([])
  const [mockData2, setmockData2] = useState([])
  const [isAttachmentSwitch, setisAttachmentSwitch] = useState(false)
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  document.title = `Task List ${state.project.projectName}| ${process.env.REACT_APP_NAME}`
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [DataToBeSetTasks, setDataToBeSetTasks] = useState([])
  const [job, setJob] = useState(null)
  const [createTaskShow, setCreateTaskShown] = useState(false)
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      jobTitle: (job && job.jobTitle) || "",
      companyName: (job && job.companyName) || "",
      location: (job && job.location) || "",
      experience: (job && job.experience) || "",
      position: (job && job.position) || "",
      type: (job && job.type) || "",
      status: (job && job.status) || "",
    },
    validationSchema: Yup.object({
      jobId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Job Id")
        .required("Please Enter Your Job Id"),
      jobTitle: Yup.string().required("Please Enter Your Job Title"),
      companyName: Yup.string().required("Please Enter Your Company Name"),
      location: Yup.string().required("Please Enter Your Location"),
      experience: Yup.string().required("Please Enter Your Experience"),
      position: Yup.string().required("Please Enter Your Position"),
      type: Yup.string().required("Please Enter Your Type"),
      status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateJobList = {
          id: job ? job.id : 0,
          jobId: values.jobId,
          jobTitle: values.jobTitle,
          companyName: values.companyName,
          location: values.location,
          experience: values.experience,
          position: values.position,
          type: values.type,
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          status: values.status,
        }
        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          jobId: values["jobId"],
          jobTitle: values["jobTitle"],
          companyName: values["companyName"],
          location: values["location"],
          experience: values["experience"],
          position: values["position"],
          type: values["type"],
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          status: values["status"],
        }
        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      toggle()
    },
  })
  const fetchEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setmockData2(result)
      })
      .catch(er => {
        console.log(er)
      })
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const formatDateTime = (inputDate) => {
    // Parse the input date string into a Date object
    const date = new Date(inputDate);
    
    // Extract the year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    
    // Construct the formatted date string in MM/DD/YYYY format
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const dispatch = useDispatch()
  const { jobs } = useSelector(state => ({
    jobs: state.JobReducer.jobs,
  }))

  useEffect(() => {
    getAllTasks();
    fetchEmployeeList()
    if (jobs && !jobs.length) {
      dispatch(onGetJobList())
    }
  }, [dispatch, jobs])

  useEffect(() => {
    setJobsList(jobs)
  }, [jobs])

  useEffect(() => {
    if (!isEmpty(jobs) && !!isEdit) {
      setJobsList(jobs)
      setIsEdit(false)
    }
  }, [jobs])

  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }


  const getAllTasks = () => {
    var formdata = new FormData();
    formdata.append('project_id', state.project.id);
    formdata.append('method', 'fetch-tasks');

    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };

    fetch(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        let obj = {
          assigned_to:  result.map((e)=>e.assigned_to),
          task:  result.map((e)=>e.task),
          project_name : state.project.projectName
        }
        const tasksArray = result.map((e)=>e.task);
        console.log(tasksArray);
        setJobsList(result.map((e)=>e.assigned_to))
        console.log(obj);

        console.log(result)
        
        setDataToBeSetTasks(result.map(e => e.task))
      })
      .catch(error => console.log('error', error));
  };
  // const getAllTasks = () => {
  //   let valueFrom = localStorage.getItem("authUser")
  //   let convertedValue = JSON.parse(valueFrom)
  //   let orgId = convertedValue.map(e => e.org_id)
  //   let user_id = convertedValue.map(e => e.user_id)
  //   let role = convertedValue.map(e => e.role)
  //   var formdata = new FormData()
  //   formdata.append("method", "get-tasks-assigned-all-by-project")
  //   formdata.append("project_id", state.project.id)
  //   formdata.append("id", user_id)
  //   formdata.append("role", role)
  //   var requestOptions = {
  //     method: "POST",
  //     body: formdata,
  //     redirect: "follow",
  //   }

  //   console.log(formdata)
  //   fetch(process.env.REACT_APP_LEAVE_API_URL+"/tasks.php", requestOptions)
  //     .then(response => response.json())
  //     .then(result => {
  //       console.log(result)
  //       console.log(result.map(e => e.task))
  //       setDataToBeSetTasks(result.map(e => e.task))
  //     })
  //     .catch(error => console.log("error", error))
  // }
  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "task_title",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Deadline Date",
        accessor: "deadline_date",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Action",
        accessor: "assigned_to",
        disableFilters: true,
        Cell: cellProps => {
          let assigned_to = [];
          let splitVal = cellProps.value.split(',');
          
          splitVal.map((e) => {
            console.log(e)
            const existingUserIndex = assigned_to.findIndex((user) => user.id === e);
          
            if (existingUserIndex === -1) {
              const existingUser = jobsList.flat().find((einter) => einter.id === e);
          
              if (existingUser) {
                assigned_to.push(existingUser);
              }
            }
          });
          console.log(assigned_to)
          let obj = {
            task : cellProps.cell.row.original,
            assigned_to: assigned_to
          }
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <Link to={assigned_to.length == 0 ?'#' : '/task-remarks'}  
                  state={{
                    task_data: obj
                  }}
                   className="btn btn-sm btn-soft-primary">
                    
                  <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                Remarks
              </UncontrolledTooltip>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    // const jobData = cellProps.row.original;
                    // handleJobClick(jobData);
                  }}
                >
                  <i className="mdi mdi-pencil-outline" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              </li>

              <li>
                <Link
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    // const jobData = cellProps.row.original;
                    // onClickDelete(jobData);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Tasks" breadcrumbItem="Task Lists" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">Task Lists {state.project.projectName}</h5>
                    <div className="flex-shrink-0">
                      <button
                        className="btn btn-info"
                        onClick={()=>setCreateTaskShown(true)}
                        >
                        Add Task
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={DataToBeSetTasks}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
      
        </div>
      </div>
      <Offcanvas
          isOpen={createTaskShow}
          direction="end"
          toggle={()=>setCreateTaskShown(false)}
        >
          <OffcanvasHeader
          toggle={()=>setCreateTaskShown(false)}
          >
            Create Task
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                if(e.target.elements.task_title.value == ""){
                    toastr.error('Task title is required');
                    return false;
                }else if(assignTo.length == 0){
                    toastr.error('Please select the assigned member');
                    return false;
                }else if(e.target.elements.task_description.value == ""){
                    toastr.error('Task description is required');
                    return false;
                }else if(e.target.elements.deadline_date.value == ""){
                    toastr.error('Task Deadline date is required');
                    return false;
                }else{
                    setLoadingSpinner(true)
                    let form = new FormData();
                    const fileInput = document.querySelector('input[type="file"]')
                    const file = isAttachmentSwitch === true ? fileInput.files[0] : null
                    form.append('method', 'create-task');
                    form.append('task_title', e.target.elements.task_title.value);
                    form.append('project_id', state.project.id);
                    form.append('task_description', e.target.elements.task_title.value);
                    form.append('assignedTo', assignTo.toString());
                    form.append(
                      'deadline_date',
                     formatDateTime(e.target.elements.deadline_date.value),
                    );
                    if (isAttachmentSwitch == true) {
                      form.append('file_upload', file);
                    }
                   
                    axios.post(
                        process.env.REACT_APP_LEAVE_API_URL+'/tasks.php',form,{headers:{
                          "Content-Type": "multipart/form-data",
                        }}
                       ).then(result => {
                          setLoadingSpinner(false)
                          if(result.data === 'success'){
                              toastr.success('You successfully added the task');
                              getAllTasks();
                              setCreateTaskShown(false);
                          }else{
                              toastr.error('Error while adding the task');
                          }
                      })
                      .catch(e => console.log(e));
                }  
            }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                  <Label>Title</Label>
                  <Input
                    name="task_title"
                    type="text"
                    className="form-control"
                    placeholder="..."
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Assign Member</label>
                    <Select
                      isMulti={true}
                      onChange={item => {
                        setassignTo(item.map(e => e.value))
                      }}
                      options={mockData2}
                      className="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Description</Label>
                  <textarea
                    name="task_description"
                    type="text"
                    className="form-control"
                    placeholder="Type description here...."
                  ></textarea>
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Deadline Date</Label>
                  <input
                    name="deadline_date"
                    type="date"
                    className="form-control"
                 />
                </Col>
                <Col md={12}>
                <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            setisAttachmentSwitch(!isAttachmentSwitch);
                          }}
                    />
                    </div>
                </Col>
                {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'end'}}>
                    <button type="button"  onClick={()=>setCreateTaskShown(false)} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        loadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Add Task</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
    </React.Fragment>
  )
}

export default TasksLists
