import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Spinner } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useLocation } from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";
import CarouselPage from "pages/AuthenticationInner/CarouselPage";

const Register = props => {
  const location = useLocation();
  //meta title
  document.title = `Register | ${process.env.REACT_APP_NAME}`;
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const fullname = searchParams.get('fullname');

  console.log('Email:', email || '');
  console.log('Full Name:', fullname || '');

  const dispatch = useDispatch();
  const  [selectedImage, setSelectedImage] = useState('')
  const  [loader, setLoader] = useState(false)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      organizationName:'',
      email: email || '',
      username: fullname ||  '',
      password: '',
      phoneNumber: '',
      imageScr : []
      
      
    },
    validationSchema: Yup.object({
      organizationName: Yup.string().required("Organization name is required"),
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
      phoneNumber: Yup.string().required("Please Enter Your Phone Number")
    }),
    onSubmit: (values) => {
      let valueForImage = document.getElementById('fileInput')
      values.imageScr = valueForImage.files[0];
      setLoader(true)
      dispatch(registerUser(values));
      setLoader(false)
    }
  });

  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

 const handleImageChange = (event) => { 
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const selectedImageURI = e.target.result;
          setSelectedImage(selectedImageURI);
          validation.values.imageUrlSet = selectedImageURI
      };
      
      reader.readAsDataURL(file);
    }
  };
  return (
    <React.Fragment>
      
      {/* <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div> */}
       <div >
         <Container fluid className="p-0">
          <Row className="g-0">
              <CarouselPage />
              <Col xl={3}  style={{ height: '700px', overflowY: 'scroll',scrollbarWidth: 'none' }}>
                
                {/* <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Create</h5>
                        <p>Get your free {process.env.REACT_APP_NAME} account now.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div> */}
                  <div style={{display:'flex',justifyContent:'center'}}>
                    {/* <Link to="/"> */}
                      <div className="avatar-md  mb-4 mt-3">
                        <span className="avatar-title rounded-circle bg-light" style={{alignSelf:'center'}}>
                        <label htmlFor="fileInput" style={{borderRadius:100}}>
                            <img
                              src={selectedImage || 'https://via.placeholder.com/200'}  // Use the selectedImage or the default logoImg
                              alt=""
                              style={{borderRadius:100,objectFit:'cover' ,cursor: 'pointer' }}
                              className="rounded-circle"
                              height="80"
                              width={80}
                            />
                          </label>
                        
                         <Input
                          type="file"
                          id="fileInput"
                          accept=".jpg, .jpeg, .png"
                          style={{display:'none'}}
                          onChange={handleImageChange}  // Handle file selection
                        />
                        </span>
                        <p style={{fontSize:12}}>Select Image</p>
                      </div>
                    {/* </Link> */}
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null}

                      {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null}
                    <div className="mb-3">
                        <Label className="form-label">Full Name</Label>
                        <Input
                          name="username"
                          type="text"
                          placeholder="Enter full name"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username && validation.errors.username ? true : false
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Organization Name</Label>
                        <Input
                          id="organizationName"
                          name="organizationName"
                          className="form-control"
                          placeholder="Enter organization name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.organizationName || ""}
                          invalid={
                            validation.touched.organizationName && validation.errors.organizationName ? true : false
                          }
                        />
                        {validation.touched.organizationName && validation.errors.organizationName ? (
                          <FormFeedback type="invalid">{validation.errors.organizationName}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          id="email"
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Phone Number</Label>
                        <Input
                          id="phoneNumber"
                          name="phoneNumber"
                          className="form-control"
                          placeholder="Phone Number"
                          type="tel"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.phoneNumber || ""}
                          invalid={
                            validation.touched.phoneNumber && validation.errors.phoneNumber ? true : false
                          }
                        />
                        {validation.touched.phoneNumber && validation.errors.phoneNumber ? (
                          <FormFeedback type="invalid">{validation.errors.phoneNumber}</FormFeedback>
                        ) : null}
                      </div>

                     
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.password || ""}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-4">
                        {
                          loader == true ? 
                          <Spinner color="primary"/>:
                        <button
                        style={{width:'100%'}}
                          className="btn btn-primary  "
                          type="submit"
                        >
                          Register
                        </button>
                        }
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          By registering you agree to the {process.env.REACT_APP_NAME}{" "}
                          <Link to="#" className="text-primary">
                            Terms of Use
                          </Link>
                        </p>
                      </div>
                    </Form>
                  </div>
                
              <div className="mt-5 text-center">
                <p>
                  Already have an account ?{" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} {process.env.REACT_APP_NAME}. Crafted with{" "}
                  <i className="mdi mdi-account text-info" /> by {process.env.REACT_APP_NAME_COMPANY}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
