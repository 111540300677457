import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import "../../assets/style/style.css"
import img1 from "../../assets/images/activities.256a49b0.svg"
import img2 from "../../assets/images/mobile-full.f01843f1.svg"
import img3 from "../../assets/images/empty.f86d4fbf.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { act } from "react-dom/test-utils"
import { Link } from "react-router-dom"

const ReportOverview = props => {
  //meta title
  document.title = "ReportOverview | Skote - React Admin & Dashboard Template"

  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="12">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Reports
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Exported Files
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row className="pt-3">
                <Col lg="4">
                  <Link to="/report/overview/activities">
                    <Card>
                      <CardBody>
                        <div className="product-img position-relative">
                          <img
                            src={img1}
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                        <div className="mt-4 text-left">
                          <h5 className="mb-3 text-truncate">
                            Time Tracked Report
                          </h5>
                        </div>
                        <div className="text-muted mb-3">
                          Analyze valuable insight on how your team is tracking
                          time against activities, project and clients.
                        </div>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col lg="4">
                  <Card>
                    <CardBody className="card-body disabled">
                      <span
                        className="badge rounded-pill badge-soft-primary font-size-11"
                        style={{ marginLeft: "4px" }}
                      >
                        ULTIMATE
                      </span>
                      <div className="product-img position-relative">
                        <img
                          src={img2}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>

                      <div className="mt-4 text-left">
                        <h5 className="mb-3 text-truncate">
                          Attendance Insights Report
                        </h5>
                      </div>
                      <div className="text-muted mb-3">
                        Monitor your team day to day attendance state and
                        understand more about their work habit.
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row className="pt-3">
                <Col xl="12">
                  <Card>
                    <CardBody>
                      <div className="product-img position-relative">
                        <img
                          src={img3}
                          alt=""
                          className="img-fluid mx-auto d-block"
                        />
                      </div>
                      <div className="mt-4 text-center">
                        <h5 className="mb-3 text-truncate">
                          No exported files yet
                        </h5>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportOverview
