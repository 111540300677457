import React from "react";
import ReactDOM from 'react-dom/client';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import "./i18n";
import { Provider } from "react-redux";
import {initializeApp} from 'firebase/app';
import 'firebase/database';
import store from "./store";
const firebaseConfig = {
  apiKey: "AIzaSyA_6M_8PIdIldfei5f9KTn5B8qrBooXl90",
  authDomain: "big-coil-356918.firebaseapp.com",
  databaseURL: "https://big-coil-356918-default-rtdb.firebaseio.com",
  projectId: "big-coil-356918",
  storageBucket: "big-coil-356918.appspot.com",
  messagingSenderId: "264726020315",
  appId: "1:264726020315:web:b96f671f4c58858e35ccfe",
  measurementId: "G-GJQQT1XD90"
};

// Initialize Firebase
const firebase =initializeApp(firebaseConfig);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <React.Fragment>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
);

serviceWorker.unregister()
