import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Card, CardBody, Col } from "reactstrap";
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/animation.json'
import avatar1 from '../../assets/images/users/avatar-3.jpg'
import avatar2 from '../../assets/images/users/avatar-4.jpg'
import avatar3 from '../../assets/images/users/avatar-5.jpg'
import avatar4 from '../../assets/images/users/avatar-6.jpg'
import avatar5 from '../../assets/images/users/avatar-7.jpg'
const CarouselPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  function colorizeLastThreeWords(inputString, color) {
    // Split the input string into an array of words
    let words = inputString.split('');

    // Find the index where the last three words start
    let startIndex = Math.max(words.length - 3, 0);

    // Create a string to store the formatted HTML
    let formattedString = '';

    // Loop through each word, adding HTML tags to the last three words
    for (let i = 0; i < words.length; i++) {
        if (i >= startIndex) {
            // Wrap the word in a span with the specified color style
            formattedString += `<span style="color: ${color};">${words[i]}</span>`;
        } else {
            formattedString += words[i];
        }

        // Add a space after each word except the last one
        if (i < words.length - 1) {
            formattedString += ' ';
        }
    }

    // Return the formatted string
    return formattedString;
}
const testimonials = [
  {
    name: "Alex Johnson",
    image: avatar1,
    designation: "Head of Operations",
    testimonial: "Track Presence has greatly simplified our attendance tracking process. Its user-friendly interface and robust features have made it an indispensable tool for our organization."
  },
  {
    name: "Emma Davis",
    image: avatar2,
    designation: "HR Specialist",
    testimonial: "I've been using Track Presence for managing employee attendance, and I'm impressed with its efficiency and accuracy. It's helped us save time and reduce administrative burden significantly."
  },
  {
    name: "Mark Thompson",
    image: avatar4,
    designation: "IT Manager",
    testimonial: "Track Presence has exceeded our expectations in terms of functionality and reliability. It integrates seamlessly with our existing systems and provides comprehensive insights into employee attendance."
  },
  {
    name: "Sophia Martinez",
    image: avatar3,
    designation: "Project Coordinator",
    testimonial: "Thanks to Track Presence, we've been able to monitor project timelines more effectively and ensure that team members are present when needed. It's been a game-changer for project management."
  },
  {
    name: "Chris Wilson",
    image: avatar5,
    designation: "Finance Manager",
    testimonial: "Track Presence has helped us streamline our payroll process by providing accurate attendance data. It's intuitive to use and has improved overall efficiency in our finance department."
  }
];

  return (
    <React.Fragment>
      <Col xl={9}>
        <div style={{backgroundColor:'#f3f3f3'}} className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            <div className="bg-overlay"></div>
            <div className="d-flex h-100 flex-column">
              <div className="p-4 mt-auto">
                <div className="row justify-content-center">
                  <div className="col-lg-7">
                    <div className="text-center">
                      <h4 style={{margin:0,fontFamily:'Nunito Sans',fontSize:24,fontWeight:'bold', textTransform:'uppercase'}} dangerouslySetInnerHTML={{__html: colorizeLastThreeWords(String(process.env.REACT_APP_NAME), '#8fe1ff')}}></h4>
                    <Lottie options={defaultOptions} 
                    width={350}
                    height={240}
                    />
                      <h5 className="mb-1">
                        <i className="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i>
                        <span className="text-primary">5k</span>+ Satisfied
                        clients
                      </h5>
                      <div dir="ltr">
                        <Carousel autoPlay={true} infiniteLoop={true}  className="owl-carousel owl-theme auth-review-carousel slider_css" id="auth-review-carousel" 
                        showThumbs={false}>
                           {
                            testimonials.map((e,index)=>(
                              <div key={index}>
                              <div className="item">
                              <Card>
                                <CardBody >
                                  <p>{e.testimonial}</p>
                                  <div className="text-center">
                                     <center>
                                     <div style={{width: 70}} className="mb-3">
                                      <img className="avatar-md rounded-circle" src={ e.image || "https://via.placeholder.com/200"}/>
                                     </div>
                                     </center>
                                    <p>{e.name}</p>
                                    <p> {e.designation}</p>
                                  </div>
                                </CardBody>
                              </Card>
                              </div>
                            </div>
                            ))
                           }
                        
                        
                        </Carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
