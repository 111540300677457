import { takeEvery, fork, put, all, call } from "redux-saga/effects"

//Account Redux states
import { REGISTER_USER } from "./actionTypes"
import { registerUserSuccessful, registerUserFailed } from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper"
import axios from "axios"
import Swal from "sweetalert2"
const fireBaseBackend = getFirebaseBackend()    
function* registerUser({ payload: { user , history } }) {
  console.log("using the following url for registration: ")
  try {
    console.log(user)
    let form = new FormData();    
    form.append('method', 'createUserWithOrganization');
    form.append('org_name',user.organizationName);
    form.append('fname', user.username);
    form.append('org_cat', null);
    form.append('no_of_employees', null);
    form.append('need_tips', 'true');
    form.append('phonenumber',user.phoneNumber);
    form.append('email_addres', user.email);
    form.append('password', user.password);
    form.append('profileimage', user.imageScr);
    axios.post(process.env.REACT_APP_LEAVE_API_URL+'/login.php', form, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(response => {
        // Handle the response from the API
        console.log('API Response:', );
        if(response.data == 'success'){
          Swal.fire({
            title:'Account created',
            text:'Your account is successfully created. Use the credientials to get login.',
            icon:'success',
            confirmButtonText:'OK',
            cancelButtonText:'Close'
          }).then((is)=>{
            if(is.isConfirmed){
              window.location.href="/login"; 
            }else{
              window.location.href="/login"; 
            }
          });
          
        }else{
          Swal.fire({
            title:'Account not created',
            text:`${response.data}`,
            icon:'error',
          })
        }
      })
      .catch(error => {
        // Handle errors during the API request
        console.error('API Error:', error);
      });
    
  } catch (error) {
    console.log("There was an error registering: ", error)
    yield put(registerUserFailed(error))
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser)
}

function* accountSaga() {
  yield all([fork(watchUserRegister)])
}

export default accountSaga
