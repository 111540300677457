import React from "react"
import { Link } from "react-router-dom"
import { Badge } from "reactstrap"

const Pdate = cell => {
   
  return cell.value ? cell.value +'-'+ cell.row.original.data.first_name : "n/a"
}

const Type = cell => {
  return cell.value ? cell.value : "n/a"
}

const Value = cell => {
  return cell.value ? cell.value : "n/a"
}

const ValueinUSD = cell => {
  return cell.value ? cell.value : "n/a"
}

const Tracked = cell => {

  return cell.value ? formatSecondsToTime(cell.value ): "n/a"
}
const formatSecondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  return `${formattedHours}h:${formattedMinutes}m:${formattedSeconds}`;
};
const Status = cell => {
  switch (cell.value) {
    case "present":
      return <Badge className="bg-success font-size-10">present</Badge>
    case "leave":
      return <Badge className="bg-warning font-size-10">Leave</Badge>
    case "absent":
      return <Badge className="bg-danger font-size-10">absent</Badge>
  }
}
const Actions = cell => {
     return (
      <div>
        
        <Link
        className="btn btn-primary"
        to={'/timesheet/view'}
        state={{ obj : cell.row.original ,created_at: cell.value }}
        >
DETAILS 
        </Link>
      </div>
     );
}

const Coin = cell => {
  return cell.value ? cell.value : "n/a"
}

export { Pdate, Type,Actions, Value, ValueinUSD, Status, Coin, Tracked }
